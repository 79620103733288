import React from "react";
import {connect} from "react-redux";
import MaintenanceTable from "../../../maintenance-table/maintenance-table";
import Filter from "../../../filter/filter";
import ProgressIndicator from "../../../progress-indicator/progress-indicator";
import Heading from "../../../heading/heading";
import {RECEIVED_STUDENT_ASSIGNMENT_RESULTS, ElasticSearchTypes, ElasticSearchColumns} from "../../../../actions/types";
import classnames from "classnames";
import StudentAssignmentMaintenanceTableMobile from "../../../student-assignment-maintenance-table-mobile/student-assignment-maintenance-table-mobile";
import $ from "jquery";
import {browserHistory} from "react-router";
import ReactScrollPagination from "react-scroll-pagination";
import ElasticSearchQuery from "../../../../actions/elastic-search-query";
import ElasticSearchActions from "../../../../actions/elastic-search-actions";
import LoginUtils from "../../../common/login-utils";
import details from "./details.png";
import "./student-assignment.css";
import "../../../reports/view/view-report.css";
import MaterialIcon from "react-google-material-icons";
import {Alert, Modal, Button} from "react-bootstrap";
import apiClient from "../../../../actions/api-client";
import SelectFieldGroup from "../../../forms/select-field-group";
import {
    parseHours,
    parseMinutes,
    parseSeconds
} from '../../../../utils/timeParser';
import htmlParser from "../../../common/htmlParser";

class StudentReport extends React.Component {
    constructor(props) {
        super(props);
        this.getStudentReport = this.getStudentReport.bind(this);
        this.startOrResume = this.startOrResume.bind(this);
        this.getNextPage = this.getNextPage.bind(this);
        this.viewStudentDetails = this.viewStudentDetails.bind(this);
        this.getUserDetailsDialog = this.getUserDetailsDialog.bind(this);
        this.loadStudentDetails = this.loadStudentDetails.bind(this);
        this.getActivityAttemptsDialog = this.getActivityAttemptsDialog.bind(this);
        this.loadAttemptDetails = this.loadAttemptDetails.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);

        this.state = {screenWidth: window.innerWidth,loadingNextPage: 0};
        this.filterComponentCssClassName = 'filterBlock filterBlockBox NiceScroll';
        this.tableCssClassName = 'listViewBlock ';
    }

    componentDidMount() {
        LoginUtils.isAuthenticated(this.props, browserHistory, '/student/assignments', '/');
        this.getStudentReport();
    }

    startOrResume(assignment) {
        browserHistory.push({
            pathname: '/student/assignments/view/'+assignment.assignmentID
        });
    }
    getNextPage() {

        let context = this;
        const { screenWidth } = this.state;
        const isMobile = screenWidth <= 767;

        if(isMobile & this.state.loadingNextPage === 0)
        {
            let myID = Math.random() * 10000000000000000000;
            this.setState({'loadingNextPage': myID});
            if (this.state.loadingNextPage === myID) {
                let esAttr = this.props.elasticSearchAttributes.map.get('studentAssignment');
                const {dispatch} = this.props;
                ElasticSearchActions.setElasticSearchAttributes(dispatch,'studentAssignment',esAttr.searchTerm, esAttr.pageSize+10, esAttr.activePage,esAttr.sortObj, esAttr.filterValues);
                let elasticSearchQuery  = new ElasticSearchQuery();
                elasticSearchQuery.query("stdassign", ElasticSearchColumns.StudentAssignment, this.props,
                    esAttr.searchTerm, esAttr.pageSize+10, esAttr.activePage , esAttr.sortObj, esAttr.filterValues, function (results, totalRecords) {
                        dispatch({
                            type: RECEIVED_STUDENT_ASSIGNMENT_RESULTS,
                            status: 200,
                            data: JSON.parse(JSON.stringify(results)),
                            alertText: '',
                            alertStyle: '',
                            showAlert: false,
                            totalRecords:totalRecords
                        });
                    }, true, 'assignmentStatus : (Completed OR completed) AND '+'studentID: ("'+context.props.auth.user.userName+'")', ElasticSearchTypes.StudentAssignment);
                this.setState({'loadingNextPage': 0});
            }

        }

    }

    handleAlertDismiss(e) {
        this.setState({'showAlert': false});
    }

    render() {
        const {screenWidth} = this.state;
        let context = this;
        const isMobile = screenWidth < 768;
        let results = this.props.studentPortal && this.props.studentPortal.assignments && this.props.studentPortal.assignments.results ? this.props.studentPortal.assignments.results : [];
        results.forEach(item => {
            let acts = [];
            if (item.assignmentType && item.assignmentType.toLowerCase()==='course') {

                item.attempts = 'N/A';
                if (item.assignedCourses && item.assignedCourses.units) {
                    for (let i = 0; i < item.assignedCourses.units.length; i++) {
                        let unit = item.assignedCourses.units[i];
                        if (unit.activities && unit.activities.length > 0) {
                            unit.activities.forEach(act => {
                                act.teacher = item.teacher;
                                if(act.score){
                                    act.score = parseFloat(act.score).toFixed(2);
                                }else{
                                    act.score = 0;
                                }
                                if(act.progress){
                                    act.progress = parseFloat(act.progress).toFixed(2);
                                }else{
                                    act.progress = 0;
                                }
                                if (act.timeIn) {
                                    act.timeInString = parseHours(act.timeIn) + ':' + parseMinutes(act.timeIn) + ':' + parseSeconds(act.timeIn) ;
                                }else{
                                    act.timeInString = '0:0:0';
                                }
                                if(!act.attempts){
                                    act.attempts = 0;
                                }
                                acts.push(act);
                            });
                        }
                    }
                }
            }
            if (acts.length > 0) {
                item.activities = acts;
            }
            if(item.score){
                item.score =parseFloat(item.score).toFixed(2);
            }else{
                item.score = 0;
            }
            if(item.progress){
                item.progress = parseFloat(item.progress).toFixed(2);
            }else{
                item.progress = 0;
            }
            if (item.timeIn) {
                item.timeInString = parseHours(item.timeIn) + ':' + parseMinutes(item.timeIn) + ':' + parseSeconds(item.timeIn) ;
            }else{
                item.timeInString = '0:0:0';
            }
            if(!item.attempts){
                item.attempts = 0;
            }
            item.id = item.assignmentID;
        });
        let esAttr = this.props.elasticSearchAttributes.map.get('studentAssignment');
        let userName = this.props.auth.user.userName;
        let maintenanceTableData = {
            emptyMessage: 'No records found.',
            showActionMenu: false,
            columns: [
                {
                    name: 'assignmentName', type: 'text', label: 'Assignment', options: {
                    sortable: true, customDom: (record) => {
                        // customDom is not applicable to array type attributes, ex: subjects in activity.

                        return (
                            <td width="40%">
                                <span className="typeIcon"> </span>
                                <span className="contentType ">
                                    <h4 style={{marginBottom: '4px', marginTop:'10px'}}>{record.assignmentName}</h4>
                                    <div>By: <strong>{record.teacher}</strong></div>
                                </span>
                                {record.activities && record.activities.length > 0 && record.assignmentType.toLowerCase()==='course' &&
                                <span className="subRowToggleStudent" onClick={(evt)=> {
                                    let parentRowId = evt.target.parentElement.parentElement.id;
                                    if (evt.target.getAttribute('class').trim().toLowerCase() === 'subRowToggleStudent'.toLowerCase()) {
                                        $('#' + parentRowId + '-child').show();
                                        evt.target.setAttribute('class', 'subRowToggleStudent minus');
                                    } else {
                                        $('#' + parentRowId + '-child').hide();
                                        evt.target.setAttribute('class', 'subRowToggleStudent');
                                    }
                                }}/>}
                            </td>
                        );
                    }
                }
                },
                // {
                //     name: 'teacher',
                //     type: 'text',
                //     label: 'Teacher',
                //     options: {sortable: true, width: '12%'}
                // },
                {
                    name: 'progress',
                    type: 'text',
                    label: 'Progress',
                    options: {sortable: true, customDom: (record) => {
                        // customDom is not applicable to array type attributes, ex: subjects in activity.

                        return (
                            <td width="12%">
                                {record.progress ? Math.round(record.progress) + '%' : '0%'}
                            </td>
                        );
                    }
                    }
                },
                {
                    name: 'score',
                    type: 'text',
                    label: 'Score',
                    options: {sortable: true, customDom: (record) => {
                        // customDom is not applicable to array type attributes, ex: subjects in activity.

                        return (
                            <td width="12%">
                                {record.score ? Math.round(record.score) + '%' : '0%'}
                            </td>
                        );
                    }
                    }
                },
                {
                    name: 'timeInString',
                    type: 'text',
                    label: 'Time In',
                    options: {sortable: true, width: '12%'}
                },
                {
                    name: 'attempts',
                    type: 'text',
                    label: 'Attempts',
                    options: {
                        sortable: false,
                        customDom: (record) => {
                            if(record.assignmentType.toLowerCase()==='course'){
                                return (<td width="12%">N/A</td>);
                            }else{
                                if(record.attempts && parseInt(record.attempts) > 0){
                                    return (
                                        <td className="view-details">
                                            <a href="javascript:void 0" onClick={function (evt) {
                                                let parentAssignmentId = null;
                                                if (evt.target.nodeName === 'A') {
                                                    parentAssignmentId = evt.target.parentElement.parentElement.id;
                                                }
                                                this.viewAttemptDetails(userName, true, record, parentAssignmentId);
                                            }.bind(this)}>
                                                {record.attempts}&nbsp;
                                                {/*<MaterialIcon icon="search" size={32}/>*/}
                                                {/*<img src={details}/>*/}
                                            </a>
                                        </td>
                                    );
                                }else{
                                    return (<td>0</td>);
                                }
                            }
                        }
                    }
                },
                {
                    name: 'id', type: 'text', label: 'View Details', options: {
                    sortable: false,
                    customDom: (record) => {

                        record.activityType && console.info("Activity type", record.activityType)

                        if(record.assignmentType.toLowerCase()==='course'){
                            return (<td></td>);
                        }else if (parseInt(record.progress) < 100)
                        {
                            return (<td></td>);
                        }
                        else
                        {
                            return (
                                <td className="view-details">
                                    <a href="javascript:void 0" onClick={function (evt) {
                                        let parentAssignmentId = null;
                                        if (evt.target.nodeName === 'A') {
                                            parentAssignmentId = evt.target.parentElement.parentElement.id;
                                        } else {
                                            parentAssignmentId = evt.target.parentElement.parentElement.parentElement.id;
                                        }


                                        if (record.activityType === "learning") {
                                            context.setState({
                                                showAlert: true,
                                                alertStyle: 'danger',
                                                alertText: 'There are no details available for learning activities.'
                                            });
                                            setTimeout(() => {
                                                context.setState({
                                                    showAlert: false,
                                                    alertStyle: '',
                                                    alertText: ''
                                                })
                                            }, 4000);
                                        } 
                                        else if((record.assignedCourses && !record.assignedCourses.accessReport && record.assignedCourses.lockReport) || (record.assignedActivities && !record.assignedActivities.accessReport && record.assignedActivities.lockReport)){
                                            context.setState({
                                                showAlert: true,
                                                alertStyle: 'danger',
                                                alertText: 'Result will be declared soon'
                                            });
                                            setTimeout(() => {
                                                context.setState({
                                                    showAlert: false,
                                                    alertStyle: '',
                                                    alertText: ''
                                                })
                                            }, 4000);
                                        }
                                        else {
                                            this.viewStudentDetails(userName, true, record, parentAssignmentId);
                                        }

                                    }.bind(this)}>
                                        <MaterialIcon icon="search" size={32}/>
                                        {/*<img src={details}/>*/}
                                    </a>
                                </td>
                            );
                        }
                    }
                }
                }
            ],
            items: results,
            pageable: true,
            rowClickAction: this.viewActivityOnRowClick,
            totalRecords: this.props.studentPortal.assignments.totalRecords,
            pagination: {
                pageSize: esAttr && esAttr.pageSize?esAttr.pageSize:10,
                activePage: 1,
                showPageInput: true,
                showFirstButton: true,
                showLastButton: true,
                showNextButton: true,
                showPreviousButton: true,
                firstButtonLabel: '<<',
                lastButtonLabel: '>>',
                previousButtonLabel: '<',
                nextButtonLabel: '>',
                showPageInfo: true,
                showGotoInput: false,
                gotoInputLabel: 'Goto',
                pageLinkSize: 5
            },
            itemClass: (record)=> {
                let assignType = record.assignmentType ? record.assignmentType.toLowerCase() : 'learning';
                if (assignType === 'course') {

                    return '';
                    //return 'booksRow';
                } else if (assignType === 'activity' && false) {
                    let type = record.activityType ? record.activityType.toLowerCase() : '';

                    switch (type) {
                        case 'url':
                        case 'pdf':
                            return 'learning';
                        case 'question':
                        case 'questions':
                            return 'questionRow';
                        case 'flashcard':
                        case 'flashcards':
                            return 'flashCard';
                    }
                }
                return '';
                //return 'learning';
            },
            rowExpansion: {
                // There is no action support in expanded rows for now.
                showActionMenu: false,
                expansionConditionExpression: (currentRow)=> {
                    // calculate condition to show expand button with row.
                    let acts = [];
                    if (currentRow.assignmentType && currentRow.assignmentType.toLowerCase() === 'course') {
                        if (currentRow.assignedCourses && currentRow.assignedCourses.units) {
                            for (let i = 0; i < currentRow.assignedCourses.units.length; i++) {
                                let unit = currentRow.assignedCourses.units[i];
                                if (unit.activities && unit.activities.length > 0) {
                                    unit.activities.forEach(act => {
                                        acts.push(act);
                                    });
                                }
                            }
                        }
                    }
                    return acts.length > 0;
                },
                getSubRowClass: (currentRow)=> {
                    // calculate class for sub rows.
                    return '';
                    /*switch (currentRow.activityType.toLowerCase()) {
                     case 'learning':
                     return 'learning';
                     case 'flashcards':
                     case 'flashcard':
                     return 'flashCard';
                     case 'question':
                     return 'questionRow';
                     default:
                     return 'learning';
                     }*/
                },
                expansionKeyInCurrentItem: 'activities', // must be an array attribute in each record.
                expansionItemColumns: [                   // For now only text type is supported if its anything else it will convert it to text.
                    {
                        name: 'activityName', type: 'text', label: 'Activity Name', options: {
                        customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                            return (
                                <td width="40%">
                                    <span className="typeIcon"> </span>
                                    <span className="contentType ">
                                        <h4 style={{marginBottom: '4px', marginTop:'10px'}}>{record.activityName}</h4>
                                    </span>
                                </td>
                            );
                        }
                    }
                    },
                    // {
                    //     name: '12312',
                    //     type: 'text',
                    //     label: 'Teacher',
                    //     options: {sortable: true, width: '11.5%'}
                    // },
                    {
                        name: 'progress',
                        type: 'text',
                        label: 'Progress',
                        options: {sortable: true, customDom: (record) => {
                            // customDom is not applicable to array type attributes, ex: subjects in activity.

                            return (
                                <td width="12.5%">
                                    {record.progress ? Math.round(record.progress) + '%' : '0%'}
                                </td>
                            );
                        }
                        }
                    },
                    {
                        name: 'score',
                        type: 'text',
                        label: 'Score',
                        options: {sortable: true , customDom: (record) => {
                            // customDom is not applicable to array type attributes, ex: subjects in activity.

                            return (
                                <td width="12%">
                                    {record.score ? Math.round(record.score) + '%' : '0%'}
                                </td>
                            );
                        }
                        }
                    },
                    {
                        name: 'timeInString',
                        type: 'text',
                        label: 'Time In',
                        options: {sortable: true, width: '12.5%'}
                    },
                    {
                        name: 'attempts',
                        type: 'text',
                        label: 'Attempts',
                        options: {
                            sortable: false,
                            customDom: (record) => {
                                if(record.attempts && parseInt(record.attempts) > 0){
                                    return (
                                        <td width="12.5%"  className="view-details">
                                            <a href="javascript:void 0" onClick={function (evt) {
                                                let parentAssignmentId = null;
                                                if (evt.target.nodeName === 'A') {
                                                    parentAssignmentId = evt.target.parentElement.parentElement.dataset.parentId;
                                                }
                                                this.viewAttemptDetails(userName, true, record, parentAssignmentId,true);
                                            }.bind(this)}>
                                                {record.attempts}
                                            </a>
                                        </td>
                                    );
                                }else{
                                    return (<td >0</td>);
                                }
                            }
                        }
                    },
                    {
                        name: 'id', type: 'text', label: 'View Details', options: {
                        sortable: false,
                        customDom: (record) => {
                            if (parseInt(record.progress) < 100)
                            {
                                return (<td></td>);
                            }
                            else
                            {
                                return (
                                    <td  className="view-details">
                                        <a href="javascript:void 0" onClick={function (evt) {

                                            let parentAssignmentId = null;
                                            if (evt.target.nodeName === 'A') {
                                                parentAssignmentId = evt.target.parentElement.parentElement.dataset.parentId;
                                            } else {
                                                parentAssignmentId = evt.target.parentElement.parentElement.parentElement.dataset.parentId;
                                            }
                                            if (record.activityType === "learning") {
                                                context.setState ({
                                                    showAlert: true,
                                                    alertStyle: 'danger',
                                                    alertText: 'There are no details available for learning activities.'
                                                });
                                                setTimeout(() => {
                                                    context.setState({
                                                        showAlert: false,
                                                        alertStyle: '',
                                                        alertText: ''
                                                    })
                                                }, 4000);
                                            }
                                            else {
                                                this.viewStudentDetails(userName, true, record, parentAssignmentId, true);
                                            }


                                        }.bind(this)}>
                                            <MaterialIcon icon="search" size={32}/>
                                            {/*<img src={details}/>*/}
                                        </a>
                                    </td>
                                );
                            }
                        }
                    }
                    }
                ],
                actions: [
                    {
                        linkLabel: 'Manage', action: (selectedRecord) => {
                        this.openManageAssignmentPage(selectedRecord);
                    },
                        isConditional: true,
                        conditionExpression: (currentRecord) => {
                            /* return (!isMobile && (currentRecord.ownerName === this.props.auth.user.userName
                             || (this.props.auth.user.userType === "Admin" && this.props.auth.user.schoolName === "LearnerPal")
                             || currentRecord.originalOwner === this.props.auth.user.userName));*/
                            return true;
                        }
                    }
                ],
                rowClickAction: (record)=> {

                }
            }
        };

        return (
            <div id="StudentReport">
                <Heading
                    isQuery={true}
                    customQuery={'((assignmentType: Activity AND assignmentStatus: (Completed OR completed)) OR (assignmentType: Course) ) AND (studentID: "'+ context.props.auth.user.userName +'")'}
                    customURL={ElasticSearchTypes.StudentAssignment}
                    location="studentAssignment"
                    screenNameForElasticSearch="stdassign"
                    actionName={RECEIVED_STUDENT_ASSIGNMENT_RESULTS}
                    heading="Assignment Reports"
                    isSearch={true}
                    isShowButton={true}
                    allColumnsForElasticSearch={ElasticSearchColumns.StudentAssignment}
                    breadcrumbsData={[
                        {
                            name: 'Reports', action: () => {
                                browserHistory.push('/student/reports');
                            }
                        },
                        {
                            name: 'Assignment Reports'
                        }
                    ]}
                />
                {this.state.showAlert &&
                    <div className="row">
                        <div className="col-md-3 col-xs-3" />
                        <div className="col-md-6 col-xs-3">
                            <Alert bsStyle={this.state.alertStyle}
                                onDismiss={this.handleAlertDismiss}>
                                {this.state.alertText}
                            </Alert>
                        </div>
                        <div className="col-md-3 col-xs-3" />
                    </div>
                }
                <div id="tableFiltersAndSideView">
                    <div className={classnames("container-fluid noPad listMainBoxWrapper")}>
                        <Filter
                            boxStyle={true}
                            isQuery={true}
                            customQuery={'((assignmentType: Activity AND assignmentStatus: (Completed OR completed)) OR (assignmentType: Course) ) AND (studentID: "'+ context.props.auth.user.userName +'")'}
                            customURL={ElasticSearchTypes.StudentAssignment}
                            location="studentAssignment"
                            screenNameForElasticSearch="stdassign"
                            actionName={RECEIVED_STUDENT_ASSIGNMENT_RESULTS}
                            viewActivityCssClass={this.filterComponentCssClassName}
                            filterColumns={[
                                { name: 'teacher', type: 'text', label: 'Teacher' }
                            ]}
                            allColumnsForElasticSearch={ElasticSearchColumns.StudentAssignment}
                        />
                        <div className={this.tableCssClassName} style={{ background: '#f7f8f9' }}>
                            <div className="listToBesslide NiceScroll  tableBoxStyle">
                                <div className="blockForlist ">
                                    <MaintenanceTable
                                        isQuery={true}
                                        customQuery={'((assignmentType: Activity AND assignmentStatus: (Completed OR completed)) OR (assignmentType: Course) ) AND (studentID: "'+ context.props.auth.user.userName +'")'}
                                        customURL={ElasticSearchTypes.StudentAssignment}
                                        location="studentAssignment"
                                        actionName={RECEIVED_STUDENT_ASSIGNMENT_RESULTS}
                                        screenNameForElasticSearch="stdassign"
                                        allColumnsForElasticSearch={ElasticSearchColumns.StudentAssignment}
                                        options={maintenanceTableData}
                                        sortAction={{
                                            action: RECEIVED_STUDENT_ASSIGNMENT_RESULTS,
                                            attributes: {
                                                skipAlterForAllRecords: true
                                            },
                                            dataPath: 'data'
                                        }}
                                    />
                                    <ReactScrollPagination
                                        fetchFunc={this.getNextPage}
                                    />
                                    {<StudentAssignmentMaintenanceTableMobile
                                        items={results}
                                        emptyMessage={maintenanceTableData.emptyMessage}
                                        columns={maintenanceTableData.columns}
                                        viewActivityMobile={() => { }}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ProgressIndicator show={this.state.loading} />
                {this.state.showUserDetailsDialog && this.getUserDetailsDialog()}
                {this.state.activityAttemptsDialog && this.getActivityAttemptsDialog()}
            </div>
        );
    }

    loadStudentDetails(assignmentID, studentID, activityID){
        let context = this;
        context.showIndicator();
        return new Promise((resolve, reject) => {
            let context = this;

            let params = {
                assignmentID: assignmentID,
                studentID: studentID,
                activityID: activityID
            };
            let pathTemplate = '/assignment/report/assignmentID/{assignmentID}/activity/{activityID}/student/{studentID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '3').then(function (result) {
                context.hideIndicator();
                resolve(result.data);
            }).catch(function (error) {
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(()=> {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    loadAttemptDetails(assignmentID, studentID, activityID){
        let context = this;
        context.showIndicator();
        return new Promise((resolve, reject) => {
            let context = this;

            let params = {
                assignmentID: assignmentID,
                studentID: studentID,
                activityID: activityID
            };
            let pathTemplate = '/assignment/history/{assignmentID}/student/{studentID}/activity/{activityID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '4').then(function (result) {
                context.hideIndicator();


                let attempts = []
                result.data.forEach(att => {
                    if (att.progress === 100) {
                        attempts.push(att);
                    }
                });
                attempts.sort(function (a,b) {
                    return a.reviewCount - b.reviewCount;
                });
                resolve(attempts);
            }).catch(function (error) {
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(()=> {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    viewStudentDetails(student, fromAssignmentScreen, record, parentAssignmentId, fromChild) {
        let context = this;

        let activityID = null;
        let assignmentID = null;
        let activityName = null;
        let activityType = null;
        if(fromChild){
            assignmentID = parentAssignmentId;
            activityID = record.activityID;
            activityName = record.activityName;
            activityType = record.activityType;
        }else{
            assignmentID = record.assignmentID;
            activityID = record.assignedActivities.activityID;
            activityName = record.assignedActivities.activityName;
            activityType = record.activityType;
        }
        this.loadStudentDetails(assignmentID, student, activityID).then(result => {
            let allData = result;
            if (allData.length > 0)
            {
                allData.sort(function (a, b) {
                    return(new Date(b.completionDate) - new Date(a.completionDate));
                });
            }
            let attemptsArray = [];
            let index=0;
            allData.forEach(attempt=>{
                attemptsArray.push({id:index, name:new Date(attempt.completionDate).toLocaleString()});
                index++;
            });
            context.setState({
                parentAssignmentId: parentAssignmentId,
                showUserDetailsDialog: true,
                userRecord: allData,
                attemptsArray:attemptsArray,
                currentAttempt:0,
                userRecordFromAssignmentScreen: fromAssignmentScreen,
                userRecordActivityID: activityID,
                reportActivityName:activityName,
                reportActivityType:activityType
            });
        }).catch(error=> {
            console.error(error);
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Unable to load student data, please contact administrator.'
            });
            setTimeout(()=> {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);
        })
    }

    viewAttemptDetails(student, fromAssignmentScreen, record, parentAssignmentId, fromChild) {
        let context = this;

        let activityID = null;
        let assignmentID = null;
        let activityName = null;
        let activityType = null;
        if(fromChild){
            assignmentID = parentAssignmentId;
            activityID = record.activityID;
            activityName = record.activityName;
        }else{
            assignmentID = record.assignmentID;
            activityID = record.assignedActivities.activityID;
            activityName = record.assignedActivities.activityName;
            activityType = record.activityType;
        }
        this.loadAttemptDetails(assignmentID, student, activityID).then(result => {
            context.setState({
                parentAssignmentId: parentAssignmentId,
                activityAttemptsDialog: true,
                userRecord: result,
                userRecordFromAssignmentScreen: fromAssignmentScreen,
                userRecordActivityID: activityID,
                reportActivityName:activityName,
                reportActivityType:activityType
            });
        }).catch(error=> {
            console.error(error);
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Unable to load student data, please contact administrator.'
            });
            setTimeout(()=> {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);
        })
    }

    getUserDetailsDialog() {
        let record = this.state.userRecord[this.state.currentAttempt];
        let userRecordFromAssignmentScreen = this.state.userRecordFromAssignmentScreen;
        let userRecordActivityID = this.state.userRecordActivityID;
        let activityName = this.state.reportActivityName;
        let dialogViewActivity = null;
        let actType = null;

        if(userRecordFromAssignmentScreen){
            dialogViewActivity = {activityName:activityName};

            actType = this.state.reportActivityType;
        }else{

            // from inner student details table.
            dialogViewActivity = this.state.currentActivity;
            actType = this.state.assignment.activities.filter( act => {return act.activityID === this.state.currentActivity.activityID})[0].activityType;
        }
        let categories = null;
        let catCount = 0;
        if (record.categoryStats) {
            categories = record.categoryStats.map(cat => {
                catCount++;
                let label = cat.category + (cat.subCategory ? ' - ' + cat.subCategory : '');
                return <tr key={cat.category + '-' + catCount} id={cat.category + '-' + catCount}>
                    <td>{label}:</td>
                    <td>
                        {cat.correctAnswers + ' out of ' + cat.total + ' questions correct (' + cat.score.toFixed(0) + '%)'}
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12   paddnone barchrtlghtbl"
                             style={{marginRight: '10px', marginTop: '5px', background:'white', width:'100%'}}>
                            <div className="filled" style={{width: (cat.score ? cat.score : 0) + '%', background:(cat.score && cat.score > 64 ? 'green' : 'red'), height: '9px',
                                borderRadius: '5px'}}></div>
                        </div>
                    </td>
                </tr>;
            })
        }

        let catStats = <table className="table" id="act-cat-stats-user">
            <tbody>
            {categories}
            </tbody>
        </table>;

        let rows = null;
        let correctNumbers = 0;
        let rowCount = 0;

        console.table(record);

        if (record.questionStats) {
            rows = record.questionStats.map(question => {
                rowCount++;

                if (question.essayType && question.essayType == "true") {
                    if (question.essayScore != "N/A") {
                        question.questionResult = question.essayScore < 65 ? false : true
                    }
                    if (!question.essayComments && question.essayScore && question.essayScore != "N/A") {
                        question.essayComments = "<i>No Comments</i>"
                    }
                }

                if (question.questionResult) {
                    correctNumbers += 1;
                }

                let answerString = undefined;

                if (actType === 'question') {
                    answerString = (question.studentAnswer && question.studentAnswer.trim() !== 'empty-result' && question.studentAnswer.trim() !== 'empty-answer') ? question.studentAnswer : 'No Answer Submitted';
                }
                return <tr className="" id={rowCount + "-user-row-" + rowCount}
                    key={rowCount + "-user-row-" + question.questionID}>
                    <td style={{ border: '1px solid #555555' }}>
                        <p className="QuestionNoBorder">
                            <strong style={{ float: 'left' }}>{rowCount + '. '} {question.questionResult ? <span style={{ color: 'green' }}><MaterialIcon icon="check" size={32} /></span> : <span style={{ color: 'red' }}><MaterialIcon icon="close" size={32} /></span>}</strong>{htmlParser(`<span dir lang = ${this.props.auth.user.prefLanguage}>` + question.question.replace('[[[LP_ANSWERS]]]', '') + '</span>')}
                        </p>
                        <br />

                        {question.answer && <div>
                            <div className="std-q-heading">Correct Answer</div>
                            <p>
                                {htmlParser(`<span dir lang = ${this.props.auth.user.prefLanguage}>` + question.answer + '</span>')}
                            </p>
                        </div>
                        }

                        <div className="std-q-heading">Student&apos;s Response</div>
                        <p style={{ whiteSpace: "pre-line", textAlign: "justify" }}>
                            {htmlParser(`<span dir lang = ${this.props.auth.user.prefLanguage}>` + answerString + '</span>')}
                        </p>

                        {question.essayType && question.essayType == "true" && question.essayComments ? <div>
                            <div className="std-q-heading">Teacher&apos;s Comment</div>
                            <p>
                                {htmlParser('<span>' + question.essayComments + '</span>')}
                            </p>
                        </div> : ""
                        }

                        {question.essayType && question.essayType == "true" && question.essayScore ? <div>
                            <div className="std-q-heading">Score</div>
                            <p>
                                {question.essayScore == "N/A" ? htmlParser('<span>' + question.essayScore + '</span>') : ( question.essayScore < 65 ? (htmlParser('<span style=" color: red; ">' + question.essayScore + '</span>')) : (htmlParser('<span style=" color: green; ">' + question.essayScore + '</span>')) )}
                            </p>
                        </div> : ""
                        }

                    </td>

                    {false && dialogViewActivity.activityType === 'question' &&
                        <td>
                            <a href="javascript:void 0" onClick={function () {
                                this.userQuestionDetails(question)
                            }.bind(this)}><MaterialIcon icon="search" size={24} /></a>
                        </td>
                    }
                </tr>;
            })
        }

        let activityStats = <table className="table" id="act-stats-user">
            <tbody>
            <tr>
                <td><MaterialIcon icon="assignment" size={24}/> Activity Name:</td>
                <td>{dialogViewActivity.activityName}</td>
            </tr>
            <tr>
                <td><MaterialIcon icon="accessibility" size={24}/> Student Name:</td>
                <td>{record.studentName}</td>
            </tr>
            <tr>
                <td><MaterialIcon icon="assessment" size={24}/> Score:</td>
                <td>{correctNumbers + ' out of ' + (record.questionStats ? record.questionStats.length : 0) + ' questions correct (' +record.score.toFixed(0)}{'%)'}</td>
            </tr>
            <tr>
                <td><MaterialIcon icon="alarm" size={24}/> Time In:</td>
                {record.timeIn ? <td>{parseHours(record.timeIn)}{' hours '}{parseMinutes(record.timeIn)}{' mins '}{parseSeconds(record.timeIn)}{' seconds'}</td> : <td>N/A</td>}
            </tr>
            <tr>
                <td><MaterialIcon icon="date_range" size={24}/> Completion Date:</td>
                {record.completionDate != "N/A" && <td>{new Date(record.completionDate).toLocaleString()}</td>}
                {record.completionDate === "N/A" && <td>{record.completionDate}</td>}
            </tr>
            </tbody>
        </table>;

        let studentStats = <table className="table" id="student-stat">
            <thead>
            <tr>
                <th style={{background: '#f7f8f9', border:'1px solid #555555'}}>
                    Questions
                </th>
                {dialogViewActivity.activityType === 'question' && false && <th>
                </th>}
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </table>;

        let table = <div id="user-activity-analysis" className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading">Student Details </h1>
                <p style={{overflow:'auto'}}>
                    {activityStats}
                </p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading"  >Category Analysis </h1>
                <p style={{overflow:'auto'}}>
                    {catStats}
                </p>
            </div>
            {actType === 'question' &&
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading">Student Question Analysis </h1>
                <p style={{overflow: 'auto'}}>
                    {studentStats}
                </p>
            </div>}
        </div>;
        return <Modal id="user-detail-dialog"
                      show={this.state.showUserDetailsDialog}
                      onHide={()=> {
                          this.setState({showUserDetailsDialog: false, userRecord: null, userRecordFromAssignmentScreen:null,userRecordActivityID:null});
                      }}
                      container={this}
                      aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Student Details</Modal.Title>
                {this.state.attemptsArray.length > 1 && <div className="col-md-4" style={{marginTop:'20px'}}>
                    <SelectFieldGroup
                        field="currentAttempt"
                        value={this.state.currentAttempt}
                        options={this.state.attemptsArray}
                        onChange={(event)=>{
                            this.setState({currentAttempt:event.target.value});
                        }}
                    />
                </div>}
            </Modal.Header>
            <Modal.Body>
                <div id="choice-dialog-content">
                    {table}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=> {
                    this.setState({showUserDetailsDialog: false, userRecord: null, userRecordFromAssignmentScreen:null,userRecordActivityID:null});
                }}>Close</Button>
            </Modal.Footer>
        </Modal>
    }

    getActivityAttemptsDialog() {
        let record = this.state.userRecord;
        let userRecordFromAssignmentScreen = this.state.userRecordFromAssignmentScreen;
        let userRecordActivityID = this.state.userRecordActivityID;
        let activityName = this.state.reportActivityName;
        let actType = null;
        record = record.sort((a,b) => {

            return parseInt(a.reviewCount) - parseInt(b.reviewCount);});
        let rows = null;
        let correctNumbers = 0;
        let rowCount = 0;
        if (record) {
            rows = record.map(attempt => {
                rowCount++;
                return <tr className="" id={attempt.reviewCount + "-user-row-" + rowCount} key={rowCount + "-user-row-" + attempt.reviewCount}>
                    <td>
                        {attempt.reviewCount}
                    </td>
                    <td>
                        {attempt.score && parseFloat(attempt.score).toFixed(0) + '%'}
                    </td>
                    <td>
                        {attempt.progress}
                    </td>
                    <td>
                        {attempt.timeIn && <td>{parseHours(attempt.timeIn) + ':' + parseMinutes(attempt.timeIn) + ':' + parseSeconds(attempt.timeIn)}</td>}
                    </td>
                    <td>
                        {new Date(attempt.completedAt).toLocaleString()}
                    </td>
                </tr>;
            })
        }

        let activityStats = <table className="table" id="act-stats-user">
            <tbody>
            <tr>
                <td><MaterialIcon icon="assignment" size={24}/> Activity Name:</td>
                <td>{activityName}</td>
            </tr>
            </tbody>
        </table>;

        let studentStats = <table className="table" id="student-attempt-history" style={{ border:'1px solid #555555'}}>
            <thead>
            <tr style={{background: '#f7f8f9', border:'1px solid #555555'}}>
                <th>
                    Attempt
                </th>
                <th>
                    Score
                </th>
                <th>
                    Progress
                </th>
                <th>
                    Time In
                </th>
                <th>
                    Completion Date
                </th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </table>;

        let table = <div id="user-activity-analysis" className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <p style={{overflow:'auto'}}>
                    {activityStats}
                </p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading">Attempt Details </h1>
                <p style={{overflow: 'auto'}}>
                    {studentStats}
                </p>
            </div>
        </div>;
        return <Modal id="user-attempt-history-dialog"
                      show={this.state.activityAttemptsDialog}
                      onHide={()=> {
                          this.setState({activityAttemptsDialog: false, userRecord: null, userRecordFromAssignmentScreen:null,userRecordActivityID:null});
                      }}
                      container={this}
                      aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Attempt History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="choice-dialog-content">
                    {table}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=> {
                    this.setState({activityAttemptsDialog: false, userRecord: null, userRecordFromAssignmentScreen:null,userRecordActivityID:null});
                }}>Close</Button>
            </Modal.Footer>
        </Modal>
    }

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    getStudentReport() {
        let {dispatch}= this.props;
        let context = this;
        context.showIndicator();
        ElasticSearchActions.setElasticSearchAttributes(dispatch,'studentAssignment'.toString(),'', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):10, 1 ,{createdAt:{order: "desc"}}, []);
        let elasticSearchQuery  = new ElasticSearchQuery();
        elasticSearchQuery.query("stdassign", ElasticSearchColumns.StudentAssignment, this.props,
            '', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):10, 1 ,{createdAt:{order: "desc"}}, [], function (results, totalRecords) {
                context.hideIndicator();
                dispatch({
                    type: RECEIVED_STUDENT_ASSIGNMENT_RESULTS,
                    status: 200,
                    data: JSON.parse(JSON.stringify(results)),
                    alertText: '',
                    alertStyle: '',
                    showAlert: false,
                    totalRecords:totalRecords
                });
            }, true, '((assignmentType: Activity AND assignmentStatus: (Completed OR completed)) OR (assignmentType: Course) ) AND (studentID: "'+ context.props.auth.user.userName +'")', ElasticSearchTypes.StudentAssignment);
    }
}

function mapStateToProps(state) {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(StudentReport);
