// Import React
import React from "react";
import { Parser } from "html-to-react";

// Define the functional component
const htmlParser = (toParse, autoPlay = false) => {
  let htmlToReactParser = new Parser();
  if(toParse){
    if(!autoPlay){
      toParse = toParse.replace( /<[^>]*\bautoplay\b[^>]*>/g, (match) => {
        return match.replace(/autoplay/g, '');
      });
    }
    if (toParse.includes("<video")) {
      let videoHtml = htmlToReactParser.parse(
        autoPlay ? 
          toParse
            .toString()
            .replace(/<video/gi, '<video autoplay controlsList="nodownload"') :
          toParse
            .toString()
            .replace(/<video/gi, '<video controlsList="nodownload"')
      );
      let i = 0;
      if(videoHtml.length){
        for (const node of videoHtml) {
            videoHtml[i++] = fixAttributes(node);
        }
    } else {
        videoHtml = fixAttributes(videoHtml);
    }
      function fixAttributes(node) {
        if (node && node.props) {
          const newProps = {
            ...node.props,
          };
          if (node.props.controlslist) {
            newProps.controlsList = node.props.controlslist;
            newProps.onContextMenu = (e) => {
              e.preventDefault();
            };
            delete newProps.controlslist;
          }
          return React.createElement(
            node.type,
            newProps,
            ...(!node.props.children
              ? []
              : Array.isArray(node.props.children)
              ? node.props.children.map((child) => fixAttributes(child))
              : [fixAttributes(node.props.children)])
          );
        }
        return node;
      }
      return videoHtml;
    } else {
      return htmlToReactParser.parse(toParse.toString());
    }
  }
};

// Export the component
export default htmlParser;
