import React, { Component } from "react";
import { gapi } from 'gapi-script';
import { connect } from "react-redux";
import { Alert, Modal, Button } from "react-bootstrap";
import ProgressIndicator from "../../../progress-indicator/progress-indicator";
import apiClient from "../../../../actions/api-client";
import "./prac-course.css";
import $ from "jquery";
import tick from "../../../../assets/images/tick.png";
import hint from "./hint.png";
import warning from "./warning.png";
import url from "./url.jpg";
import pdf from "./pdf.png";
import { browserHistory } from "react-router";
import ClevertapReact from "clevertap-react";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import MaterialIcon from "react-google-material-icons";
import Draggable, { DraggableCore } from 'react-draggable'; // Both at the same time
import Heading from "../../../heading/heading";
import AWS from 'aws-sdk';
import {
    CognitoUserPool,
    AuthenticationDetails,
    CognitoUser
} from 'amazon-cognito-identity-js';
import config from '../../../../config'
import {
    parseHours,
    parseMinutes,
    parseSeconds
} from '../../../../utils/timeParser';
import htmlParser from "../../../common/htmlParser";

class PracticeCourse extends Component {
    constructor(props) {
        super(props);
        this.getAssignmentByIdAndStudentId = this.getAssignmentByIdAndStudentId.bind(this);
        this.getCourseSidePanel = this.getCourseSidePanel.bind(this);
        this.loadActivity = this.loadActivity.bind(this);
        this.isActivityComplete = this.isActivityComplete.bind(this);
        this.isPracticeActivity = this.isPracticeActivity.bind(this);
        this.setTimer = this.setTimer.bind(this);
        this.getTimerValue = this.getTimerValue.bind(this);
        this.resumeActivity = this.resumeActivity.bind(this);
        this.getMainContentTopHeading = this.getMainContentTopHeading.bind(this);
        this.generateQuestionView = this.generateQuestionView.bind(this);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
        this.pause = this.pause.bind(this);
        this.hint = this.hint.bind(this);
        this.print = this.print.bind(this);
        this.submit = this.submit.bind(this);
        this.end = this.end.bind(this);
        this.questionChoiceSelected = this.questionChoiceSelected.bind(this);
        this.sendActivityAssignmentResult = this.sendActivityAssignmentResult.bind(this);
        this.sendActivityAssignmentResultForGoogle = this.sendActivityAssignmentResultForGoogle.bind(this);
        this.getCheckAnswerDialog = this.getCheckAnswerDialog.bind(this);
        this.getTestReportDialog = this.getTestReportDialog.bind(this);
        this.getHintDialog = this.getHintDialog.bind(this);
        this.getActivitySwitchWarningDialog = this.getActivitySwitchWarningDialog.bind(this);
        this.switchActivity = this.switchActivity.bind(this);
        this.generateFlashcardView = this.generateFlashcardView.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
        this.getNoAnswerSelectionWarningDialog = this.getNoAnswerSelectionWarningDialog.bind(this);
        this.onRate = this.onRate.bind(this);
        this.checkEmptyAnswer = this.checkEmptyAnswer.bind(this);
        this.getNoAnswerAssessmentDialog = this.getNoAnswerAssessmentDialog.bind(this);
        this.getAssessmentCannotBeDoneAgainDialog = this.getAssessmentCannotBeDoneAgainDialog.bind(this);
        this.checkForSkippedAnswers = this.checkForSkippedAnswers.bind(this);
        this.getPauseDialog = this.getPauseDialog.bind(this);

        this.params = this.props.params;

        this.state = {
            timerSeconds: 0,
            timerMinutes: 0,
            timerHours: 0,
            questionActivityResults: new Map(),
            flashcardActivityResults: new Map(),
            flashcardRight: 0,
            showNextDialog: false,
            showHintDialog: false,
            showTestReportDialog: false,
            activitySwitchWarningDialog: false,
            assignmentProgress: 0,
            currentActivityProgress: 0,
            currentActivityComplete: true,
            preserveOrder: new Map(),
            lockedCount: 0,
            showNoAnswerSelectionWarningDialog: false,
            viewDefinition: false,
            currentRating: 0,
            showNoAnswerAssessment: false,
            assessmentCannotBeDoneAgain: false,
            assessmentRedirect: false,
            essayActivity: false,
            showPauseDialog: false,
            pausedActivity: false,
            screenWidth: window.innerWidth
        };
    }
    handleWindowSizeChange = () => {
        this.setState({ screenWidth: window.innerWidth });
    };

    componentDidMount() {
        if ( this.props.auth.user.federatedIdentity ) {
            window.gapi.load('auth2', () => {
                this.auth2 = gapi.auth2.init({
                    client_id: config.googleClientId,
                })
                this.auth2.then(() => {

                    console.log('on init');
                    let currentUser = this.auth2.currentUser.get();
                    currentUser.reloadAuthResponse().then(
                        (result)=> {
                            this.setState({ "gToken" : result});
                        },
                        (err) => {
                            console.log("Could Not refresh Idenity Token", err);
                        }
                    );

                });
            });

        }
        let context = this;
        if (this.props.location.state) {
            this.setState(this.props.location.state);
        }
        else if (this.params.id) {
            this.setState({ subjectID: this.params.id });
        }
        this.showIndicator();
        this.getAssignmentByIdAndStudentId(context.params.id).then(result => {
            this.hideIndicator();

            let assignment = result.assignment;
            if (assignment.activityType &&
                assignment.activityType.toLowerCase() !== "course" &&
                assignment.assignedActivities.activityType.toLowerCase() === 'question' &&
                assignment.assignedActivities.assessmentType &&
                assignment.assignedActivities.assessmentType.toLowerCase() === 'assessment' &&
                assignment.assignedActivities.progress === 100) {
                context.setState({ assessmentCannotBeDoneAgain: true, assessmentRedirect: true });
            } else {
                if (assignment.activityType) {
                    let state = {
                        ...context.state
                    };
                    if (assignment.activityType.toLowerCase() === 'course') {
                        state.assignment = assignment;
                        state.view = 'course';
                        state.course = assignment.assignedCourses;
                    } else {
                        // its an activity. need to load.
                        state.assignment = assignment;
                        state.view = 'activity';
                        state.currentActivityComplete = false;

                        let activity = assignment.assignedActivities;
                        if (activity.activityType.toLowerCase() === 'question' || activity.activityType.toLowerCase() === 'questions') {

                            // filter only unanswered questions.
                            let unanswered = [];
                            let answered = [];
                            activity.questions.forEach(question => {
                                if (!question.hasResult) {
                                    unanswered.push(question);
                                } else {
                                    answered.push(question);
                                }
                            });

                            //Randomize.
                            let randomized = new Set();
                            while (randomized.size !== unanswered.length) {
                                let question = unanswered[Math.floor(Math.random() * (unanswered.length))];
                                console.log(question);
                                randomized.add(question)
                            }
                            randomized = Array.from(randomized);
                            let currentIndex = 0;

                            state.contentView = 'question';
                            state.currentActivity = activity;
                            state.answeredQuestions = answered;
                            state.unansweredQuestions = randomized;
                            state.currentQuestionIndex = currentIndex;
                            state.isPractice = !(activity.assessmentType.toLowerCase() === 'asessment');

                            // paused state - get previous state if activity was paused.

                            let context = this;
                            if (activity.answeredQuestion) {
                                for (let i = 0; i < activity.answeredQuestion.length; i++) {
                                    let ans = activity.answeredQuestion[i];
                                    let questionKey = ans.activityID + "-" + ans.questionID;
                                    context.state.questionActivityResults.set(questionKey, {
                                        studentID: ans.stdID,
                                        questionID: ans.questionID,
                                        activityID: ans.activityID,
                                        assignmentID: ans.assignmentID,
                                        timer: ans.timer,
                                        studentAnswer: ans.stdAnswer,
                                        hasResult: true
                                    });
                                }
                            }
                        } else if (activity.activityType.toLowerCase() === 'flashcard' || activity.activityType.toLowerCase() === 'flashcards') {
                            // filter only unanswered flashcards.
                            let unanswered = [];
                            let answered = [];
                            activity.words.forEach(word => {
                                if (!word.hasResult) {
                                    unanswered.push(word);
                                } else {
                                    answered.push(word);
                                }
                            });

                            //Randomize.
                            let randomized = new Set();
                            while (randomized.size !== unanswered.length) {
                                let word = unanswered[Math.floor(Math.random() * (unanswered.length))];
                                console.log(word);
                                randomized.add(word)
                            }
                            randomized = Array.from(randomized);
                            let currentIndex = 0;

                            state.contentView = 'flashcard';
                            state.currentActivity = activity;
                            state.answeredFlashcards = answered;
                            state.unansweredFlashcards = randomized;
                            state.currentFlashcardIndex = currentIndex;
                            state.isPractice = false;

                        } else {
                            state.contentView = 'learning';
                            state.currentActivity = activity;
                        }
                    }
                    if (!this.state.timerSet) {
                        for (var i = 1; i < 99999; i++) { window.clearInterval(i); }
                        this.setState({ timerSet: null });
                        this.setTimer();
                        state.timerSet = true;
                    }
                    context.setState(state);
                }
            }

        }).catch(error => {

            context.hideIndicator();
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Practice course is currently unavailable. Please contact administrator.'
            });
            setTimeout(() => {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);
        });
        window.addEventListener('beforeunload', this.keepOnPage);

    }
    componentWillUnmount() {
        for (var i = 1; i < 99999; i++) { window.clearInterval(i); }
        this.setState({ timerSet: null });
        window.removeEventListener('beforeunload', this.keepOnPage);
    }
    keepOnPage(e) {
        let message = 'Unsaved changes will be lost';
        e.returnValue = message;
        return message;
    }
    // loadActivity(activity, unitID) {
    //     let context = this;
    //
    //     // assuming this is a course assignment, because the only way this function is called is through side panel of a course assignment.
    //
    //     new Promise((resolve, reject) => {
    //         // load activity with its execution details. {count, progress, avg score, hasResult for each question if its a question type.}
    //         let course = context.state.assignment.assignedCourses;
    //         // always have a single unit
    //         let unit = course.units.filter(unit => unit.unitID === unitID)[0];
    //         // always have an activity.
    //         let clickedActivity = unit.activities.filter(act => act.activityID === activity.activityID)[0];
    //
    //         resolve({activity: clickedActivity, unit: unit});
    //     }).then(result => {
    //         let activity = result.activity;
    //         let unit = result.unit;
    //         // Act loaded. set to main frame.
    //         if (activity.activityType.toLowerCase() === 'question') {
    //
    //             // filter only unanswered questions.
    //             let unanswered = [];
    //             let answered = [];
    //             activity.questions.forEach(question => {
    //                 if (!question.hasResult) {
    //                     unanswered.push(question);
    //                 } else {
    //                     answered.push(question);
    //                 }
    //             });
    //
    //             //Randomize.
    //             let randomized = new Set();
    //             while (randomized.size !== unanswered.length) {
    //                 let question = unanswered[Math.floor(Math.random() * (unanswered.length))];
    //                 console.log(question);
    //                 randomized.add(question)
    //             }
    //             randomized = Array.from(randomized);
    //             let currentIndex = 0;
    //
    //             context.setState({
    //                 contentView: 'question',
    //                 currentActivity: activity,
    //                 currentActivityProgress: 0,
    //                 pausedActivity: false,
    //                 currentUnit: unit,
    //                 answeredQuestions: answered,
    //                 unansweredQuestions: randomized,
    //                 currentQuestionIndex: currentIndex,
    //                 isPractice: !(activity.assessmentType && activity.assessmentType.toLowerCase() === 'assessment'),
    //                 currentActivityComplete: false
    //             });
    //         } else if (activity.activityType.toLowerCase() === 'flashcard' || activity.activityType.toLowerCase() === 'flashcards') {
    //             let state = {...context.state};
    //             // filter only unanswered flashcards.
    //             let unanswered = [];
    //             let answered = [];
    //             activity.words.forEach(word => {
    //                 if (!word.hasResult) {
    //                     unanswered.push(word);
    //                 } else {
    //                     answered.push(word);
    //                 }
    //             });
    //
    //             //Randomize.
    //             let randomized = new Set();
    //             while (randomized.size !== unanswered.length) {
    //                 let word = unanswered[Math.floor(Math.random() * (unanswered.length))];
    //                 console.log(word);
    //                 randomized.add(word)
    //             }
    //             randomized = Array.from(randomized);
    //             let currentIndex = 0;
    //
    //             state.contentView = 'flashcard';
    //             state.currentActivity = activity;
    //             state.answeredFlashcards = answered;
    //             state.unansweredFlashcards = randomized;
    //             state.currentFlashcardIndex = currentIndex;
    //             state.isPractice = false;
    //             state.currentUnit = unit;
    //             state.currentActivityComplete= false;
    //
    //             context.setState(state);
    //         } else {
    //             // learning / pdf / url.
    //             context.setState({
    //                 contentView: 'learning',
    //                 currentActivity: activity,
    //                 currentActivityProgress: 0,
    //                 pausedActivity: false,
    //                 currentUnit: unit,
    //                 currentActivityComplete: false
    //             });
    //         }
    //     }).catch(error => {
    //         context.setState({
    //             showAlert: true,
    //             alertStyle: 'danger',
    //             alertText: 'Activity cannot be loaded please contact administrator.'
    //         });
    //         setTimeout(()=> {
    //             context.setState({
    //                 showAlert: false,
    //                 alertStyle: '',
    //                 alertText: ''
    //             })
    //         }, 4000);
    //
    //     });
    //
    //     console.log(activity, 'Load activity called.');
    // }
    //

    loadActivity(activity, unitID) {
        let context = this;

        let params = {
            activityID: activity.activityID
        }
        let body = {};
        let that = this;
        apiClient.invokeApi(params, '/activity/{activityID}', 'GET', {}, body).then(function (result) {
            // that.setState({alertText:result.data, alertStyle:'success', showAlert:true});
            new Promise((resolve, reject) => {
                // load activity with its execution details. {count, progress, avg score, hasResult for each question if its a question type.}
                let course = context.state.assignment.assignedCourses;
                // always have a single unit
                let unit = course.units.filter(unit => unit.unitID === unitID)[0];
                // always have an activity.
                let clickedActivity = result.data;
                if (activity.assessmentType) {
                    clickedActivity.assessmentType = activity.assessmentType;
                }

                resolve({ activity: clickedActivity, unit: unit });
            }).then(result => {

                let activity = result.activity;
                let unit = result.unit;
                // Act loaded. set to main frame.
                if (activity.activityType.toLowerCase() === 'question') {

                    // filter only unanswered questions.
                    let unanswered = [];
                    let answered = [];
                    activity.questions.forEach(question => {
                        if (!question.hasResult) {
                            unanswered.push(question);
                        } else {
                            answered.push(question);
                        }
                    });

                    //Randomize.
                    let randomized = new Set();
                    while (randomized.size !== unanswered.length) {
                        let question = unanswered[Math.floor(Math.random() * (unanswered.length))];
                        console.log(question);
                        randomized.add(question)
                    }
                    randomized = Array.from(randomized);
                    let currentIndex = 0;

                    context.setState({
                        contentView: 'question',
                        currentActivity: activity,
                        currentActivityProgress: 0,
                        currentUnit: unit,
                        answeredQuestions: answered,
                        unansweredQuestions: randomized,
                        currentQuestionIndex: currentIndex,
                        isPractice: activity.assessmentType ? activity.assessmentType.toLowerCase() === 'practice' : activity.assessmentType.toLowerCase() === 'practice',
                        currentActivityComplete: false,
                        pausedActivity: false
                    });
                } else if (activity.activityType.toLowerCase() === 'flashcard' || activity.activityType.toLowerCase() === 'flashcards') {
                    let state = { ...context.state };
                    // filter only unanswered flashcards.
                    let unanswered = [];
                    let answered = [];
                    activity.words.forEach(word => {
                        if (!word.hasResult) {
                            unanswered.push(word);
                        } else {
                            answered.push(word);
                        }
                    });

                    //Randomize.
                    let randomized = new Set();
                    while (randomized.size !== unanswered.length) {
                        let word = unanswered[Math.floor(Math.random() * (unanswered.length))];
                        console.log(word);
                        randomized.add(word)
                    }
                    randomized = Array.from(randomized);
                    let currentIndex = 0;

                    state.contentView = 'flashcard';
                    state.currentActivity = activity;
                    state.answeredFlashcards = answered;
                    state.unansweredFlashcards = randomized;
                    state.currentFlashcardIndex = currentIndex;
                    state.isPractice = false;
                    state.currentUnit = unit;
                    state.currentActivityComplete = false;

                    context.setState(state);
                } else {
                    // context.completeLearningActivity(null, activity, unit, context.state.assignment.assignedCourses, context.state.assignment.assignmentID);
                    // learning / pdf / url.
                    context.setState({
                        contentView: 'learning',
                        currentActivity: activity,
                        currentActivityProgress: 0,
                        currentUnit: unit,
                        currentActivityComplete: false,
                        pausedActivity: false
                    });
                }
            }).catch(error => {

                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: "Oops, Sorry that doesn't seem to work. If you need help, contact support@learnerpal.com"
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);

            });
        });
        // assuming this is a course assignment, because the only way this function is called is through side panel of a course assignment.



        console.log(activity, 'Load activity called.');
    }


    isActivityComplete(act) {

        return act.progress === 100;
    }

    isPracticeActivity(act) {
        // console.log(act);
        if (!act || !act.assessmentType) {
            return true;
        } else if (act && act.assessmentType) {
            return act.assessmentType.toLowerCase() === 'practice';
        } else {
            return false;
        }


    }

    isResumeable(act) {
        //return act.assessmentType.toLowerCase() === 'practice' && (act.progress.trim() && act.progress.trim() !== '100%' && act.progress.trim() !== '0%');
        return act.progress > 0 && act.progress < 100;
    }

    resumeActivity(act) {
        console.log(act, 'Resume activity called.');
    }

    switchActivity(evt, act, unitID) {
        this.loadActivity(act, unitID);

        let menus = [];
        if (evt.target.nodeName === 'A') {
            menus = [...document.querySelectorAll('[id=' + evt.target.parentElement.parentElement.parentElement.parentElement.id + '] .fieldBox div.fieldBoxFullWidth')];
        } else if (evt.target.nodeName === 'LABEL') {
            menus = [...document.querySelectorAll('[id=' + evt.target.parentElement.parentElement.parentElement.id + '] .fieldBox div.fieldBoxFullWidth')];
        } else if (evt.target.id === 'activityTypeDisplay') {
            menus = [...document.querySelectorAll('[id=' + evt.target.parentElement.parentElement.parentElement.parentElement.id + '] .fieldBox div.fieldBoxFullWidth')];
        } else {
            menus = [...document.querySelectorAll('[id=' + evt.target.parentElement.parentElement.id + '] .fieldBox div.fieldBoxFullWidth')];
        }
        for (var i = 0; i < menus.length; i++) {
            let menu = menus[i];
            menu.classList.remove('active');
        }
        if (evt.target.nodeName === 'A') {
            evt.target.parentElement.parentElement.classList.add('active');
        } else if (evt.target.nodeName === 'LABEL') {
            evt.target.parentElement.classList.add('active');
        } else if (evt.target.id === 'activityTypeDisplay') {
            evt.target.parentElement.parentElement.classList.add('active');
        } else {
            evt.target.classList.add('active');
        }
    }

    getSidePanelUnitHeadingActivities(activities, unitID) {
        let context = this;
        let result = [];
        if (activities) {
            result = activities.map(act => {
                let activityTypeDisplay = "";

                switch (act.activityType.toLowerCase()) {
                    case "learning":
                        if (act.documentType.toLowerCase() === 'url') {
                            activityTypeDisplay = "URL Activity"
                        } else if (act.documentType.toLowerCase() === 'pdf') {
                            activityTypeDisplay = "PDF Activity"
                        }
                        else if (act.documentType.toLowerCase() === 'video') {
                            activityTypeDisplay = "Video Activity"
                        }
                        break;
                    case "flashcards":
                        activityTypeDisplay = "Flashcard Activity"
                        break;
                    case "question":
                        if (act && act.assessmentType && act.assessmentType.toLowerCase() === "assessment") {
                            activityTypeDisplay = "Assessment Question Activity"
                        } else if (act && act.assessmentType && act.assessmentType.toLowerCase() === "interactive") {
                            activityTypeDisplay = "Interactive Question Activity"
                        } else {
                            activityTypeDisplay = "Practice Question Activity"
                        }
                        break;
                    default:

                }
                let lockEntry = { id: act.activityID, isLocked: true, complete: false };
                if (context.state.lockedCount === 0) {
                    lockEntry.isLocked = false;
                    if (act.progress === 100) {
                        lockEntry.complete = true;
                    }
                    context.state.lockedCount += 1;
                } else {
                    if (act.progress === 100 || act.activityType === 'learning') {
                        lockEntry.isLocked = false;
                        lockEntry.complete = true;
                    }
                }
                context.state.preserveOrder.set(act.activityID, lockEntry);

                return <div className="fieldBox" key={act.activityID} id={act.activityID} onClick={(evt) => {

                    let index = 0;
                    let dontProceed = true;
                    if (context.state.assignment.preserveOrder) {
                        let allLinks = [...document.querySelectorAll('#all-units .fieldBox')];
                        for (var i = 0; i < allLinks.length; i++) {
                            if (allLinks[i].id === act.activityID) {
                                index = i;
                                break;
                            }
                        }
                        let clicked = context.state.preserveOrder.get(allLinks[index].id);
                        if (index > 0) {
                            // make sure all previous are completed.
                            for (var j = 0; j < index; j++) {
                                let item = context.state.preserveOrder.get(allLinks[j].id);
                                if (item.isLocked === true || item.complete === false) {
                                    dontProceed = false;
                                    context.setState({
                                        showAlert: true,
                                        alertStyle: 'danger',
                                        alertText: 'Activity is locked, Please complete previous activity first.'
                                    });
                                    setTimeout(() => {
                                        context.setState({
                                            showAlert: false,
                                            alertStyle: '',
                                            alertText: ''
                                        })
                                    }, 3000);
                                    break;
                                }
                            }
                        }
                    }

                    if (dontProceed) {

                        if (context.isActivityComplete(act) && act.assessmentType && act.assessmentType === "assessment") {

                            context.setState({
                                assessmentCannotBeDoneAgain: true
                            });

                        } else if (!context.state.pausedActivity && !context.state.currentActivityComplete && context.state.currentActivity && context.state.currentActivityProgress > 0 && context.state.currentActivityProgress < context.state.currentActivity.questions.length) {
                            evt.persist();
                            if (context.state.currentActivity.activityID !== act.activityID) {
                                context.setState({
                                    activitySwitchWarningDialog: true,
                                    activitySwitchWarningData: evt,
                                    activitySwitchAct: act,
                                    activitySwitchUnitId: unitID
                                });
                            }
                        } else if (!context.state.pausedActivity && !context.state.currentActivityComplete && context.state.currentActivity && context.state.currentActivityProgress > 0 && context.state.currentActivityProgress < context.state.currentActivity.words.length) {
                            evt.persist();
                            if (context.state.currentActivity.activityID !== act.activityID) {
                                context.setState({
                                    activitySwitchWarningDialog: true,
                                    activitySwitchWarningData: evt,
                                    activitySwitchAct: act,
                                    activitySwitchUnitId: unitID
                                });
                            }
                        }
                        else {
                            if (context.state.currentActivity && context.state.currentActivity.activityID !== act.activityID && context.state.currentActivity.activityType !== 'PDF' && context.state.currentActivity.activityType !== 'URL' && context.state.currentActivity.activityType.toLowerCase() !== 'video' && context.state.currentActivity.activityType !== 'learning') {
                                evt.persist();
                                context.setState({
                                    activitySwitchWarningDialog: true,
                                    activitySwitchWarningData: evt,
                                    activitySwitchAct: act,
                                    activitySwitchUnitId: unitID
                                });
                            }
                            else {
                                for (var i = 1; i < 99999; i++) { window.clearInterval(i); }
                                this.setState({ timerSet: null });
                                this.setTimer();
                                context.loadActivity(act, unitID);


                                let menus = [];
                                if (evt.target.nodeName === 'A' || evt.target.nodeName === 'I') {
                                    menus = [...document.querySelectorAll('[id=' + evt.target.parentElement.parentElement.parentElement.parentElement.id + '] .fieldBox div.fieldBoxFullWidth')];
                                } else if (evt.target.nodeName === 'LABEL') {
                                    menus = [...document.querySelectorAll('[id=' + evt.target.parentElement.parentElement.parentElement.id + '] .fieldBox div.fieldBoxFullWidth')];
                                } else if (evt.target.id === 'activityTypeDisplay') {
                                    menus = [...document.querySelectorAll('[id=' + evt.target.parentElement.parentElement.parentElement.parentElement.id + '] .fieldBox div.fieldBoxFullWidth')];
                                } else {
                                    menus = [...document.querySelectorAll('[id=' + evt.target.parentElement.parentElement.id + '] .fieldBox div.fieldBoxFullWidth')];
                                }
                                for (var i = 0; i < menus.length; i++) {
                                    let menu = menus[i];
                                    menu.classList.remove('active');
                                }
                                if (evt.target.nodeName === 'A') {
                                    evt.target.parentElement.parentElement.classList.add('active');
                                } else if (evt.target.nodeName === 'LABEL') {
                                    evt.target.parentElement.classList.add('active');
                                } else if (evt.target.id === 'activityTypeDisplay') {
                                    evt.target.parentElement.parentElement.classList.add('active');
                                } else {
                                    evt.target.classList.add('active');
                                }
                            }
                        }
                    }

                }}>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-alignlft paddnone fieldBoxFullWidth">
                        <label htmlFor="subject2" className="pay_label css-label1 activityLeftPanel" data-toggle="tooltip" title={act.activityName}><a
                            href="javascript:void 0">{act.activityName}</a> </label>
                        { /* Resume button only if its a practice course */
                            context.isResumeable(act) &&
                            <span className="pauseButton"><MaterialIcon icon="play_circle_outline" size={24} /></span>
                        }
                        {  /* Count number */
                            context.isPracticeActivity(act) &&
                            <div className="maincolblusr" style={{ float: 'right' }}>{act.count | 0} {act.count === 1 ? 'Attempt' : 'Attempts'}</div>
                        }
                        {  /* Completed status */
                            context.isActivityComplete(act) && act.assessmentType && act.assessmentType === "assessment" &&
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-alignlft" style={{ float: 'right' }}>
                                <div className="maincolgreen"><img src={tick} /> Completed</div>
                            </div>
                        }

                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-alignlft paddnone">
                            <div id="activityTypeDisplay" className="activityTypeDisplay">{activityTypeDisplay}</div>
                        </div>
                    </div>

                </div>;
            });
        }
        return result;
    }

    unitHeadingToggle(evt) {
        console.log(evt, 'Unit heading toggle called.');
        let box = evt.target.nextElementSibling.firstElementChild;
        if (box.className.indexOf('in') > -1) {
            box.className = box.className.replace('in', '');
            box.style.height = '0px';
        } else {
            box.className += ' in';
            box.style.height = 'auto';
        }

    }

    getSidePanelUnitHeadings(units) {
        let context = this;
        let results = [];
        if (units) {
            let unitCount = 1;
            units.forEach(unit => {

                if (unit && unit.activities && unit.activities.length > 0) {
                    results.push(
                        <button type="button" className="collapsed" key={'trigger-unit-' + unit.unitID}
                            id={'trigger-unit-' + unit.unitID}
                            onClick={context.unitHeadingToggle}>{unit.unitName ? unit.unitName : 'Unit ' + unitCount}
                            <span className="caret caret-close" />
                        </button>
                    );
                    results.push(
                        <div className="SubjectBox" key={'box-unit-' + unit.unitID} id={'box-unit-' + unit.unitID}>
                            <div className="limitFilters collapse" id={'box-filter-unit-' + unit.unitID}
                                style={{ height: "0px" }}>
                                {/* Course Unit Activities */
                                    context.getSidePanelUnitHeadingActivities(unit.activities, unit.unitID)
                                }
                            </div>
                        </div>
                    );
                }
                unitCount++;
            });
        }
        return <div className="overFlowScroll-sm overflww" id={'all-units'}>{results}</div>;
    }

    expandCollapseSideBar(evt) {
        console.log(evt, 'Expand Collapse called.');
        let sidePanel = $("#sidePanel");
        let contentPanel = $("#contentPanel");
        sidePanel.toggleClass('leftBoxWidthMinimize');
        contentPanel.toggleClass('rightBoxWidthMinimize');
    }

    getCourseSidePanel(course) {
        let context = this;

        if (course) {

            let courseName = course.courseName;
            let dueDate = context.state.assignment ? context.state.assignment.dueDate : '';
            dueDate = new Date(parseFloat(dueDate));
            var day = dueDate.getDate();
            var month = dueDate.getMonth() + 1;
            var year = dueDate.getFullYear();
            let units = course.units ? course.units : [];
            let iconClass = "typeIcons";
            if (course.subject) {
                switch (course.subject.toLowerCase()) {
                    case 'algebra 1':
                    case 'algebra':
                        iconClass = 'typeIcons';
                        break;
                    case 'u.s. history and government':
                    case 'us history':
                    case 'u.s. history':
                        iconClass = 'typeIconsUSHistory';
                        break;
                    case 'global history and geography':
                        iconClass = 'typeIconshistory';
                        break;
                    case 'cc geometry':
                        iconClass = 'typeIconsgeometry';
                        break;
                    case 'earth science':
                        iconClass = 'typeIconsscience';
                        break;
                    case 'living environment':
                        iconClass = 'typeIconsenvironment';
                        break;
                    default:
                        break;
                }
            }
            return <div className="anchorLeftBox filterBlockss" id="sidePanel">
                <div className="ArrowDivCollapse "><span className="ArrowExpand resmain"
                    onClick={context.expandCollapseSideBar} /></div>

                {/* Course Heading and collapse arrow */}
                <div className="SubjectBox">
                    <div className="limitFilters">
                        <span className={iconClass} />
                        <span className="contentType ">
                            <h4>{courseName}</h4>
                        </span>
                    </div>
                </div>
                {/* Course Unit Headings */}
                {context.getSidePanelUnitHeadings(units)}

            </div>;
        }
    }

    getActivitySidePanel(act) {
        let context = this;
        if (act) {

            let courseName = context.state.assignment && context.state.assignment.assignedActivities && context.state.assignment.assignedActivities.activityName ? context.state.assignment.assignedActivities.activityName : '';
            let isPractice = context.state.assignment && context.state.assignment.assignedActivities && context.state.assignment.assignedActivities.assessmentType ? context.state.assignment.assignedActivities.assessmentType : '';
            let count = context.state.assignment && context.state.assignment.assignedActivities && context.state.assignment.assignedActivities.count ? context.state.assignment.assignedActivities.count : 0;
            let dueDate = context.state.assignment ? context.state.assignment.dueDate : '';
            dueDate = new Date(parseFloat(dueDate));
            var day = dueDate.getDate();
            var month = dueDate.getMonth() + 1;
            var year = dueDate.getFullYear();
            let iconClass = "typeIcons";
            switch (context.state.assignment.assignedActivities.subjects[0].toLowerCase()) {
                case 'algebra 1':
                case 'algebra':
                    iconClass = 'typeIcons';
                    break;
                case 'u.s. history and government':
                case 'us history':
                case 'u.s. history':
                    iconClass = 'typeIconsUSHistory';
                    break;
                case 'global history and geography':
                    iconClass = 'typeIconshistory';
                    break;
                case 'cc geometry':
                    iconClass = 'typeIconsgeometry';
                    break;
                case 'earth science':
                    iconClass = 'typeIconsscience';
                    break;
                case 'living environment':
                    iconClass = 'typeIconsenvironment';
                    break;
                default:
                    break;
            }

            let activityTypeDisplay = "";
            switch (context.state.assignment.assignedActivities.activityType.toLowerCase()) {
                case "learning":
                    if (act.documentType.toLowerCase() === 'url') {
                        activityTypeDisplay = "URL Activity"
                    } else if (act.documentType.toLowerCase() === 'pdf') {
                        activityTypeDisplay = "PDF Activity"
                    } else if (act.documentType.toLowerCase() === 'video') {
                        activityTypeDisplay = "Video Activity"
                    }
                    break;
                case "flashcards":
                    activityTypeDisplay = "Flashcard Activity"
                    break;
                case "question":
                    if (context.state.assignment.assignedActivities && context.state.assignment.assignedActivities.assessmentType && context.state.assignment.assignedActivities.assessmentType.toLowerCase() === "assessment") {
                        activityTypeDisplay = "Assessment Question Activity"
                    } else if (context.state.assignment.assignedActivities && context.state.assignment.assignedActivities.assessmentType && context.state.assignment.assignedActivities.assessmentType.toLowerCase() === "interactive") {
                        activityTypeDisplay = "Interactive Question Activity"
                    } else {
                        activityTypeDisplay = "Practice Question Activity"
                    }
                    break;
                default:

            }

            return <div className="anchorLeftBox filterBlockss" id="sidePanel">

                <div className="ArrowDivCollapse "><span className="ArrowExpand resmain"
                    onClick={context.expandCollapseSideBar} /></div>

                {/* Course Heading and collapse arrow */}

                <div className="SubjectBox">

                    <div className="limitFilters">
                        <span className={iconClass} />
                        <span className="contentType ">
                            <h4>{courseName}</h4>
                            <div className="activityTypeDisplay">{activityTypeDisplay}</div>
                        </span>
                    </div>
                </div>
            </div>;
        }
    }

    setTimer() {
        let context = this;
        // document.getElementById('timerSeconds').innerHTML = context.state.timerSeconds;
        // document.getElementById('timerMinutes').innerHTML = context.state.timerMinutes;
        // document.getElementById('timerHours').innerHTML = context.state.timerHours;
        setInterval(() => {
            if (context.state.timerSeconds === 59) {
                if (context.state.timerMinutes + 1 === 60) {
                    var sec = document.getElementById('timerSeconds');
                    var min = document.getElementById('timerMinutes');
                    var hr = document.getElementById('timerHours');
                    if (sec && min && hr) {
                        context.state.timerSeconds = 0;
                        context.state.timerMinutes = 0;
                        context.state.timerHours = ++context.state.timerHours;
                        // second, minute and hour.
                        sec.innerHTML = context.state.timerSeconds.toString().length === 1 ? '0' + context.state.timerSeconds : context.state.timerSeconds;
                        min.innerHTML = context.state.timerMinutes.toString().length === 1 ? '0' + context.state.timerMinutes : context.state.timerMinutes;
                        hr.innerHTML = context.state.timerHours.toString().length === 1 ? '0' + context.state.timerHours : context.state.timerHours;
                    }
                } else {
                    var sec = document.getElementById('timerSeconds');
                    var min = document.getElementById('timerMinutes');
                    if (sec && min) {
                        context.state.timerSeconds = 0;
                        context.state.timerMinutes = ++context.state.timerMinutes;
                        sec.innerHTML = context.state.timerSeconds.toString().length === 1 ? '0' + context.state.timerSeconds : context.state.timerSeconds;
                        min.innerHTML = context.state.timerMinutes.toString().length === 1 ? '0' + context.state.timerMinutes : context.state.timerMinutes;
                    }
                }
            } else {
                var sec = document.getElementById('timerSeconds');
                if (sec) {
                    context.state.timerSeconds = ++context.state.timerSeconds;
                    sec.innerHTML = context.state.timerSeconds.toString().length === 1 ? '0' + context.state.timerSeconds : context.state.timerSeconds;
                }
            }
        }, 1000);
    }

    getTimerValue() {
        var sec = document.getElementById('timerSeconds');
        var min = document.getElementById('timerMinutes');
        var hr = document.getElementById('timerHours');
        return ((parseInt(hr.innerHTML) * 60 * 60) + (parseInt(min.innerHTML) * 60) + (parseInt(sec.innerHTML)));
    }

    questionChoiceSelected(evt) {
        console.log(evt, 'Choice selected.');
    }

    generateQuestionView(question, answerObj) {
        if (question.questionType.toLowerCase() === 'essay') {
            let defaultVal = answerObj && answerObj.studentAnswer && answerObj.studentAnswer.trim() !== 'empty-answer' ? answerObj.studentAnswer : '';
            question.question = question.question.replace('[[[LP_ANSWERS]]]', '');
            return <div className="row" key={question.questionID} id="mainQuestionView">
                <div className="studentViewQuestionContainer">
                    <div className="col-md-4 col-sm-12 col-xs-7  hidden-md hidden-sm hidden-lg hidden-xs">
                        <div className="fltrghtress  ">
                            <a href="javascript:void 0" className="printres fltrghtress " onClick={this.print}> Print</a>
                            <a href="javascript:void 0" className="submitres fltrghtress" onClick={this.submit}> Submit</a>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <p>{htmlParser(question.question)}</p>
                    </div>
                    <div className="col-md-12">
                        <textarea placeholder="Enter your answer here." className="answerTextArea" id="answerTextArea"
                            defaultValue={defaultVal} />
                    </div>
                    <div className="row" />
                </div>
            </div>;

        } else {

            let choices = [];
            var defaultVal = answerObj ? answerObj.studentAnswer : '';
            if (defaultVal) {
                // let end = defaultVal.indexOf('/react');
                // defaultVal = defaultVal.substring(end+15);
                defaultVal = defaultVal.replace(/\"/g, "");
                defaultVal = defaultVal.replace(/ /g, "");
            }
            let choicesString = '<div className="col-md-12 choicesText">';

            for (let i = 0; i < question.choices.length; i++) {
                let choice = question.choices[i];
                let isChecked = defaultVal === choice.replace(/ /g, "");
                choices.push(<div className="fieldBox" key={choice + '-' + i}>
                    <input type="radio" className="css-checkbox css-label1 bc_info card_side" value={choice}
                        id={"choice-" + i} name="choice" defaultChecked={isChecked} />
                    <label className="css-label radGroup1" htmlFor={"choice-" + i}
                        onClick={this.questionChoiceSelected}>{choice}</label>
                </div>);

                // string experiment.
                if (false && isChecked) {
                    choicesString += '<div className="fieldBox" key="choice-' + i + '"><span style="vertical-align: top">' + (i + 1) + ') &nbsp;&nbsp; </span><input type="radio" className="css-checkbox css-label1 bc_info card_side"  id="choice-' + i + '" name="choice" checked /><label className="css-label radGroup1"  style="width:75%" for="choice-' + i + '">' + choice + '</label></div>';
                } else {
                    choicesString += '<div className="fieldBox" key="choice-' + i + '"><span style="vertical-align: top">' + (i + 1) + ') &nbsp;&nbsp; </span><input type="radio" className="css-checkbox css-label1 bc_info card_side"  id="choice-' + i + '" name="choice" /><label className="css-label radGroup1" style="width:75%" for="choice-' + i + '">' + choice + '</label></div>';
                }
            }

            choicesString += '</div>';

            let showChoices = question.question.indexOf('[[[LP_ANSWERS]]]') > -1;
            let questionToDisplay = question.question;
            if (showChoices) {
                questionToDisplay = questionToDisplay.replace('[[[LP_ANSWERS]]]', choicesString);
            }

            return <div className="row" key={question.questionID} id="mainQuestionView">
                <div className="studentViewQuestionContainer">
                    <div className="col-md-4 col-sm-12 col-xs-7  hidden-md hidden-sm hidden-lg ">

                        <div className="fltrghtress  ">
                            <a href="javascript:void 0" className="printres fltrghtress " onClick={this.print}> Print</a>
                            <a href="javascript:void 0" className="submitres fltrghtress" onClick={this.submit}> Submit</a>
                        </div>

                    </div>

                    <div className="col-md-12">
                        <p>{htmlParser(questionToDisplay)}</p>
                    </div>
                    {!showChoices && <div className="col-md-12">
                        {htmlParser(choicesString)}
                    </div>}
                    <div className="row" />
                </div>
            </div>;
        }
    }

    componentDidUpdate() {
        if (this.state.contentView === 'question') {
            var radios = document.querySelectorAll('input[type=radio]');
            var currentQuestion = this.state.unansweredQuestions[this.state.currentQuestionIndex];
            var questionKey = this.state.currentActivity.activityID + "-" + currentQuestion.questionID;
            var result = this.state.questionActivityResults.get(questionKey);
            var checked = document.querySelectorAll('input[type=radio]:checked');
            if (result && checked.length === 0) {
                radios.forEach(rad => {
                    if (rad.nextElementSibling.firstElementChild.innerHTML && result.studentAnswer && (decodeURIComponent(rad.nextElementSibling.firstElementChild.innerHTML.replace(/\"/g, "").replace(/ /g, "").trim()) === decodeURIComponent(result.studentAnswer.replace(/\"/g, "").replace(/ /g, "").trim()))) {
                        rad.checked = true;
                    }

                });
            }
        }
    }


    generateFlashcardView(flashcard, answerObj) {
        let currentActivity = this.state.currentActivity ? this.state.currentActivity : null;

        return <div className="">
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3" style={{ float: 'right' }}>
                <div className="row">
                    <div className="brdgr">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-alignlft " style={{ padding: '0px' }}>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <span className="timerHeading">Elapsed Time:</span><br />
                                <span id="timer" style={{ color: '#0f6fb4' }}>&nbsp;&nbsp;
                                    <span id="timerHours">00</span>:<span id="timerMinutes">00</span>:<span id="timerSeconds">00</span></span>
                                <br /><br />
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-alignlft marginall">
                                <h3 className="ccard">Current Card: </h3>
                                <span
                                    className="bluetxta text-alignlft">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {this.state.currentFlashcardIndex + 1} / {currentActivity.words.length}</span><br />
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-alignlft marginall">
                                <h3 className="sscore">Score:</h3>
                                <span
                                    className="bluetxta text-alignlft">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {this.state.flashcardRight} / {currentActivity.words.length}</span>
                            </div>
                        </div>
                        {this.state.viewDefinition && <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-alignlft marginall"
                            style={{ padding: '0px' }}>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-alignlft  marginall">
                                <a className="greenbtn" href="javascript:void 0" onClick={function () {
                                    if ((this.state.currentFlashcardIndex + 1) === this.state.unansweredFlashcards.length) {
                                        this.end(true);
                                    } else {
                                        this.next(true);
                                    }
                                    // this.next(true);
                                    this.setState({ viewDefinition: false });
                                    $('#flip-container').removeClass('flip');
                                    if ((this.state.currentFlashcardIndex + 1) === this.state.unansweredFlashcards.length) {
                                        $('#i_was_right').removeClass('active');
                                        $('#i_was_wrong').removeClass('active');
                                        $('#i_was_right').addClass('active');
                                    }
                                }.bind(this)
                                } id="i_was_right">Next (I was right)</a>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-alignlft  marginall">
                                <a className="orngbtn" href="javascript:void 0" onClick={function () {
                                    if ((this.state.currentFlashcardIndex + 1) === this.state.unansweredFlashcards.length) {
                                        this.end(true);
                                    } else {
                                        this.next(false);
                                    }
                                    // this.next(false);
                                    this.setState({ viewDefinition: false });
                                    $('#flip-container').removeClass('flip');
                                    if ((this.state.currentFlashcardIndex + 1) === this.state.unansweredFlashcards.length) {
                                        $('#i_was_wrong').removeClass('active');
                                        $('#i_was_right').removeClass('active');
                                        $('#i_was_wrong').addClass('active');
                                    }
                                }.bind(this)
                                } id="i_was_wrong">Next (I was wrong)</a>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 flip-container" id="flip-container">
                <div className="flipper">
                    <div className="brdrnam front" style={{ padding: '0px' }}>
                        <div className="eleRows">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-8 text-center">
                                <span className="headblue">Word {this.state.currentFlashcardIndex + 1}</span>
                            </div>
                            <div className="">
                                <button className="addActivityBtn btnnotm flipbtntxt" onClick={() => {
                                    this.setState({ viewDefinition: true });
                                    $('#flip-container').toggleClass('flip');
                                }} />
                            </div>
                        </div>
                        <p className="cntrfonbig"><div className="flashcard-word">{flashcard.translatedWord ? flashcard.translatedWord : flashcard.word}</div></p>
                    </div>

                    <div className="brdrnam back" style={{ padding: '0px' }}>
                        <div className="eleRows">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-8 text-center">
                                <span className="headblue">{flashcard.translatedWord ? flashcard.translatedWord : flashcard.word}</span>
                            </div>
                            <div className="">
                                <button className="addActivityBtn btnnotm flipbtntxt" onClick={() => {
                                    this.setState({ viewDefinition: true });
                                    $('#flip-container').toggleClass('flip');
                                }} />
                            </div>
                        </div>
                        <p className="cntrfonbig"><div className="fdef">{flashcard.wordDefinition}</div></p>
                    </div>
                </div>
            </div>

        </div>;
    }

    generateLearningView(flashcard) {
        let currentActivity = this.state.currentActivity ? this.state.currentActivity : null;
        if (currentActivity.documentType.toLowerCase() === 'url') {
            return <div className="">
                <div className="eleRow hidden-xs" style={{ paddingTop: '0px' }}>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-alignlft">
                        <h3 style={{ marginTop: '5px' }}>Learning </h3>
                    </div>
                </div>
                <div className="container-fluid questionMainBlock">
                    <div className="quesAndIcon">
                        <div className="row">
                            <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 text-alignlft">
                                <a href={currentActivity.documentLink} target="_blank">
                                    <span className="imgurl"><img src={url} /></span>
                                    <span className="bluetxta text-alignlft">{currentActivity.activityName}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>;
        } else {
            return <div className="">
                <div className="eleRow hidden-xs" style={{ paddingTop: '0px' }}>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-alignlft">
                        <h3 style={{ marginTop: '5px' }}>Learning </h3>
                    </div>
                </div>
                <div className="container-fluid questionMainBlock">
                    <div className="quesAndIcon">
                        <div className="row">
                            <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 text-alignlft">
                                <a href={currentActivity.documentLink} target="_blank">
                                    <span className="imgurl"><img src={pdf} /></span>
                                    <span className="bluetxta text-alignlft">{currentActivity.fileName}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>;
        }
    }

    getMainContent(contentView, obj, answerObj) {

        if (this.state.currentActivity) {
            if (contentView === 'question') {
                return (
                    this.generateQuestionView(obj, answerObj)
                )
            } else if (contentView === 'flashcard') {
                return (
                    this.generateFlashcardView(obj, answerObj)
                )
            } else {
                // learning
                return (
                    this.generateLearningView(obj)
                )
            }
        } else {
            return <div className="row" key={'no_activity_selected'} id="mainQuestionView">
                <div id="no_activity_selected">
                    <p>
                        Please select an Activity from side bar.
                    </p>
                </div>
            </div>;
        }
    }

    handleAlertDismiss(e) {
        this.setState({ 'showAlert': false });
    }

    getMainContentTopHeading() {
        // set heading, timer and breadcrumbs. for type questions or flashcard or learning.

        let currentActivity = this.state.currentActivity ? this.state.currentActivity : null;
        let currentUnit = this.state.currentUnit ? this.state.currentUnit : null;
        let isPractice = !(currentActivity && currentActivity.assessmentType && currentActivity.assessmentType.toLowerCase() === 'assessment');
        let course = this.state.assignment && this.state.assignment.assignedCourses ? this.state.assignment.assignedCourses : null;

        return currentActivity && this.state.currentActivityComplete === false ? <div className="eleRow hidden-xs">

            <div className="ccol-lg-4 col-md-4 col-sm-4 col-xs-6 text-left">
                <h3>{currentActivity.activityName} </h3>
            </div>

            {this.state.contentView === 'question' && <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 text-center">
                <h3>{"Current Question: "}
                    <span style={{ color: '#0f6fb4' }}>{this.state.currentQuestionIndex + 1} / {currentActivity.questions.length}</span>
                </h3>
            </div>}

            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <h3 className="quesNum">
                    <span className="timerHeading">Elapsed Time:</span>
                    <span id="timer" style={{ color: '#0f6fb4' }}>&nbsp;&nbsp;
                        <span id="timerHours">00</span>:<span id="timerMinutes">00</span>:<span id="timerSeconds">00</span></span>
                </h3>
            </div>

        </div> : null;
    }

    getCheckAnswerDialog() {

        let currentQuestion = this.state.unansweredQuestions ? this.state.unansweredQuestions[this.state.currentQuestionIndex] : {};
        let checked = [...document.querySelectorAll('#mainQuestionView input:checked')];
        let isCorrect = false;
        if (currentQuestion && currentQuestion.questionType && currentQuestion.questionType.toLowerCase() !== 'essay') {
            if (checked && checked.length > 0) {
                let studentAnswer = checked[0].nextElementSibling.firstElementChild.innerHTML;
                if (currentQuestion.answer) {
                    isCorrect = currentQuestion.answer.replace(/\"/g, "").replace(/ /g, "").replace(/<!--.*?-->/g, "").toLowerCase() === studentAnswer.replace(/\"/g, "").replace(/ /g, "").replace(/<!--.*?-->/g, "").toLowerCase();
                }
            }
        }
        
        let body = <div>
            <p className="cntrfon">
                {htmlParser(currentQuestion.explanation)}
            </p>
        </div>;


        let width = window.innerWidth / 2;
        let height = window.innerHeight / 2;
        let left = width * -1;

        let handleClassName = "handle explanation_box";
        const { screenWidth } = this.state;
        const isTab = screenWidth <= 1199;
        if (isTab) {
            handleClassName = "explanation_box"
        }

        return (<Draggable handle=".handle" bounds={{ top: -50, left: left, right: width, bottom: height }} defaultPosition={{ x: 0, y: -62 }}>
            <div className={handleClassName}>
                <div>


                    <Button onClick={() => {
                        let that = this;
                        document.getElementById('drop_shadow_check_ans').style.display = 'none';
                        document.querySelector('.container-fluid.noPad.listMainBoxWrapper').style.overflow = 'auto';
                        that.setState({ showNextDialog: false, rating: 0 });
                        if (that.state.currentActivity && that.state.currentActivity.questions.length - that.state.currentQuestionIndex === 1) {
                            setTimeout(function () {
                                that.setState({ showNextDialog: false });
                            }, 200);
                            that.end(true);
                        } else {
                            that.next();
                        }
                    }} bsStyle='primary' id="cross-button">X</Button>






                    {!isCorrect && <span className="eleRowspoporng" style={{ paddingLeft: '20px', paddingTop: '25px' }}>Incorrect Answer <br /> <span className="eleRowspopcrt" title={currentQuestion.answer}>Correct Answer is: {htmlParser(currentQuestion.answer)}</span></span>}
                    {isCorrect && <span className="eleRowspop" style={{ paddingLeft: '20px', paddingTop: '25px' }}>Correct Answer!</span>}
                </div>
                <div className="body">
                    {body}
                </div>
                <div className="text-left footer-fix" style={{
                    padding: '15px',
                    height: '60px',
                    marginLeft: '20px',
                    position: 'relative',
                    marginTop: '-30px'
                }} id="checkAnsFooter">How would you rate this explanation?
                    <Rater style={{ marginLeft: "20px" }} rating={this.state.currentRating} onRate={this.onRate} />
                    <Button onClick={() => {
                        let that = this;
                        document.getElementById('drop_shadow_check_ans').style.display = 'none';
                        document.querySelector('.container-fluid.noPad.listMainBoxWrapper').style.overflow = 'auto';
                        that.setState({ showNextDialog: false, rating: 0 });
                        if (that.state.currentActivity.questions.length - that.state.currentQuestionIndex === 1) {
                            setTimeout(function () {
                                that.setState({ showNextDialog: false });
                            }, 200);
                            that.end(true);
                        } else {
                            that.next();
                        }
                    }} bsStyle='primary'>Next Question</Button>
                </div>
            </div>
        </Draggable>);


        // let currentQuestion = this.state.unansweredQuestions ? this.state.unansweredQuestions[this.state.currentQuestionIndex] : {};
        // let checked = [...document.querySelectorAll('#mainQuestionView input:checked')];
        // let isCorrect = false;
        // if (currentQuestion && currentQuestion.questionType && currentQuestion.questionType.toLowerCase() !== 'essay') {
        //     if (checked && checked.length > 0) {
        //         let studentAnswer = checked[0].nextElementSibling.firstElementChild.innerHTML;
        //         if (currentQuestion.answer) {
        //             isCorrect = currentQuestion.answer.replace(/\"/g, "").replace(/ /g, "").toLowerCase() === studentAnswer.replace(/\"/g, "").replace(/ /g, "").toLowerCase();
        //         }
        //     }
        // }
        // 
        // let body = <div>
        //     <p className="cntrfon">
        //         {htmlParser(currentQuestion.explanation)}
        //     </p>
        // </div>;

        //
        // return <Modal id="check_answer_modal"
        //               backdrop="static"
        //               show={this.state.showNextDialog}
        //               onHide={()=> {
        //                   let that = this;
        //                   if (that.state.currentActivity.questions.length - that.state.currentQuestionIndex === 1) {
        //                     setTimeout(function () {
        //                         that.setState({showNextDialog: false});
        //                     }, 200);
        //                      that.end(true);
        //                   } else {
        //                       that.setState({showNextDialog: false});
        //                       that.next();
        //                   }
        //                   this.setState({currentRating:0});
        //               }}
        //               container={this}
        //               aria-labelledby="contained-modal-title">
        //     <Modal.Header closeButton>
        //         {!isCorrect && <span className="eleRowspoporng" >Incorrect Answer - <span className="eleRowspop"  style={{display: 'inline'}}>Correct Answer is: {htmlParser(currentQuestion.answer)}</span></span>}
        //         {isCorrect && <span className="eleRowspop"  >Correct Answer!</span>}
        //     </Modal.Header>
        //     <Modal.Body>
        //         {body}
        //     </Modal.Body>
        //     <Modal.Footer>
        //         <div className="col-md-8 text-left">How would you rate this explanation?
        //             <Rater rating={this.state.currentRating} onRate={this.onRate} />
        //         </div>
        //         <div className="col-md-4 text-right">
        //             <Button onClick={()=> {
        //                 let that = this;
        //                 that.setState({showNextDialog: false, rating:0});
        //                 if (that.state.currentActivity.questions.length - that.state.currentQuestionIndex === 1) {
        //                     setTimeout(function () {
        //                         that.setState({showNextDialog: false});
        //                     }, 200);
        //                    that.end(true);
        //                 } else {
        //                    that.next();
        //                 }
        //             }} bsStyle='primary'>Next Question</Button>
        //         </div>
        //     </Modal.Footer>
        // </Modal>;
    }

    onRate(rate) {
        if (rate.type === 'click') {
            let body = {
                studentID: this.props.auth.user.userName,
                questionID: this.state.currentActivity.questions[this.state.currentQuestionIndex].questionID,
                rating: rate.rating.toString()
            };
            this.setState({ currentRating: rate.rating });
            apiClient.invokeApi({}, '/question/rating', 'PUT', {}, body, '3').then(function (result) {
            });
        }
    }

    getHintDialog() {

        let currentQuestion = this.state.unansweredQuestions ? this.state.unansweredQuestions[this.state.currentQuestionIndex] : {};
        let body = <div>
            <div className="infoIcon">
                <img src={hint} style={{ height: '80px' }} />
            </div>
            <div style={{ display: 'inline-block', width: '80%' }}>
                {htmlParser(currentQuestion.hint)}
            </div>
        </div>;

        return <Modal id="hint_modal"
            show={this.state.showHintDialog}
            onHide={() => {
                this.setState({ showHintDialog: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Question Hint</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showHintDialog: false });
                }} bsStyle='primary'>OK</Button>
            </Modal.Footer>
        </Modal>;
    }

    getNoAnswerSelectionWarningDialog() {

        let body = <div>
            <p>You have not submitted an answer for this question. If you choose to continue without submitting an answer, the question will be marked as incorrect. If you would like to answer this question, click the ""Cancel"" button below and submit an answer</p>
        </div>;
        let currentQuestion = this.state.unansweredQuestions[this.state.currentQuestionIndex];

        return <Modal id="hint_modal"
            backdrop="static"
            show={this.state.showNoAnswerSelectionWarningDialog}
            onHide={() => {
                this.setState({ showNoAnswerSelectionWarningDialog: false });
                if (currentQuestion.questionType.toLowerCase() !== 'essay' && this.state.isPractice) {
                    document.querySelector('.container-fluid.noPad.listMainBoxWrapper').style.overflow = 'hidden';
                    this.setState({ showNextDialog: true });
                } else {
                    this.next();
                }
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header>
                <Modal.Title id="delete-activity-modal">You have not submitted an answer for this question</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showNoAnswerSelectionWarningDialog: false });
                }} bsStyle='default'>Cancel</Button>
                <Button onClick={() => {
                    this.setState({ showNoAnswerSelectionWarningDialog: false });
                    if (currentQuestion.questionType.toLowerCase() !== 'essay' && this.state.isPractice) {
                        document.getElementById('drop_shadow_check_ans').style.display = 'block';
                        document.querySelector('.container-fluid.noPad.listMainBoxWrapper').style.overflow = 'hidden';
                        this.setState({ showNextDialog: true });
                    } else {
                        this.next();
                    }
                }} bsStyle='primary'>Ok</Button>
            </Modal.Footer>
        </Modal>;
    }

    getAssessmentCannotBeDoneAgainDialog() {

        return <Modal id="hint_modal"
            backdrop="static"
            show={this.state.assessmentCannotBeDoneAgain}
            onHide={() => {
                this.setState({ assessmentCannotBeDoneAgain: false });
                if (this.state.assessmentRedirect) {
                    browserHistory.push('/student/assignments');
                }
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Sorry, assessment assignments can only be taken once</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ assessmentCannotBeDoneAgain: false });
                    if (this.state.assessmentRedirect) {
                        browserHistory.push('/student/assignments');
                    }
                }} bsStyle='primary'>OK</Button>
            </Modal.Footer>
        </Modal>;
    }

    checkForSkippedAnswers() {
        let assignment = this.state.assignment;
        let currentActivity = this.state.currentActivity;
        let unanswered = false;

        if (this.state.contentView === 'question') {
            let answerCount = 0;

            // preserve current question details.
            let questionActivityResults = this.state.questionActivityResults;

            if (this.state.unansweredQuestions.length - questionActivityResults.size > 1) {
                unanswered = true;
            } else {

                questionActivityResults.forEach(item => {
                    if (!item.studentAnswer || item.studentAnswer === 'empty-answer') {
                        unanswered = true;
                    }
                });
                // for (let i=0; i<questionActivityResults.size; i++) {
                //     if (!questionActivityResults[i].value.studentAnswer || questionActivityResults[i].value.studentAnswer === 'empty-answer') {
                //           unanswered=true;
                //     }
                // }
                // same stuff stays here..
                let checked = [...document.querySelectorAll('#mainQuestionView input:checked')];
                let currentQuestion = this.state.unansweredQuestions[this.state.currentQuestionIndex];
                let questionKey = currentActivity.activityID + "-" + currentQuestion.questionID;


                if (currentQuestion.questionType.toLowerCase() === 'essay') {
                    this.setState({ essayActivity: true });
                    let value = document.getElementById("answerTextArea").value;
                    if (!value) {
                        unanswered = true;
                    }
                } else {
                    if (checked && checked.length > 0) {
                    } else {
                        unanswered = true;
                    }
                }
            }

        }

        if (!unanswered) {
            this.end(true);
        } else {
            this.setState({ showNoAnswerAssessment: true });
        }

    }

    getNoAnswerAssessmentDialog() {

        let body = <div>
            <p>You have chosen to skip questions which will be graded as incorrect. Do you want to go back and answer the questions that you skipped?</p>
        </div>;

        return <Modal id="hint_modal"
            backdrop="static"
            show={this.state.showNoAnswerAssessment}
            onHide={() => {
                this.setState({ showNoAnswerAssessment: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Skipped Questions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showNoAnswerAssessment: false });
                }} bsStyle='default'>View Skipped Questions</Button>
                <Button onClick={() => {
                    let that = this;
                    setTimeout(function () {
                        that.setState({ showNoAnswerAssessment: false });
                    }, 1000);
                    this.end(true);
                }} bsStyle='primary'>Submit & End</Button>
            </Modal.Footer>
        </Modal>;
    }

    getPauseDialog() {

        return <Modal id="hint_modal"
            backdrop="static"
            show={this.state.showPauseDialog}
            onHide={() => {
                this.setState({ showPauseDialog: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header >
                <Modal.Title id="delete-activity-modal">Activity Paused</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                You can resume this activity from assignments at a later time
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    let that = this;
                    that.setState({ showPauseDialog: false }, function () {
                        that.submit(true);
                    });
                }} bsStyle='primary'>OK</Button>
            </Modal.Footer>
        </Modal>;
    }

    getActivitySwitchWarningDialog() {

        let body = <div>
            <div className="infoIcon">
                <img src={warning} style={{ height: '80px' }} />
            </div>
            <div style={{ display: 'inline-block' }}>
                Switching to another activity, You may lose all unsaved progress.
            </div>
        </div>;

        return <Modal id="activity_warning_modal"
            backdrop="static"
            show={this.state.activitySwitchWarningDialog}
            onHide={() => {
                this.setState({ activitySwitchWarningDialog: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Warning!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ activitySwitchWarningDialog: false });
                }}>Cancel</Button>
                <Button onClick={() => {
                    this.switchActivity(this.state.activitySwitchWarningData, this.state.activitySwitchAct, this.state.activitySwitchUnitId);
                    this.setState({ activitySwitchWarningDialog: false, activitySwitchWarningData: null });
                    for (var i = 1; i < 99999; i++) { window.clearInterval(i); }
                    this.setState({ timerSet: null });
                    this.setState({ timerSeconds: 0, timerMinutes: 0, timerHours: 0, timerSet: true }, this.setTimer);
                }} bsStyle='primary'>Continue</Button>
            </Modal.Footer>
        </Modal>;
    }

    getTestReportDialog() {

        let studentAnswers = this.state.questionActivityResults;
        let currentAct = this.state.currentActivity ? this.state.currentActivity : {};
        let score = 0;
        let categoryHeadings = new Set();
        studentAnswers.forEach(ans => {
            for (var i = 0; i < currentAct.questions.length; i++) {
                if (currentAct.questions[i].questionID === ans.questionID && htmlParser(currentAct.questions[i].answer) === htmlParser(ans.studentAnswer)) {
                    score++;
                }
                else if (ans.studentAnswer && currentAct.questions[i].questionID === ans.questionID && currentAct.questions[i].answer.replace(/\"/g, "").replace(/ /g, "").toLowerCase() === ans.studentAnswer.replace(/\"/g, "").replace(/ /g, "").toLowerCase()) {
                    score++;
                }
                categoryHeadings.add({
                    category: currentAct.questions[i].category,
                    subcategory: currentAct.questions[i].subcategory
                });
            }
        });
        let scorePercent = null;
        let length = 0;
        if (currentAct.questions) {
            scorePercent = (score / currentAct.questions.length) * 100;
            length = currentAct.questions.length;
        }
        let headings = Array.from(categoryHeadings);
        let reportEntries = [];
        let wrongQuestions = [];
        headings.forEach(heading => {
            let headingData = { questions: [], score: 0 };
            for (let j = 0; j < currentAct.questions.length; j++) {
                let catQ = currentAct.questions[j];
                if (catQ.category === heading.category && catQ.subcategory === heading.subcategory) {
                    headingData.questions.push(catQ);
                    let isCorrect = false;
                    studentAnswers.forEach(ans => {
                        if (htmlParser(ans.studentAnswer) === htmlParser(catQ.answer)) {
                            isCorrect = true;
                        }
                    });
                    if (isCorrect) {
                        headingData.score += 1;
                    } else {
                        wrongQuestions.push(catQ);
                    }
                }
            }
            headingData.title = heading.category + ' - ' + heading.subcategory;
            headingData.totalQuestionPercentage = (headingData.questions.length / currentAct.questions.length) * 100;
            headingData.correctQuestionPercentage = (headingData.score / headingData.questions.length) * 100;
            headingData.correctQuestionScore = headingData.score;
            headingData.correctQuestionLength = headingData.questions.length;
            reportEntries.push(headingData);
        });

        let reportLines = reportEntries.map(line => {
            let correctQuestionPercentage = line.correctQuestionPercentage ? line.correctQuestionPercentage.toFixed(0) : 0;
            return <tr key={line.title}>
                <td>{line.title}:</td>
                <td>
                    {line.correctQuestionScore + ' out of ' + line.correctQuestionLength + ' questions correct. (' + correctQuestionPercentage + '%)'}
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12   paddnone barchrtlghtbl"
                        style={{ marginRight: '10px', marginTop: '5px', background: 'white' }}>
                        <div className="filled" style={{ width: (line.correctQuestionPercentage ? line.correctQuestionPercentage : 0) + '%', background: (line.correctQuestionPercentage && line.correctQuestionPercentage > 64 ? 'green' : 'red') }}></div>
                    </div>
                </td>
            </tr>;
        });

        let wrongWithStudentAns = [];
        wrongQuestions.forEach(wrongQ => {

            if (studentAnswers.has(currentAct.activityID + '-' + wrongQ.questionID)) {
                wrongQ.studentAnswer = studentAnswers.get(currentAct.activityID + '-' + wrongQ.questionID).studentAnswer;
            }
            wrongWithStudentAns.push(wrongQ);
        });

        let rowCount = 0;
        let wrongLines = wrongWithStudentAns.map(question => {
            let explanation = question.explanation;
            // replace auto play.
            if (explanation) {
                explanation = explanation.replace('autoplay', '');
            }

            rowCount++;
            return <tr className="" key={'wrong' + '-' + question.questionID}>
                <td style={{ border: '1px solid #555555' }}>
                    <p className="QuestionNoBorder">
                        <strong style={{ float: 'left' }}>{rowCount + '. '} </strong>{htmlParser('<span>' + question.question.replace('[[[LP_ANSWERS]]]', '') + '</span>')}
                    </p>
                    <br />
                    <div className="std-q-heading">Correct Answer</div>
                    <p>
                        {htmlParser('<span>' + question.answer + '</span>')}
                    </p>
                    <div className="std-q-heading">Your Response</div>
                    <p>
                        {htmlParser('<span>' + question.studentAnswer + '</span>')}
                    </p>
                    <div className="std-q-heading">Explanation</div>
                    <p>
                        {htmlParser('<span>' + explanation + '</span>')}
                    </p>
                </td>
            </tr>;
        });

        let studentStats = <table className="table" id="student-stat">
            <thead>
                <tr>
                    <th style={{ background: '#f7f8f9', border: '1px solid #555555' }}>
                        Questions you got wrong.
                </th>
                </tr>
            </thead>
            <tbody>
                {wrongLines}
            </tbody>
        </table>;


        let activityStats = <table className="table" id="act-stats-user">
            <tbody>
                <tr>
                    <td><MaterialIcon icon="accessibility" size={24} /> Student Name:</td>
                    <td>{this.props.auth.user.firstName + " " + this.props.auth.user.lastName}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="assignment" size={24} /> Activity Name:</td>
                    <td>{currentAct.activityName}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="assessment" size={24} /> Score:</td>
                    <td>{scorePercent ? scorePercent.toFixed(0) : 0}%</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="check" size={24} /> Number Correct:</td>
                    <td>{score} of {length}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="timer" size={24} /> Time In:</td>
                    <td>
                        {parseHours(this.getTimerValue())}{' hours '}
                        {parseMinutes(this.getTimerValue())}{' mins '}
                        {parseSeconds(this.getTimerValue())}{' seconds'}
                    </td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="date_range" size={24} /> Completion Date:</td>
                    <td>{new Date().toLocaleString()}</td>
                </tr>
            </tbody>
        </table>;

        let catStats = <table className="table" id="act-cat-stats-user">
            <tbody>
                {reportLines}
            </tbody>
        </table>;


        let table = <div id="user-activity-analysis" className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <p style={{ overflow: 'auto' }}>
                    {activityStats}
                </p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading"  >Category Analysis </h1>
                <p style={{ overflow: 'auto' }}>
                    {catStats}
                </p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading"  >Student Question Analysis </h1>
                <p style={{ overflow: 'auto' }}>
                    {studentStats}
                </p>
            </div>
        </div>;

        return <Modal id="test_report_modal"
            backdrop="static"
            show={this.state.showTestReportDialog}
            onHide={() => {
                let questionActivityResults = new Map();
                let flashcardActivityResults = new Map();
                this.setState({ showTestReportDialog: false, showNextDialog: false, showNoAnswerAssessment: false });
                if (this.state.view !== 'course') {
                    browserHistory.push('/student/assignments');
                } else {
                    // set view to select activity.
                    this.setState({ currentActivityComplete: true, questionActivityResults: questionActivityResults, flashcardActivityResults: flashcardActivityResults });
                }
                for (var i = 1; i < 99999; i++) { window.clearInterval(i); }
                this.setState({ timerSet: null });
                //clearInterval(this.state.timerID);
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">{
                    <span className="eleRowspopbl paddnone margnnone ">Results for <span
                        className="eleRowspop paddnone margnnone">{currentAct.activityName}</span></span>
                }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {table}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    let questionActivityResults = new Map();
                    let flashcardActivityResults = new Map();
                    this.setState({ showTestReportDialog: false, showNextDialog: false, showNoAnswerAssessment: false });
                    if (this.state.view !== 'course') {
                        browserHistory.push('/student/assignments');
                    } else {
                        // set view to select activity.
                        this.setState({ currentActivityComplete: true, questionActivityResults: questionActivityResults, flashcardActivityResults: flashcardActivityResults });
                    }
                    for (var i = 1; i < 99999; i++) { window.clearInterval(i); }
                    this.setState({ timerSet: null });
                    //  clearInterval(this.state.timerID);
                }} bsStyle='primary'>OK</Button>
            </Modal.Footer>
        </Modal>;
        // if (this.state.isPractice) {
        //     // calculate score.
        //     let score = 0;
        //     let categoryHeadings = new Set();
        //     studentAnswers.forEach(ans => {
        //         for (var i = 0; i < currentAct.questions.length; i++) {
        //             if (currentAct.questions[i].questionID === ans.questionID && currentAct.questions[i].answer === ans.studentAnswer) {
        //                 score++;
        //             }
        //             categoryHeadings.add({
        //                 category: currentAct.questions[i].category,
        //                 subcategory: currentAct.questions[i].subcategory
        //             });
        //         }
        //     });
        //     let scorePercent = null;
        //     let length = 0;
        //     if (currentAct.questions) {
        //         scorePercent = (score / currentAct.questions.length) * 100;
        //         length = currentAct.questions.length;
        //     }
        //     let headings = Array.from(categoryHeadings);
        //     let reportEntries = [];
        //     let wrongQuestions = [];
        //     headings.forEach(heading => {
        //         let headingData = {questions: [], score: 0};
        //         for (let j = 0; j < currentAct.questions.length; j++) {
        //             let catQ = currentAct.questions[j];
        //             if (catQ.category === heading.category && catQ.subcategory === heading.subcategory) {
        //                 headingData.questions.push(catQ);
        //                 let isCorrect = false;
        //                 studentAnswers.forEach(ans => {
        //                     if (ans.studentAnswer === catQ.answer) {
        //                         isCorrect = true;
        //                     }
        //                 });
        //                 if (isCorrect) {
        //                     headingData.score += 1;
        //                 } else {
        //                     wrongQuestions.push(catQ);
        //                 }
        //             }
        //         }
        //         headingData.title = heading.category + ' - ' + heading.subcategory;
        //         headingData.totalQuestionPercentage = (headingData.questions.length / currentAct.questions.length) * 100;
        //         headingData.correctQuestionPercentage = (headingData.score / headingData.questions.length) * 100;
        //         reportEntries.push(headingData);
        //     });
        //
        //
        //
        //     let reportLines = reportEntries.map(line => {
        //         return <tr key={line.title}>
        //             <td>{line.title}:</td>
        //             <td>
        //                 {line.correctQuestionScore + ' out of ' + line.correctQuestionLength + ' questions correct. (' + (line.correctQuestionPercentage ? line.correctQuestionPercentage.toFixed(0):0) + '%)'}
        //                 <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12   paddnone barchrtlghtbl"
        //                      style={{marginRight: '10px', marginTop: '5px', background:'white'}}>
        //                     <div className="filled" style={{width: (line.correctQuestionPercentage ? line.correctQuestionPercentage : 0) + '%', background:(line.correctQuestionPercentage && line.correctQuestionPercentage > 64 ? 'green' : 'red')}}></div>
        //                 </div>
        //             </td>
        //         </tr>;
        //     });
        //
        //     let wrongWithStudentAns = [];
        //     wrongQuestions.forEach(wrongQ => {
        //
        //         if (studentAnswers.has(currentAct.activityID + '-' + wrongQ.questionID)) {
        //             wrongQ.studentAnswer = studentAnswers.get(currentAct.activityID + '-' + wrongQ.questionID).studentAnswer;
        //         }
        //         wrongWithStudentAns.push(wrongQ);
        //     });
        //
        //     let rowCount=0;
        //     let wrongLines = wrongWithStudentAns.map(question => {
        //         let explanation = question.explanation;
        //         // replace auto play.
        //         if (explanation) {
        //             explanation = explanation.replace('autoplay', '');
        //         }
        //
        //         rowCount++;
        //         return <tr className="" key={rowCount + '-'+'wrong' + '-' + question.questionID}>
        //             <td  style={{border:'1px solid #555555'}}>
        //                 <p className="QuestionNoBorder">
        //                     <strong style={{float:'left'}}>{rowCount + '. '} </strong>{htmlParser('<span>' + question.question.replace('[[[LP_ANSWERS]]]','') + '</span>')}
        //                 </p>
        //                 <br />
        //                 <div className="std-q-heading">Correct Answer</div>
        //                 <p>
        //                     {htmlParser('<span>' + question.answer + '</span>')}
        //                 </p>
        //                 <div className="std-q-heading">Your Response</div>
        //                 <p>
        //                     {htmlParser('<span>' + question.studentAnswer + '</span>')}
        //                 </p>
        //                 <div className="std-q-heading">Explanation</div>
        //                 <p>
        //                     {htmlParser('<span>' + explanation + '</span>')}
        //                 </p>
        //             </td>
        //         </tr>;
        //     });
        //
        //     let studentStats = <table className="table" id="student-stat">
        //         <thead>
        //         <tr>
        //             <th style={{background: '#f7f8f9', border:'1px solid #555555'}}>
        //                 Questions you got wrong.
        //             </th>
        //         </tr>
        //         </thead>
        //         <tbody>
        //         {wrongLines}
        //         </tbody>
        //     </table>;
        //
        //
        //     let activityStats = <table className="table" id="act-stats-user">
        //         <tbody>
        //         <tr>
        //             <td><MaterialIcon icon="accessibility" size={24}/> Student Name:</td>
        //             <td>{this.props.auth.user.firstName + " " + this.props.auth.user.lastName}</td>
        //         </tr>
        //         <tr>
        //             <td><MaterialIcon icon="assignment" size={24}/> Activity Name:</td>
        //             <td>{currentAct.activityName}</td>
        //         </tr>
        //         <tr>
        //             <td><MaterialIcon icon="assessment" size={24}/> Score:</td>
        //             <td>{scorePercent ? scorePercent.toFixed(0):0}%</td>
        //         </tr>
        //         <tr>
        //             <td><MaterialIcon icon="check" size={24}/> Number Correct:</td>
        //             <td>{score} of {length}</td>
        //         </tr>
        //         <tr>
        //             <td><MaterialIcon icon="check" size={24}/> Time In:</td>
        //             <td>{currentAct.timeIn}{' mins'}</td>
        //         </tr>
        //         <tr>
        //             <td><MaterialIcon icon="date_range" size={24}/> Completion Date:</td>
        //             <td>{new Date().toLocaleString()}</td>
        //         </tr>
        //         </tbody>
        //     </table>;
        //
        //     let catStats = <table className="table" id="act-cat-stats-user">
        //         <tbody>
        //         {reportLines}
        //         </tbody>
        //     </table>;
        //
        //
        //     let table = <div id="user-activity-analysis" className="row">
        //         <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        //             <p style={{overflow:'auto'}}>
        //                 {activityStats}
        //             </p>
        //         </div>
        //         <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        //             <h1 className="reportHeading"  >Category Analysis </h1>
        //             <p style={{overflow:'auto'}}>
        //                 {catStats}
        //             </p>
        //         </div>
        //         <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        //             <h1 className="reportHeading"  >Student Question Analysis </h1>
        //             <p style={{overflow:'auto'}}>
        //                 {studentStats}
        //             </p>
        //         </div>
        //     </div>;
        //
        //     return <Modal id="test_report_modal"
        //                   backdrop="static"
        //                   show={this.state.showTestReportDialog}
        //                   onHide={()=> {
        //                       for (var i = 1; i < 99999; i++){window.clearInterval(i);}
        //                       this.setState({timerSet: null});
        //                       this.setState({showTestReportDialog: false, showNoAnswerAssessment: false});
        //                       if (this.state.view !== 'course') {
        //                           browserHistory.push('/student/assignments');
        //                       } else {
        //                           // set view to select activity.
        //                           this.setState({currentActivityComplete: true});
        //                       }
        //                   }}
        //                   container={this}
        //                   aria-labelledby="contained-modal-title">
        //         <Modal.Header closeButton>
        //             <Modal.Title id="delete-activity-modal">{
        //                 <span className="eleRowspopbl paddnone margnnone ">Results for <span
        //                     className="eleRowspop paddnone margnnone">{currentAct.activityName}</span></span>
        //             }</Modal.Title>
        //         </Modal.Header>
        //         <Modal.Body>
        //             {table}
        //         </Modal.Body>
        //         <Modal.Footer>
        //             <Button onClick={()=> {
        //                 this.setState({showTestReportDialog: false, showNoAnswerAssessment: false});
        //                 if (this.state.view !== 'course') {
        //                     browserHistory.push('/student/assignments');
        //                 } else {
        //                     // set view to select activity.
        //                     this.setState({currentActivityComplete: true});
        //                 }
        //             }} bsStyle='primary'>OK</Button>
        //         </Modal.Footer>
        //     </Modal>;
        // } else {
        //     if (this.state.reportData) {
        //         let wrongWithStudentAns = this.state.reportData.wrongWithStudentAns;
        //         let reportEntries = this.state.reportData.reportEntries;
        //         let score = this.state.reportData.score;
        //         let scorePercent = this.state.reportData.scorePercent;
        //         let length = this.state.reportData.length;
        //         let reportLines = reportEntries.map(line => {
        //             return <tr key={line.title}>
        //                 <td>{line.title}:</td>
        //                 <td>
        //                     {'  ' + line.correctQuestionPercentage ? line.correctQuestionPercentage.toFixed(2):0 + '% Correct'}
        //                     <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12   paddnone barchrtlghtbl"
        //                          style={{marginRight: '10px', marginTop: '5px', background:'white'}}>
        //                         <div className="filled" style={{width: (line.correctQuestionPercentage ? line.correctQuestionPercentage : 0) + '%', background:(line.correctQuestionPercentage && line.correctQuestionPercentage > 64 ? 'green' : 'red')}}></div>
        //                     </div>
        //                 </td>
        //             </tr>;
        //         });
        //
        //         let rowCount = 0;
        //         let wrongLines = wrongWithStudentAns.map(question => {
        //             let explanation = question.explanation;
        //             // replace auto play.
        //             if (explanation) {
        //                 explanation = explanation.replace('autoplay', '');
        //             }
        //             rowCount++;
        //             return <tr className="" key={rowCount + '-'+'wrong' + '-' + question.questionID}>
        //                 <td  style={{border:'1px solid #555555'}}>
        //                     <p className="QuestionNoBorder">
        //                         <strong style={{float:'left'}}>{rowCount + '. '} </strong>{htmlParser('<span>' + question.question.replace('[[[LP_ANSWERS]]]','') + '</span>')}
        //                     </p>
        //                     <br />
        //                     <div className="std-q-heading">Correct Answer</div>
        //                     <p>
        //                         {htmlParser('<span>' + question.answer + '</span>')}
        //                     </p>
        //                     <div className="std-q-heading">Your Response</div>
        //                     <p>
        //                         {htmlParser('<span>' + question.studentAnswer + '</span>')}
        //                     </p>
        //                     <div className="std-q-heading">Explanation</div>
        //                     <p>
        //                         {htmlParser('<span>' + explanation + '</span>')}
        //                     </p>
        //                 </td>
        //             </tr>;
        //         });
        //
        //
        //         let studentStats = <table className="table" id="student-stat">
        //             <thead>
        //             <tr>
        //                 <th style={{background: '#f7f8f9', border:'1px solid #555555'}}>
        //                     Questions you got wrong.
        //                 </th>
        //             </tr>
        //             </thead>
        //             <tbody>
        //             {wrongLines}
        //             </tbody>
        //         </table>;
        //
        //
        //         let activityStats = <table className="table" id="act-stats-user">
        //             <tbody>
        //             <tr>
        //                 <td><MaterialIcon icon="accessibility" size={24}/> Student Name:</td>
        //                 <td>{this.props.auth.user.firstName + " " + this.props.auth.user.lastName}</td>
        //             </tr>
        //             <tr>
        //                 <td><MaterialIcon icon="assignment" size={24}/> Activity Name:</td>
        //                 <td>{currentAct.activityName}</td>
        //             </tr>
        //             <tr>
        //                 <td><MaterialIcon icon="assessment" size={24}/> Score:</td>
        //                 <td>{scorePercent ? scorePercent.toFixed(0):0}%</td>
        //             </tr>
        //             <tr>
        //                 <td><MaterialIcon icon="check" size={24}/> Number Correct:</td>
        //                 <td>{score} of {length}</td>
        //             </tr>
        //             <tr>
        //                 <td><MaterialIcon icon="check" size={24}/> Time In:</td>
        //                 <td>{currentAct.timeIn}{' mins'}</td>
        //             </tr>
        //             <tr>
        //                 <td><MaterialIcon icon="date_range" size={24}/> Completion Date:</td>
        //                 <td>{new Date().toLocaleString()}</td>
        //             </tr>
        //             </tbody>
        //         </table>;
        //
        //         let catStats = <table className="table" id="act-cat-stats-user">
        //             <tbody>
        //             {reportLines}
        //             </tbody>
        //         </table>;
        //
        //
        //         let table = <div id="user-activity-analysis" className="row">
        //             <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        //                 <p style={{overflow:'auto'}}>
        //                     {activityStats}
        //                 </p>
        //             </div>
        //             <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        //                 <h1 className="reportHeading"  >Category Analysis </h1>
        //                 <p style={{overflow:'auto'}}>
        //                     {catStats}
        //                 </p>
        //             </div>
        //             <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        //                 <h1 className="reportHeading"  >Student Question Analysis </h1>
        //                 <p style={{overflow:'auto'}}>
        //                     {studentStats}
        //                 </p>
        //             </div>
        //         </div>;
        //
        //         return <Modal id="test_report_modal"
        //                       show={this.state.showTestReportDialog}
        //                       onHide={()=> {
        //                           for (var i = 1; i < 99999; i++){window.clearInterval(i);}
        //                           this.setState({timerSet: null});
        //                           this.setState({showTestReportDialog: false, showNoAnswerAssessment: false});
        //                           if (this.state.view !== 'course') {
        //                               browserHistory.push('/student/assignments');
        //                           } else {
        //                               // set view to select activity.
        //                               this.setState({currentActivityComplete: true});
        //                           }
        //                       }}
        //                       container={this}
        //                       aria-labelledby="contained-modal-title">
        //             <Modal.Header closeButton>
        //                 <Modal.Title id="delete-activity-modal">{
        //                     <span className="eleRowspopbl paddnone margnnone ">Results for <span
        //                         className="eleRowspop paddnone margnnone">{currentAct.activityName}</span></span>
        //                 }</Modal.Title>
        //             </Modal.Header>
        //             <Modal.Body>
        //                 {table}
        //             </Modal.Body>
        //             <Modal.Footer>
        //                 <Button onClick={()=> {
        //                     this.setState({showTestReportDialog: false, showNoAnswerAssessment: false});
        //                     if (this.state.view !== 'course') {
        //                         browserHistory.push('/student/assignments');
        //                     } else {
        //                         // set view to select activity.
        //                         this.setState({currentActivityComplete: true});
        //                     }
        //                 }} bsStyle='primary'>OK</Button>
        //             </Modal.Footer>
        //         </Modal>;
        //     }
        // }
    }

    checkEmptyAnswer(flashcardResult) {

        if (this.state.contentView === 'question') {
            // preserve current question details.
            let checked = [...document.querySelectorAll('#mainQuestionView input:checked')];
            let currentQuestion = this.state.unansweredQuestions[this.state.currentQuestionIndex];

            if (currentQuestion.questionType.toLowerCase() === 'essay') {
                this.setState({ essayActivity: true });
                let value = document.getElementById("answerTextArea").value;
                if (!value) {
                    this.setState({ showNoAnswerSelectionWarningDialog: true });
                } else {
                    this.next();
                }

            } else {

                if (checked && checked.length > 0) {
                    if (currentQuestion.questionType.toLowerCase() !== 'essay' && this.state.isPractice) {
                        document.getElementById('drop_shadow_check_ans').style.display = 'block';
                        document.querySelector('.container-fluid.noPad.listMainBoxWrapper').style.overflow = 'hidden';
                        this.setState({ showNextDialog: true });
                    } else {
                        this.next();
                    }
                } else if (this.state.isPractice) {
                    document.querySelector('.container-fluid.noPad.listMainBoxWrapper').style.overflow = 'hidden';
                    this.setState({ showNoAnswerSelectionWarningDialog: true });
                } else {
                    this.next();
                }
            }


            // now set details for next question in unanswered queue.
        } else {
            this.next(flashcardResult);
        }
    }

    next(flashcardResult) {
        console.log('Next called.');
        this.setState({ currentRating: 0 });
        if (this.state.contentView === 'question') {
            // preserve current question details.
            let checked = [...document.querySelectorAll('#mainQuestionView input:checked')];
            let assignment = this.state.assignment;
            let currentActivity = this.state.currentActivity;
            let questionActivityResults = this.state.questionActivityResults;
            let currentQuestion = this.state.unansweredQuestions[this.state.currentQuestionIndex];
            let questionKey = currentActivity.activityID + "-" + currentQuestion.questionID;
            let isCourse = this.state.view === 'course';
            let entry = {
                questionID: currentQuestion.questionID,
                activityID: currentActivity.activityID,
                assignmentID: assignment.assignmentID,
                timer: this.getTimerValue()
            };

            if (currentQuestion.questionType.toLowerCase() === 'essay') {
                let value = document.getElementById("answerTextArea").value;
                if (value) {
                    entry.studentAnswer = value;
                }
                else {
                    entry.studentAnswer = 'empty-answer';
                }
                entry.hasResult = !!value;
                if (isCourse) {
                    entry.unitID = this.state.currentUnit.unitID;
                    entry.courseID = this.state.course.courseID;
                }
                questionActivityResults.set(questionKey, entry);


            } else {

                if (checked && checked.length > 0) {

                    entry.studentAnswer = checked[0].nextElementSibling.firstElementChild.innerHTML;
                    entry.hasResult = !!checked[0].nextElementSibling.firstElementChild.innerHTML;
                    if (isCourse) {
                        entry.unitID = this.state.currentUnit.unitID;
                        entry.courseID = this.state.course.courseID;
                    }
                    questionActivityResults.set(questionKey, entry);
                } else {

                    // this.setState({showNoAnswerSelectionWarningDialog:true});
                    entry.studentAnswer = 'empty-answer';
                    if (isCourse) {
                        entry.unitID = this.state.currentUnit.unitID;
                        entry.courseID = this.state.course.courseID;
                    }
                    // Unchecked - remove entry.
                    questionActivityResults.set(questionKey, entry);
                }
            }

            if (this.state.currentQuestionIndex < (this.state.unansweredQuestions.length - 1)) {
                this.setState({
                    currentQuestionIndex: this.state.currentQuestionIndex + 1,
                    currentActivityProgress: this.state.currentActivityProgress + 1,

                });
            }
            // now set details for next question in unanswered queue.
        } else if (this.state.contentView === 'flashcard') {
            let assignment = this.state.assignment;
            let currentActivity = this.state.currentActivity;
            let flashcardActivityResults = this.state.flashcardActivityResults;
            let currentFlashcard = this.state.unansweredFlashcards[this.state.currentFlashcardIndex];
            let flashcardKey = currentActivity.activityID + "-" + currentFlashcard.word;
            let isCourse = this.state.view === 'course';
            let entry = {
                word: currentFlashcard.word,
                activityID: currentActivity.activityID,
                assignmentID: assignment.assignmentID,
                timer: this.getTimerValue()
            };
            if (isCourse) {
                entry.unitID = this.state.currentUnit.unitID;
                entry.courseID = this.state.course.courseID;
            }
            entry.studentAnswer = flashcardResult;
            entry.result = flashcardResult;
            entry.hasResult = true;
            flashcardActivityResults.set(flashcardKey, entry);
            let rightAns = 0;
            flashcardActivityResults.forEach(ans => ans.studentAnswer === true ? rightAns++ : 0);

            if (this.state.currentFlashcardIndex < (this.state.unansweredFlashcards.length - 1)) {
                this.setState({
                    currentFlashcardIndex: this.state.currentFlashcardIndex + 1,
                    flashcardRight: rightAns,
                    currentActivityProgress: this.state.currentActivityProgress + 1,

                });
            } else {
                // last card.
                if ((this.state.currentFlashcardIndex + 1) === this.state.unansweredFlashcards.length && !this.state.lastObject) {
                    this.setState({
                        flashcardRight: rightAns,
                        currentActivityProgress: this.state.currentActivityProgress + 1,
                        lastObject: true
                    });
                }
            }
        }
    }

    prev() {
        console.log('Previous called.');

        if (this.state.contentView === 'question') {
            // preserve current question details.
            let checked = [...document.querySelectorAll('#mainQuestionView input:checked')];
            let assignment = this.state.assignment;
            let currentActivity = this.state.currentActivity;
            let questionActivityResults = this.state.questionActivityResults;
            let currentQuestion = this.state.unansweredQuestions[this.state.currentQuestionIndex];
            let questionKey = currentActivity.activityID + "-" + currentQuestion.questionID;
            let isCourse = this.state.view === 'course';
            let entry = {
                questionID: currentQuestion.questionID,
                activityID: currentActivity.activityID,
                assignmentID: assignment.assignmentID,
                timer: this.getTimerValue()
            };
            if (currentQuestion.questionType.toLowerCase() === 'essay') {
                let value = document.getElementById("answerTextArea").value;
                entry.studentAnswer = value;
                entry.hasResult = !!value;
                questionActivityResults.set(questionKey, entry);
            } else {

                if (checked && checked.length > 0) {
                    entry.studentAnswer = checked[0].nextElementSibling.firstElementChild.innerHTML;
                    entry.hasResult = !!checked[0].nextElementSibling.firstElementChild.innerHTML;
                    if (isCourse) {
                        entry.unitID = this.state.currentUnit.unitID;
                        entry.courseID = this.state.course.courseID;
                    }
                    questionActivityResults.set(questionKey, entry);
                } else {
                    // Unchecked - remove entry.
                    if (isCourse) {
                        entry.unitID = this.state.currentUnit.unitID;
                        entry.courseID = this.state.course.courseID;
                    }
                    questionActivityResults.set(questionKey, entry);
                }
            }

            if (this.state.currentQuestionIndex > 0) {
                this.setState({
                    currentQuestionIndex: this.state.currentQuestionIndex - 1,
                    currentActivityProgress: this.state.currentActivityProgress - 1,

                });
            }
            // now set details for next question in unanswered queue.
        } else if (this.state.contentView === 'flashcard') {
            $('#i_was_right').removeClass('active');
            $('#i_was_wrong').removeClass('active');

            let currentActivity = this.state.currentActivity;
            let flashcardActivityResults = this.state.flashcardActivityResults;
            let currentFlashcard = this.state.unansweredFlashcards[this.state.currentFlashcardIndex];
            let flashcardKey = currentActivity.activityID + "-" + currentFlashcard.word;
            flashcardActivityResults.delete(flashcardKey);
            let rightAns = 0;
            flashcardActivityResults.forEach(ans => ans.studentAnswer === true ? rightAns++ : 0);
            if (this.state.currentFlashcardIndex > 0) {
                this.setState({
                    flashcardActivityResults: flashcardActivityResults,
                    currentFlashcardIndex: this.state.currentFlashcardIndex - 1,
                    currentActivityProgress: this.state.currentActivityProgress - 1,
                    flashcardRight: rightAns,

                });
            }
        }
    }

    pause() {
        console.log('Pause called.');
        this.setState({ showPauseDialog: true, pausedActivity: true });
        // this.submit(true);
    }

    hint() {
        console.log('Hint called.');

        this.setState({
            ...this.state,
            showHintDialog: true
        })
    }

    print() {
        let directionStyle = this.props.auth.user.prefLanguage === 'ar' ? "direction:rtl;text-align:left;display:inline-block" : "";

        if (this.state.contentView === 'flashcard') {
            let record = this.state.unansweredFlashcards;
            let count = 0;
            let printNodes = [];
            if (record.length > 0) {
                record.forEach(flashcard => {
                    let flashcardText = flashcard.translatedWord ? flashcard.translatedWord : flashcard.word;
                    let definition = flashcard.wordDefinition;
                    printNodes.push("<div> <h3> Flashcard " + (count + 1) + "</h3> <p style='" + directionStyle + "'>" + flashcardText + "</p> <p style='" + directionStyle + "'>" + definition + "</p></div><hr />");
                    count++;
                });
                let myWindow = window.open('', 'PRINT', 'height=600,width=800');
                myWindow.document.write('<html><head><title>' + 'All Flashcards' + '</title>');
                myWindow.document.write('<h1>' + 'All Flashcards' + '</h1>');
                myWindow.document.write(printNodes.join(" "));
                myWindow.document.write('</body></html>');
                myWindow.focus(); // necessary for IE >= 10*/
                setTimeout(function () {
                    myWindow.print();
                    myWindow.document.close(); // necessary for IE >= 10
                    myWindow.close();
                }, 1000);
                return false;
            }
        }
        else {
            let record = this.state.unansweredQuestions;
            let count = 0;
            let printNodes = [];
            if (record.length > 0) {
                record.forEach(question => {
                    let questionText = question.question.replace('[[[LP_ANSWERS]]]', '');
                    let questionText2 = "";
                    if (this.props.auth.user.prefLanguage === 'ar' && questionText.indexOf('<div') === 0) {
                        questionText2 = questionText.substring(0, questionText.indexOf('>'));
                        questionText2 += " style='" + directionStyle + "' ";
                        questionText2 += questionText.substring(questionText.indexOf('>'));
                    } else {
                        questionText2 = "<p style='" + directionStyle + "'> " + questionText + "</p>";
                    }

                    let choicesHtml = [];
                    let choiceCount = 0;
                    if (question.choices && question.choices.length > 0) {
                        question.choices.forEach(choice => {
                            choicesHtml.push("<div className='fieldBox'  style='direction:ltr'id=" + question.activityID + choiceCount + ">" + "<span>" + (choiceCount + 1) + ". </span>" + "<input type='checkbox' className='css-checkbox css-label1 bc_info card_side'  id=" + "choice" + choiceCount + " name='choice' />" +
                                "<label className='css-label radGroup1' for='choice'" + choiceCount + "'></label><div style='" + directionStyle + "'>" + choice + "</div></div>");
                            choiceCount++;
                        });
                    }
                    printNodes.push("<div> <h3> Question " + (count + 1) + "</h3> <p>" + questionText2 + "</p> <p>" + choicesHtml.join(" ") + "</p></div><hr />");
                    count++;
                });
                let myWindow = window.open('', 'PRINT', 'height=600,width=800');
                myWindow.document.write('<html><head><title>' + 'All Questions' + '</title>');
                myWindow.document.write('<h1>' + 'All Questions' + '</h1>');
                myWindow.document.write(printNodes.join(" "));
                myWindow.document.write('</body></html>');
                myWindow.focus(); // necessary for IE >= 10*/
                setTimeout(function () {
                    myWindow.print();
                    myWindow.document.close(); // necessary for IE >= 10
                    myWindow.close();
                }, 1000);
                return false;
            }
        }


    }

    refreshIdentityToken(response) {

        return new Promise ( (resolve, reject) => {
            const token = response['id_token'];
            const access_token = response['access_token'];
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: config.identityPoolId,
            Logins: {
                'accounts.google.com': token
            }
        });
            AWS.config.region = config.region;
            AWS.config.credentials.clearCachedId();
            AWS.config.credentials.get(function (err, data) {
            if (err) {
                reject("Error: " + err);
            }
            localStorage.setItem('accessToken', access_token);
            localStorage.setItem('identityToken', token);
            localStorage.setItem('accessKeyId', AWS.config.credentials.accessKeyId);
            localStorage.setItem('secretAccessKey', AWS.config.credentials.secretAccessKey);
            localStorage.setItem('sessionToken', AWS.config.credentials.sessionToken);
        });

        resolve(AWS.config.credentials.getPromise());
        })
    }

    getAwsCredentials() {
        AWS.config.update({ region: 'us-east-1' });

        let userPool = new CognitoUserPool({
            UserPoolId: config.userPoolId,
            ClientId: config.clientId
        });
        let cognitoUser = userPool.getCurrentUser();
        cognitoUser.getSession(function (err, session) {
            if (session) {
                console.log('session validity: ' + session.isValid());
                localStorage.setItem('token', session.getIdToken().getJwtToken());
            }
        });

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: config.identityPoolId,
            Logins: {
                [config.cognitoURL]: localStorage.getItem('token')
            }
        });
        AWS.config.credentials.clearCachedId();
        // AWS.config.credentials.clearCachedId();
        // We can set the get method of the Credentials object to retrieve
        // the unique identifier for the end user (identityId) once the provider
        // has refreshed itself

        AWS.config.credentials.get(function (err) {
            if (err) {
                console.log("Error: " + err);
            }
            // localStorage.setItem('token', result.getIdToken().getJwtToken());
            localStorage.setItem('accessKeyId', AWS.config.credentials.accessKeyId);
            localStorage.setItem('secretAccessKey', AWS.config.credentials.secretAccessKey);
            localStorage.setItem('sessionToken', AWS.config.credentials.sessionToken);
        });

        return AWS.config.credentials.getPromise();
    }



    sendActivityAssignmentResultForGoogle(payload, submitToTeacher, pause, showReport, markActivityComplete, saveCourse) {
        let token = this.state.gToken
        this.refreshIdentityToken(token).then(res => {
            for (var i = 1; i < 99999; i++) { window.clearInterval(i); }
            this.setState({ timerSet: null, showNextDialog: false, showNoAnswerAssessment: false });
            let context = this;
            if (this.state.currentActivity.activityType === 'question') {
                let correctAnsweredQuestions = [];
                let wrongAnsweredQuestions = [];
                let questions = this.state.currentActivity.questions;
                let answered = payload.assignedActivities.answeredQuestion;
                answered.forEach(ans => {
                    if (ans.studentAnswer === questions.filter(q => {
                        return q.questionID === ans.questionID
                    })[0].answer) {
                        correctAnsweredQuestions.push(ans);
                    } else {
                        wrongAnsweredQuestions.push(ans);
                    }
                });

                payload.assignedActivities.correctAnsweredQuestions = correctAnsweredQuestions;
                payload.assignedActivities.wrongAnsweredQuestions = wrongAnsweredQuestions;
            } else if (this.state.currentActivity.activityType === 'flashcards') {

                var correctAnsweredQuestions = [];
                var wrongAnsweredQuestions = [];
                var answered = payload.assignedActivities.answeredFlashcard;
                answered.forEach(ans => {
                    if (ans.studentAnswer === true) {
                        correctAnsweredQuestions.push(ans);
                    } else {
                        wrongAnsweredQuestions.push(ans);
                    }
                });
                payload.assignedActivities.correctAnsweredQuestions = correctAnsweredQuestions;
                payload.assignedActivities.wrongAnsweredQuestions = wrongAnsweredQuestions;
            }

            context.showIndicator();
            let pathTemplate = '/assignment/student/practice';
            let params = {};
            if (submitToTeacher) {
                payload.submit = true;
            }
            if (pause) {
                payload.pause = 'true';
            } else {
                payload.pause = 'false';
            }

            payload.activityTime = this.getTimerValue();

            if (markActivityComplete) {
                payload.assignedActivities.isCompleted = 'true';
            }
            return new Promise((resolve, reject) => {
                apiClient.invokeApi(params, pathTemplate, 'PUT', {}, payload, '2').then(function (result) {
                    ClevertapReact.event("Submitted Practice Course from " + context.state.subjectID ? context.state.subjectID : context.props.location.state.subjectID, { 'userName': context.props.auth.user.userName });
                    context.hideIndicator();
                    if (submitToTeacher) {
                        let preserveOrder = context.state.preserveOrder;
                        let item = preserveOrder.get(context.state.currentActivity.activityID);
                        item.isLocked = false;
                        item.complete = true;
                        preserveOrder.set(context.state.currentActivity.activityID, item);
                        context.setState({
                            preserveOrder: preserveOrder,
                            showNextDialog: false
                        });
                    }

                    resolve(result.data);
                    if (showReport) {
                        if (markActivityComplete) {
                            context.setState({
                                showTestReportDialog: true,
                                reportData: result.data,
                                currentActivityProgress: 0,
                                showNextDialog: false
                            })
                        } else {
                            context.setState({
                                showTestReportDialog: true,
                                showNextDialog: false
                            })
                        }
                    } else if (saveCourse) {
                        context.setState({
                            currentActivityComplete: true,
                            showNextDialog: false
                        })
                    } else {
                        browserHistory.push('/student/assignments');
                    }
                }).catch(function (error) {
                    context.hideIndicator();
                    reject(error);
                });
            });
        }).catch(e => {
        })
    }

    sendActivityAssignmentResult(payload, submitToTeacher, pause, showReport, markActivityComplete, saveCourse) {
        this.getAwsCredentials().then(res => {
            for (var i = 1; i < 99999; i++) { window.clearInterval(i); }
            this.setState({ timerSet: null, showNextDialog: false, showNoAnswerAssessment: false });
            let context = this;
            if (this.state.currentActivity.activityType === 'question') {
                let correctAnsweredQuestions = [];
                let wrongAnsweredQuestions = [];
                let questions = this.state.currentActivity.questions;
                let answered = payload.assignedActivities.answeredQuestion;
                answered.forEach(ans => {
                    if (ans.studentAnswer === questions.filter(q => {
                        return q.questionID === ans.questionID
                    })[0].answer) {
                        correctAnsweredQuestions.push(ans);
                    } else {
                        wrongAnsweredQuestions.push(ans);
                    }
                });

                payload.assignedActivities.correctAnsweredQuestions = correctAnsweredQuestions;
                payload.assignedActivities.wrongAnsweredQuestions = wrongAnsweredQuestions;
            } else if (this.state.currentActivity.activityType === 'flashcards') {

                var correctAnsweredQuestions = [];
                var wrongAnsweredQuestions = [];
                var answered = payload.assignedActivities.answeredFlashcard;
                answered.forEach(ans => {
                    if (ans.studentAnswer === true) {
                        correctAnsweredQuestions.push(ans);
                    } else {
                        wrongAnsweredQuestions.push(ans);
                    }
                });
                payload.assignedActivities.correctAnsweredQuestions = correctAnsweredQuestions;
                payload.assignedActivities.wrongAnsweredQuestions = wrongAnsweredQuestions;
            }

            context.showIndicator();
            let pathTemplate = '/assignment/student/practice';
            let params = {};
            if (submitToTeacher) {
                payload.submit = true;
            }
            if (pause) {
                payload.pause = 'true';
            } else {
                payload.pause = 'false';
            }

            payload.activityTime = this.getTimerValue();

            if (markActivityComplete) {
                payload.assignedActivities.isCompleted = 'true';
            }
            return new Promise((resolve, reject) => {
                apiClient.invokeApi(params, pathTemplate, 'PUT', {}, payload, '2').then(function (result) {
                    ClevertapReact.event("Submitted Practice Course from " + context.state.subjectID ? context.state.subjectID : context.props.location.state.subjectID, { 'userName': context.props.auth.user.userName });
                    context.hideIndicator();
                    if (submitToTeacher) {
                        let preserveOrder = context.state.preserveOrder;
                        let item = preserveOrder.get(context.state.currentActivity.activityID);
                        item.isLocked = false;
                        item.complete = true;
                        preserveOrder.set(context.state.currentActivity.activityID, item);
                        context.setState({
                            preserveOrder: preserveOrder,
                            showNextDialog: false
                        });
                    }

                    resolve(result.data);
                    if (showReport) {
                        if (markActivityComplete) {
                            context.setState({
                                showTestReportDialog: true,
                                reportData: result.data,
                                currentActivityProgress: 0,
                                showNextDialog: false
                            })
                        } else {
                            context.setState({
                                showTestReportDialog: true,
                                showNextDialog: false
                            })
                        }
                    } else if (saveCourse) {
                        context.setState({
                            currentActivityComplete: true,
                            showNextDialog: false
                        })
                    } else {
                        browserHistory.push('/student/assignments');
                    }
                }).catch(function (error) {
                    context.hideIndicator();
                    reject(error);
                });
            });
        }).catch(e => {
        })
    }

    submit(pause, showReport, markActivityComplete) {
        console.log('Submit called.');
        this.setState({ currentRating: 0 });
        let assignment = this.state.assignment;
        let currentActivity = this.state.currentActivity;

        if (this.state.contentView === 'question') {
            // preserve current question details.
            let questionActivityResults = this.state.questionActivityResults;
            let isCourse = this.state.view === 'course';

            // same stuff stays here..
            let checked = [...document.querySelectorAll('#mainQuestionView input:checked')];
            let currentQuestion = this.state.unansweredQuestions[this.state.currentQuestionIndex];
            let questionKey = currentActivity.activityID + "-" + currentQuestion.questionID;
            let entry = {
                studentID: this.props.auth.user.userName,
                questionID: currentQuestion.questionID,
                activityID: currentActivity.activityID,
                assignmentID: assignment.assignmentID,
                timer: this.getTimerValue()
            };

            if (currentQuestion.questionType.toLowerCase() === 'essay') {
                let value = document.getElementById("answerTextArea").value;
                entry.studentAnswer = value;
                entry.hasResult = !!value;
                if (isCourse) {
                    entry.unitID = this.state.currentUnit.unitID;
                    entry.courseID = this.state.course.courseID;
                }
                questionActivityResults.set(questionKey, entry);
            } else {
                if (checked && checked.length > 0) {
                    entry.studentAnswer = checked[0].nextElementSibling.firstElementChild.innerHTML;;
                    entry.hasResult = !!checked[0].nextElementSibling.firstElementChild.innerHTML;;
                    if (isCourse) {
                        entry.unitID = this.state.currentUnit.unitID;
                        entry.courseID = this.state.course.courseID;
                    }
                    questionActivityResults.set(questionKey, entry);
                } else {
                    entry.studentAnswer = 'empty-answer';
                    entry.hasResult = false;
                    if (isCourse) {
                        entry.unitID = this.state.currentUnit.unitID;
                        entry.courseID = this.state.course.courseID;
                    }
                    questionActivityResults.set(questionKey, entry);
                }
            }

            let answeredQuestion = [];
            let timerForAssignmentActivity = 0;
            questionActivityResults.forEach(q => {
                answeredQuestion.push(q);
                timerForAssignmentActivity += q.timer
            });
            currentActivity.answeredQuestion = answeredQuestion;
            if (!currentActivity.count) {
                currentActivity.count = 0;
            }
            if (pause) {

                currentActivity.progress = ((this.state.currentActivityProgress) / currentActivity.questions.length) * 100;
            } else {
                currentActivity.progress = 100;
                currentActivity.count = parseInt(currentActivity.count) + 1;
            }

            assignment.assignedActivities = currentActivity;
            assignment.studentID = this.props.auth.user.userName;

            // general payload.
            let payload = {
                "assignmentID": assignment.assignmentID,
                "assignmentName": assignment.assignmentName,
                "practiceType": assignment.activityType,
                "assignedActivities": {
                    "activityID": currentActivity.activityID,
                    "activityName": currentActivity.activityName,
                    "activityType": currentActivity.activityType,
                    "timer": this.getTimerValue(),
                    "assessmentType": currentActivity.assessmentType,
                    "count": currentActivity.count,
                    "progress": currentActivity.progress,
                    "avgScore": 0,
                    "answeredQuestion": currentActivity.answeredQuestion,
                    "isCompleted": markActivityComplete
                },
                "studentID": this.props.auth.user.userName,
                "submit": true,
                "pause": pause
            };

            if (isCourse) {

                // save for course.
                payload.assignedActivities.courseID = this.state.course.courseID;
                payload.assignedActivities.unitID = this.state.currentUnit.unitID;
                let course = this.state.course;
                let progress = 0;
                let activityCount = 0;
                course.units.forEach(unit => {
                    unit.activities.forEach(act => {
                        console.log("act.activityID === payload.assignedActivities.activityID:  " + act.activityID + " " + payload.assignedActivities.activityID);
                        if (act.activityID === payload.assignedActivities.activityID) {
                            progress += parseFloat(payload.assignedActivities.progress);
                        } else {
                            progress += parseFloat(act.progress ? act.progress : 0);
                        }
                        activityCount++;
                        console.log(progress);
                        console.log(activityCount);
                    })
                });
                payload.courseProgress = progress / activityCount;
                if (this.props.auth.user.federatedIdentity) {
                    this.sendActivityAssignmentResultForGoogleentResult(payload, true, pause, showReport, markActivityComplete, true);

                } else {
                    this.sendActivityAssignmentResult(payload, true, pause, showReport, markActivityComplete, true);
                }
            } else {
                if (this.props.auth.user.federatedIdentity) {
                    this.sendActivityAssignmentResultForGoogleentResultlt(payload, true, pause, showReport, markActivityComplete);
                } else {

                    this.sendActivityAssignmentResult(payload, true, pause, showReport, markActivityComplete);
                }
                // activity.
            }

            // now set details for next question in unanswered queue.
        } else if (this.state.contentView === 'flashcard') {

            // preserve current question details.
            let flashcardActivityResults = this.state.flashcardActivityResults;
            let isCourse = this.state.view === 'course';

            let answeredFlashcard = [];
            let timerForAssignmentActivity = 0;
            flashcardActivityResults.forEach(q => {
                answeredFlashcard.push(q);
                timerForAssignmentActivity += q.timer
            });

            currentActivity.answeredFlashcard = answeredFlashcard;

            if (!currentActivity.count) {
                currentActivity.count = 0;
            }
            if (pause) {

                currentActivity.progress = ((this.state.currentActivityProgress) / currentActivity.words.length) * 100;
            } else {
                currentActivity.progress = 100;
                currentActivity.count = parseInt(currentActivity.count) + 1;
            }

            // currentActivity.count = parseInt(currentActivity.count) + 1;
            // currentActivity.progress = ((this.state.currentActivityProgress) / currentActivity.words.length) * 100;

            assignment.assignedActivities = currentActivity;
            assignment.studentID = this.props.auth.user.userName;

            let payload = {
                "assignmentID": assignment.assignmentID,
                "assignmentName": assignment.assignmentName,
                "practiceType": assignment.activityType,
                "assignedActivities": {
                    "activityID": currentActivity.activityID,
                    "activityName": currentActivity.activityName,
                    "activityType": "flashcards",
                    "timer": timerForAssignmentActivity,
                    "assessmentType": currentActivity.assessmentType,
                    "count": currentActivity.count,
                    "progress": currentActivity.progress,
                    "avgScore": 0,
                    "answeredFlashcard": currentActivity.answeredFlashcard,
                    "isCompleted": markActivityComplete
                },
                "studentID": this.props.auth.user.userName,
                "submit": true,
                "pause": pause
            };

            if (isCourse) {
                // save for course.
                payload.assignedActivities.courseID = this.state.course.courseID;
                payload.assignedActivities.unitID = this.state.currentUnit.unitID;

                //calculate progress for course.
                let course = this.state.course;
                let progress = 0;
                let activityCount = 0;
                course.units.forEach(unit => {
                    unit.activities.forEach(act => {
                        console.log("act.activityID === payload.assignedActivities.activityID:  " + act.activityID + " " + payload.assignedActivities.activityID);
                        if (act.activityID === payload.assignedActivities.activityID) {
                            progress += parseFloat(payload.assignedActivities.progress);
                        } else {
                            progress += parseFloat(act.progress ? act.progress : 0);
                        }
                        activityCount++;
                        console.log(progress);
                        console.log(activityCount);
                    })
                });
                payload.courseProgress = progress / activityCount;
                if ( this.props.auth.user.federatedIdentity ) {
                    this.sendActivityAssignmentResultForGoogle(payload, true, pause, false, markActivityComplete, true);
                } else {

                    this.sendActivityAssignmentResult(payload, true, pause, false, markActivityComplete, true);
                }
            } else {
                if ( this.props.auth.user.federatedIdentity ) {
                    this.sendActivityAssignmentResultForGoogle(payload, true, pause, false, markActivityComplete);
                 } else {
                    this.sendActivityAssignmentResult(payload, true, pause, false, markActivityComplete);

                }
                // activity.
            }

            this.setState({ flashcardRight: 0 });
        }
    }

    end(markActivityComplete) {
        console.log('End called.');
        this.setState({ currentRating: 0 });
        let that = this;
        document.getElementById('drop_shadow_check_ans').style.display = 'none';
        setTimeout(function () {
            that.setState({ showNoAnswerAssessment: false, showNextDialog: false });
        }, 1000);
        that.submit(false, !this.state.essayActivity, markActivityComplete);

    }

    render() {
        let context = this;
        let course = context.state.assignment && context.state.assignment.assignedCourses ? context.state.assignment.assignedCourses : null;
        let activity = context.state.assignment && context.state.assignment.assignedActivities ? context.state.assignment.assignedActivities : null;

        let content = null;

        if (context.state.currentActivity) {
            if (context.state.contentView === 'question') {

                let currentQuestion = context.state.unansweredQuestions[context.state.currentQuestionIndex];
                let questionActivityResults = this.state.questionActivityResults;
                content = this.getMainContent(context.state.contentView, currentQuestion, questionActivityResults.get(this.state.currentActivity.activityID + '-' + currentQuestion.questionID));
            } else if (context.state.contentView === 'flashcard') {
                let currentFlashcard = context.state.unansweredFlashcards[context.state.currentFlashcardIndex];
                content = this.getMainContent(context.state.contentView, currentFlashcard);
            } else if (context.state.contentView === 'learning') {
                content = this.getMainContent(context.state.contentView);
            }
        }



        return (
            <div className="container-fluid noPad listMainBoxWrapper " id="view-assignment">
                <Heading
                    breadcrumbsData={
                        [
                            {
                                name: 'Self Study', action: () => {
                                    browserHistory.push('/student/selfstudy');
                                }
                            },
                            {
                                name: 'Practice Course', action: () => {
                                }
                            }
                        ]
                    }
                    pickList={
                        <div>
                            <a href={'/student/selfstudy/view/' + encodeURIComponent(this.state.subjectID) + '[[question]]?subject=' + this.state.subjectID}>Questions</a> |
                            <a href={'/student/selfstudy/flashcard/' + encodeURIComponent(this.state.subjectID) + '[[flashcard]]?subject=' + this.state.subjectID}>Flashcards</a> |
                            <a href={'/student/selfstudy/course/' + encodeURIComponent(this.state.subjectID)}>Courses</a>
                        </div>
                    }
                    showFilterButtons="false"
                />
                {this.state.showAlert &&
                    <div className="row">
                        <div className="col-md-3 col-xs-3" />
                        <div className="col-md-6 col-xs-3">
                            <Alert bsStyle={this.state.alertStyle}
                                onDismiss={this.handleAlertDismiss}>
                                {this.state.alertText}
                            </Alert>
                        </div>
                        <div className="col-md-3 col-xs-3" />
                    </div>
                }
                <div id="submission" className="tab-pane fade active in">

                    {/* Side panel for Course view */}
                    {course && this.getCourseSidePanel(course)}
                    {/* Side panel for Activity view */}
                    {activity && !course && this.getActivitySidePanel(activity)}
                    {/* Main Content */}
                    <div className="mainActivBoxRight" id="contentPanel">

                        {/* Main content heading and breadcrumbs */}

                        {context.state.contentView === 'question' && this.getMainContentTopHeading()}

                        {/* content for current activity */}
                        {this.state.currentActivity && this.state.currentActivityComplete === false && <div className="">

                            {(context.state.contentView === 'flashcard' || context.state.contentView === 'learning') && content}

                            <div className="container-fluid questionMainBlock" style={{ marginTop: '58px' }}>
                                <div className="quesAndIconStudent">

                                    {context.state.contentView === 'question' && content}
                                    {/* Content area footer  */}
                                    {context.state.contentView !== 'learning' && <div className="row">
                                        <div className=" lastQuesRedirectStudent">
                                            <div className="col-md-2 col-sm-3 col-xs-2 text-alignlft">
                                                {!this.state.isPractice && (this.state.currentQuestionIndex > 0 || this.state.currentFlashcardIndex > 0)
                                                    && <a href="javascript:void 0" className="prevQuesStudent"
                                                        onClick={this.prev}>
                                                        <span className="submitAnswer">Back</span></a>}
                                                {this.state.contentView === 'question' && (this.state.isPractice || this.state.currentQuestionIndex < (this.state.currentActivity.questions.length - 1)) &&
                                                    <a href="javascript:void 0"
                                                        className={this.state.isPractice ? "nextQuesStudent forPracticeOnly" : "nextQuesStudent "}
                                                        onClick={function () {
                                                            let currentQuestion = context.state.unansweredQuestions[context.state.currentQuestionIndex];
                                                            //  if (context.state.currentActivity.questions.length - context.state.currentQuestionIndex === 1) {
                                                            //     this.end(true);
                                                            //  } else
                                                            this.checkEmptyAnswer();
                                                        }.bind(this)
                                                        }> <span className="submitAnswer hidden-xs hidden-sm">{this.state.isPractice ? 'Submit Answer' : 'Next'}</span><span className="submitAnswer hidden-md hidden-lg">Submit</span></a>}
                                            </div>
                                            <div className="col-md-7 col-sm-6 col-xs-7 text-center">
                                                <a href="javascript:void 0" className="pause" onClick={this.pause}>
                                                    <span className="submitAnswer">Pause</span></a>
                                                {this.state.isPractice && <a href="javascript:void 0" className="hint"
                                                    onClick={this.hint}><span className="submitAnswer">Hint</span></a>}
                                                <a href="javascript:void 0" className="print" onClick={this.print}>
                                                    <span className="submitAnswer">Print</span></a>
                                            </div>
                                            <div className="col-md-3 col-sm-3 col-xs-2 text-alignrght">
                                                {/* {!this.state.isPractice &&
                                                 <a href="javascript:void 0" className="submit" onClick={this.submit}>
                                                 Submit</a>} */}
                                                {this.state.isPractice &&
                                                    <a href="javascript:void 0" className="end "
                                                        onClick={function () {
                                                            this.end(true);
                                                        }.bind(this)}> <span className="submitAnswer">End</span></a>}

                                                {!this.state.isPractice &&
                                                    <a href="javascript:void 0" className="end "
                                                        onClick={function () {
                                                            this.checkForSkippedAnswers();
                                                        }.bind(this)}><span className="submitAnswer">Submit & End</span></a>}
                                            </div>
                                        </div>
                                    </div>}

                                </div>
                            </div>
                        </div>}

                        {this.state.currentActivityComplete &&
                            <div className="row" key={'no_activity_selected'} id="mainQuestionView">
                                <div id="no_activity_selected">
                                    <p>
                                        Please select an activity from side bar.
                                    <br /><br />
                                        <a href="javascript:void 0" onClick={() => {
                                            browserHistory.push('/student/selfstudy');
                                        }}> Go back to Self Study </a>
                                    </p>
                                </div>
                            </div>}

                    </div>

                </div>
                <div id="drop_shadow_check_ans" className="fade in modal" style={{
                    zIndex: 0,
                    display: 'none',
                    background: 'black',
                    opacity: 0.5
                }}></div>
                <ProgressIndicator show={this.state.loading} />
                {this.state.showNextDialog && this.getCheckAnswerDialog()}
                {this.state.showHintDialog && this.getHintDialog()}
                {this.state.showTestReportDialog && this.getTestReportDialog()}
                {this.state.activitySwitchWarningDialog && this.getActivitySwitchWarningDialog()}
                {this.state.showNoAnswerSelectionWarningDialog && this.getNoAnswerSelectionWarningDialog()}
                {this.state.showNoAnswerAssessment && this.getNoAnswerAssessmentDialog()}
                {this.state.assessmentCannotBeDoneAgain && this.getAssessmentCannotBeDoneAgainDialog()}
                {this.state.showPauseDialog && this.getPauseDialog()}
            </div>
        );
    }

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    getAssignmentByIdAndStudentId(id) {
        let context = this;
        context.showIndicator();
        let subject = decodeURIComponent(this.params.id);
        console.log(subject);
        let params = {
            subject: subject,
            studentID: this.props.auth.user.userName
        };
        let pathTemplate = '/course/practice/subject/{subject}/student/{studentID}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        return new Promise((resolve, reject) => {
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '2').then(function (result) {

                resolve(result.data);
            }).catch(function (error) {
                reject(error);
            });
        });
    }
}

function mapStateToProps(state) {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(PracticeCourse);
