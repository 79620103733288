// PieChart.js
import React, { Component } from "react";
import { Pie } from "react-chartjs-2";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { connect } from "react-redux";
import htmlParser from "../../../common/htmlParser";

// Register the datalabels plugin with Chart.js
Chart.plugins.register(ChartDataLabels);

class PieChart extends Component {
  constructor(props) {
    super(props);
  }
  // const PieChart = () => {
  render() {
    // const labels = ['Option 1&nbsp', 'Option 2&nbsp', 'Option 3&nbsp', 'Option 4&nbsp', 'Option 5&nbsp', 'Empty'];
    // const studentsPerAnswer = [['user1', 'user2', 'user3'], ['aa', 'bb', 'ccc'], ['qq', 'WWW', 'user3']];
    
    const data = {
      labels: this.props.data.labels,
      datasets: [
        {
          data: this.props.data.count,
          students: this.props.data.studentsPerAnswer, //[['user1', 'user2', 'user3'], ['user1', 'user2', 'user3'], ['user1', 'user2', 'user3']],
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };
    console.log(data);
    
    const options = {
      responsive: true,
      legend: {
        display: false, // Disable legend display
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            if (data.datasets[0].students[tooltipItem.index]) {
              return data.datasets[0].students[tooltipItem.index].map(
                (id) => id + "\n"
              );
            }
            if(data.labels[tooltipItem.index] == 'NoAnswer')
            return "No Answer";
          },
          title: function (tooltipItem, data) {
            // Display a custom title for the tooltip
            const index = tooltipItem[0].index;
            if(data.labels[index] == 'NoAnswer'){
                return ''
            }
            return `${htmlParser(data.labels[index])}`;
          },
        },
        titleFontSize: 14,
        bodyFontSize: 12,
        backgroundColor: "rgba(0,0,0,0.7)", // Tooltip background color
        titleFontColor: "#fff", // Title text color
        bodyFontColor: "#fff", // Body text color
        displayColors: false, // Hide the color box
        enabled: this.props.auth.user.userType != 'student'
      },
      plugins: {
        datalabels: {
          color: "#000",
          anchor: "center",
          align: "center",
          font: {
            weight: 600, // Make the data labels bold
            size: 13,
          },
          formatter: (value, context) => {
            if(this.props.data.labels[context.dataIndex] == 'NoAnswer'){
                return 'No Answers';
            }
            return value > 0 ? (
              htmlParser(this.props.data.labels[context.dataIndex]) +
              " : " +
              value
            ): " ";
          },
        },
      },
    };

    return (
      <div style={{ width: "50em" }}>
        <Pie data={data} options={options} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state };
}

export default connect(mapStateToProps)(PieChart);
