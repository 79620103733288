/**
 * Created by ashahab on 7/25/2017.
 */
import React from 'react';
import htmlParser from '../common/htmlParser';

export const Notifications = (props) =>
{
    let allNotifications = props.allNotifications;
    let showNotifcation = props.showNotifcation;

    let cssClassName = "cd-panel from-right";
    if(showNotifcation)
    {
        cssClassName = "cd-panel from-right is-visible"
    }

    allNotifications.sort(function(a, b) {
      return parseFloat(b.creationTime) - parseFloat(a.creationTime);
    });

    let allNotificationsHtmlArray = [];
    for(let i=0; i<allNotifications.length; i++)
    {
        allNotificationsHtmlArray.push(
            <div key={allNotifications[i].creationTime} className="notifiStrip">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-sm-12">
                        {(!allNotifications[i].notificationStatus || allNotifications[i].notificationStatus === "read") &&
                            <p className="stripText">{htmlParser(allNotifications[i].notification)}</p>
                        }
                        {allNotifications[i].notificationStatus === "unread" &&
                            <p className="stripText"><strong> {htmlParser(allNotifications[i].notification)}</strong></p>
                        }
                        <p className="assignTime">{formatDate(parseFloat(allNotifications[i].creationTime))}</p>
                    </div>
                </div>
            </div>
        )
    }
    return(
        <div className={cssClassName}>
            <header className="cd-panel-header">
                <h2>Notifications</h2>
                <a href="#0" className="cd-panel-close" onClick={props.closeNotification}>Close</a> </header>
            <div className="cd-panel-container">
                {allNotificationsHtmlArray}
            </div>
        </div>
    )
}

function formatDate(dateVal) {
    let newDate = new Date(dateVal);
    let currentDate = new Date();

    let diff = (currentDate - newDate)/3600000;

    let retVal = "";
    if (diff < 1) {
        retVal = "Less than an hour ago";
    } else if (Math.round(diff) === 1) {
        retVal = "1 hour ago";
    } else if (diff < 24) {
        retVal = Math.round(diff) + " hours ago";
    } else if (Math.round(diff/24) === 1) {
        retVal = "1 day ago";
      } else {
          retVal = Math.round(diff/24) + " days ago";
    }
    return retVal;
}
