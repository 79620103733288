/**
 * Created by ashahab on 8/2/2017.
 */
import React, {Component} from 'react';
import createFragment from 'react-addons-create-fragment';
import './maintenance-table-mobile.css';
import {connect} from 'react-redux';
import Utility from '../maintenance-table/utility';
import htmlParser from '../common/htmlParser';

class QuestionsMaintenanceTableMobile extends Component {
    constructor(props) {
        super(props);
        this.emptyMessage = props.emptyMessage;
        this.columns = props.columns;
        this.subCol = props.columns.filter((col)=> {
            return col.name === 'subjects'
        })[0];
    }

    getRecordIconClass(questionType) {
        if(questionType)
            switch (questionType.toLowerCase()) {
                case 'essay':
                    return 'QuesWrite';
                case 'multiple choice':
                    return 'QuesRead';
                default:
                    return '';
            }
        return '';
    }

    render() {
        let viewQuestionMobile = this.props.viewQuestionMobile;

        let records = <div className=" mobileListView hidden-lg hidden-tab hidden-md" key={'maintenance-table-mobile'}>
            {this.props.items.map(record => {
                
                record.question = record.question ? record.question.replace('&nbsp;', ''): '';
                while (record.question.indexOf('<br>') >= 0) {
                    record.question = record.question.replace('<br>', '');
                }
                record.question = record.question.replace('[[[LP_ANSWERS]]]', '');
                if(record.question.indexOf("autoplay") >= 0) {
                    record.question = record.question.replace(/autoplay/g, "");
                }

                let ownerName = record['ownerName'] ? Utility.ucFirst(record['ownerName']) : '';
                let actClass = this.getRecordIconClass(record['questionType']);
                return createFragment({
                    key: <div className={"listviewblockBox " + actClass} onClick={()=>{viewQuestionMobile(record)}}>
                        <div className="col-xs-2"><span className="typeIcon"/></div>
                        <div className="col-xs-10">
                          <span className="contentType ">
                            <h4>{htmlParser('<span>' + record.question + '</span>')}</h4>
                            <p>By <strong>{ownerName}</strong></p>
                          </span>
                        </div>
                    </div>
                });
            })}
            {this.props.items.length === 0 ? <div className="listviewblockBox emptyMessage">
                {this.props.emptyMessage}
            </div> : ''}
        </div>;
        return (records);
    }
}

function mapStateToProps(state = {}) {
    return {...state};
}
export default connect(mapStateToProps)(QuestionsMaintenanceTableMobile);
