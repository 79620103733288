/**
 * Created by ashahab on 8/24/2017.
 */

import React, {Component} from "react";
import {connect} from "react-redux";
import htmlParser from "../../../common/htmlParser";

class WidgetActivityLog extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div  className="dasboxss col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: '0px'}}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  pacboxdes smalcont" style={{margin: '0px'}} >
                    <div className="dashboardWidgetContainer" style={{margin: '0px', padding: '15px', backgroundColor: this.props.color}} >
                        <strong>{htmlParser(this.props.message)}</strong>
                        {this.props.extraLink && this.props.extraLink}
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state = {}) {
    return {...state};
}

export default connect(mapStateToProps)(WidgetActivityLog);
