import React from "react";
import { connect } from "react-redux";
import { Alert, Modal, Button } from "react-bootstrap";
import { gapi } from 'gapi-script';
import ProgressIndicator from "../../../progress-indicator/progress-indicator";
import Heading from "../../../heading/heading";
import apiClient from "../../../../actions/api-client";
import "./prac-assignment.css";
import { Tabs, Tab } from 'react-bootstrap';
import $ from "jquery";
import tick from "../../../../assets/images/tick.png";
import hint from "./hint.png";
import warning from "./warning.png";
import url from "./url.jpg";
import pdf from "./pdf.png";
import cancel from "../../../../assets/images/cnc.png";
import { browserHistory, withRouter } from "react-router";
import ElasticSearchStore from "../../../../actions/elastic-search-store";
import config from "../../../../config";
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'
import MaterialIcon from "react-google-material-icons";
import Draggable, { DraggableCore } from 'react-draggable'; // Both at the same time
import Utility from '../../../maintenance-table/utility';
import ClevertapReact from "clevertap-react";
import ReactDOM from 'react-dom';
import AudioPlayer from 'react-h5-audio-player';
import classnames from "classnames";
import MobileExplanationPanel from "../../assignments/view/MobileExplanationPanel.js";
import AWS from 'aws-sdk';
import {
    CognitoUserPool,
    AuthenticationDetails,
    CognitoUser
} from 'amazon-cognito-identity-js';
import {
    parseHours,
    parseMinutes,
    parseSeconds
} from '../../../../utils/timeParser';
import { SET_FULLSCREEN } from "../../../../actions/types";
import { UNSET_FULLSCREEN } from "../../../../actions/types";
import htmlParser from "../../../common/htmlParser.js";

class PracticeSubject extends React.Component {

    constructor(props) {
        super(props);
        this.getSubjectFilterData = this.getSubjectFilterData.bind(this);
        this.getCourseSidePanel = this.getCourseSidePanel.bind(this);
        this.loadActivity = this.loadActivity.bind(this);
        this.isActivityComplete = this.isActivityComplete.bind(this);
        this.isPracticeActivity = this.isPracticeActivity.bind(this);
        this.setTimer = this.setTimer.bind(this);
        this.getTimerValue = this.getTimerValue.bind(this);
        this.resumeActivity = this.resumeActivity.bind(this);
        this.getMainContentTopHeading = this.getMainContentTopHeading.bind(this);
        this.generateQuestionView = this.generateQuestionView.bind(this);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
        this.pause = this.pause.bind(this);
        this.hint = this.hint.bind(this);
        this.print = this.print.bind(this);
        this.submit = this.submit.bind(this);
        this.end = this.end.bind(this);
        this.questionChoiceSelected = this.questionChoiceSelected.bind(this);
        this.sendActivityAssignmentResult = this.sendActivityAssignmentResult.bind(this);
        this.sendActivityAssignmentResultForGoogle = this.sendActivityAssignmentResultForGoogle.bind(this);
        this.getCheckAnswerDialog = this.getCheckAnswerDialog.bind(this);
        this.getTestReportDialog = this.getTestReportDialog.bind(this);
        this.getHintDialog = this.getHintDialog.bind(this);
        this.getActivitySwitchWarningDialog = this.getActivitySwitchWarningDialog.bind(this);
        this.switchActivity = this.switchActivity.bind(this);
        this.generateFlashcardView = this.generateFlashcardView.bind(this);
        this.expandCollapseSideBar = this.expandCollapseSideBar.bind(this);
        this.unitHeadingToggle = this.unitHeadingToggle.bind(this);
        this.searchByKeyword = this.searchByKeyword.bind(this);
        this.addNewDeckClicked = this.addNewDeckClicked.bind(this);
        this.myFlashcards = this.myFlashcards.bind(this);
        this.learnerPalFlashcards = this.learnerPalFlashcards.bind(this);
        this.deckSortChange = this.deckSortChange.bind(this);
        this.deleteDeck = this.deleteDeck.bind(this);
        this.onRate = this.onRate.bind(this);
        this.checkEmptyAnswer = this.checkEmptyAnswer.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
        this.audioHandler = this.audioHandler.bind(this);
        this.toggleTranslate = this.toggleTranslate.bind(this);
        this.setOriginalQuestion = this.setOriginalQuestion.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this);
        this.getTranslationButton = this.getTranslationButton.bind(this);
        this.setQuestionAndClearTranslateState = this.setQuestionAndClearTranslateState.bind(this);
        this.route = this.props.route;
        this.router = this.props.router;
        this.keepOnPage = this.keepOnPage.bind(this);

        let params = this.props.params.id.split('[[');
        let view = params[1].replace(']]', '') === 'course' ? 'course' : 'activity';
        let contentView = params[1].replace(']]', '');
        this.params = { id: params[0] };
        if (contentView === 'question') {
            this.esDocType = 'question' + config.stage;
        } else if (contentView === 'flashcard') {
            this.esDocType = 'glossary' + config.stage;
        }
        this.estore = new ElasticSearchStore();

        this.state = {
            leave: true,
            timerSeconds: 0,
            timerMinutes: 0,
            timerHours: 0,
            questionActivityResults: new Map(),
            flashcardActivityResults: new Map(),
            flashcardRight: 0,
            showNextDialog: false,
            showHintDialog: false,
            showTestReportDialog: false,
            activitySwitchWarningDialog: false,
            assignmentProgress: 0,
            currentActivityProgress: 1,
            currentActivityComplete: true,
            preserveOrder: new Map(),
            lockedCount: 0,
            view: view,
            contentView: contentView,
            currentCategoryIndex: null,
            currentCategory: null,
            showCountDialog: false,
            showCountCategory: null,
            showCountSubCategory: null,
            answeredQuestions: [],
            unansweredQuestions: [],
            unansweredFlashcards: [],
            correctAnsweredQuestions: [],
            wrongAnsweredQuestions: [],
            currentQuestionIndex: 0,
            currentFlashcardIndex: 0,
            isPractice: 'practice',
            showKeywordSearchCountDialog: false,
            keywordSearchTotal: 0,
            activePanel: 'learnerpal',
            showDeleteDialog: false,
            record: null,
            deleteError: false,
            viewDefinition: false,
            showNoAnswerSelectionWarningDialog: false,
            screenWidth: window.innerWidth,
            seeEnglish: "",
            submitAnswerBtn: true,
            seeTranslation: "",
            isTranslated: false,
            tempAnswer: "",
            disableCheck: true,
            selectedTab: "question",
            inputCheck: "",
            disableReAns: true,
            ansCheck: false
        };

    }
    handleWindowSizeChange = () => {
        this.setState({ screenWidth: window.innerWidth });
    };

    checkEmptyAnswer(flashcardResult) {

        if (this.state.contentView === 'question') {
            // preserve current question details.
            let checked = [...document.querySelectorAll('#mainQuestionView input:checked')];
            let currentQuestion = this.state.unansweredQuestions[this.state.currentQuestionIndex];

            if (currentQuestion.questionType.toLowerCase() === 'essay') {
                this.setState({ essayActivity: true });
                let value = document.getElementById("answerTextArea").value;
                if (!value) {
                    this.setState({ showNoAnswerSelectionWarningDialog: true });
                } else {
                    this.next();
                }

            } else {

                if (checked && checked.length > 0) {
                    if (currentQuestion.questionType.toLowerCase() !== 'essay' && this.state.isPractice) {
                        document.getElementById('drop_shadow_check_ans').style.display = 'block';
                        document.querySelector('.container-fluid.noPad.listMainBoxWrapper').style.overflow = 'hidden';
                        // this.setState({ showNextDialog: true });
                        this.setState({ selectedTab: "explanation" });
                        this.setState({ disableCheck: false });
                        this.setState({ inputCheck: "disabled" });
                        this.setState({ submitAnswerBtn: false });
                    } else {
                        this.next();
                    }
                } else if (this.state.isPractice) {
                    document.querySelector('.container-fluid.noPad.listMainBoxWrapper').style.overflow = 'hidden';
                    this.setState({ showNoAnswerSelectionWarningDialog: true });
                } else {
                    this.next();
                }
            }


            // now set details for next question in unanswered queue.
        } else {
            this.next(flashcardResult);
        }
    }

    componentDidMount() {

        let { dispatch } = this.props;
        dispatch({
            type: SET_FULLSCREEN
        });
        if (this.props.auth.user.federatedIdentity) {
            window.gapi.load('auth2', () => {
                this.auth2 = gapi.auth2.init({
                    client_id: config.googleClientId,
                })
                this.auth2.then(() => {

                    console.log('on init');
                    let currentUser = this.auth2.currentUser.get();
                    currentUser.reloadAuthResponse().then(
                        (result) => {
                            this.setState({ "gToken": result });
                        },
                        (err) => {
                            console.log("Could Not refresh Idenity Token", err);
                        }
                    );

                });
            });

        }

        if (this.props.location.state) {
            this.setState(this.props.location.state);
        }
        else if (this.params.id) {
            this.setState({ subjectID: this.params.id });
        }
        let context = this;
        this.router.setRouteLeaveHook(this.route, this.keepOnPage);
        this.showIndicator();
        this.getUserDecks().then(result => {
            context.state.decks = result
        });
        this.getSubjectFilterData(context.params.id).then(result => {
            this.hideIndicator();
            let assignment = {};
            let categories = [];
            let buckets = [];
            if (context.state.contentView === 'question') {
                buckets = result.aggregations.category.category.buckets;
            } else {
                buckets = result.aggregations.category.buckets;
            }
            buckets.forEach(cat => categories.push({ category: cat.key, subCategories: [] }));
            let sorted = categories.sort((a, b) => {
                return a.category.localeCompare(b.category);
            });
            let state = {
                ...context.state,
                categories: sorted
            };
            if (state.view === 'course') {
                state.assignment = assignment;
                state.view = 'course';
                state.course = assignment.assignedCourses;
            } else {
                let context = this;
                if (context.state.answeredQuestion) {
                    for (let i = 0; i < context.state.answeredQuestion.length; i++) {
                        let ans = context.state.answeredQuestion[i];
                        let questionKey = this.props.auth.user.userName + '-' + this.params.id + "-" + ans.questionID;
                        context.state.questionActivityResults.set(questionKey, {
                            studentID: ans.stdID,
                            questionID: ans.questionID,
                            activityID: ans.activityID,
                            assignmentID: ans.assignmentID,
                            timer: ans.timer,
                            studentAnswer: ans.stdAnswer,
                            hasResult: true
                        });
                    }
                }
                if (!this.state.timerSet) {
                    for (var i = 1; i < 99999; i++) { window.clearInterval(i); }
                    this.setState({ timerSet: null });
                    this.setTimer();
                    state.timerSet = true;
                }
                context.setState(state);
            }
        }).catch(error => {
            context.hideIndicator();
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Assignment cannot be loaded please contact administrator.'
            });
            setTimeout(() => {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);
        });
        window.addEventListener('beforeunload', this.keepOnPage);

    }
    componentWillUnmount() {
        for (var i = 1; i < 99999; i++) { window.clearInterval(i); }
        this.setState({ timerSet: null });
        window.removeEventListener('beforeunload', this.keepOnPage);
    }
    // componentDidUpdate(prevProps, prevState) {
    //
    //     if (prevState.seeTranslation != "" && prevState.seeEnglish != "") {
    //         this.setState({ seeTranslation: "" });
    //         this.setState({ seeEnglish: "" });
    //     }
    // }

    keepOnPage(e) {
        const { dispatch } = this.props;
        const { isFullScreen } = this.props.appSettings;
        let message = 'This page is asking you to confirm that you want to leave - Data you have entered may not be saved.';
        // e.returnValue = message;
        var r = window.confirm(message);
        if (r == true) {
            if (isFullScreen) {
                dispatch({
                    type: UNSET_FULLSCREEN
                });
            }
            else if (!isFullScreen || this.state.leave) { return null; }
        } else {
            return false;
        }

    }

    loadActivity(activity, unitID) {
        let context = this;

        // assuming this is a course assignment, because the only way this function is called is through side panel of a course assignment.

        new Promise((resolve, reject) => {
            // load activity with its execution details. {count, progress, avg score, hasResult for each question if its a question type.}
            let course = context.state.assignment.assignedCourses;
            // always have a single unit
            let unit = course.units.filter(unit => unit.unitID === unitID)[0];
            // always have an activity.
            let clickedActivity = unit.activities.filter(act => act.activityID === activity.activityID)[0];

            resolve({ activity: clickedActivity, unit: unit });
        }).then(result => {
            let activity = result.activity;
            let unit = result.unit;
            // Act loaded. set to main frame.
            if (activity.activityType.toLowerCase() === 'question') {

                // filter only unanswered questions.
                let unanswered = [];
                let answered = [];
                activity.questions.forEach(question => {
                    if (!question.hasResult) {
                        unanswered.push(question);
                    } else {
                        answered.push(question);
                    }
                });

                //Randomize.
                let randomized = new Set();
                while (randomized.size !== unanswered.length) {
                    let question = unanswered[Math.floor(Math.random() * (unanswered.length))];
                    console.log(question);
                    randomized.add(question)
                }
                randomized = Array.from(randomized);
                let currentIndex = 0;

                context.setState({
                    contentView: 'question',
                    currentActivity: activity,
                    currentUnit: unit,
                    answeredQuestions: answered,
                    unansweredQuestions: randomized,
                    currentQuestionIndex: currentIndex,
                    isPractice: activity.assessmentType.toLowerCase() === 'practice',
                    currentActivityComplete: false
                });
            } else if (activity.activityType.toLowerCase() === 'flashcard' || activity.activityType.toLowerCase() === 'flashcards') {
                let state = { ...context.state };
                // filter only unanswered flashcards.
                let unanswered = [];
                let answered = [];
                activity.words.forEach(word => {
                    if (!word.hasResult) {
                        unanswered.push(word);
                    } else {
                        answered.push(word);
                    }
                });

                //Randomize.
                let randomized = new Set();
                while (randomized.size !== unanswered.length) {
                    let word = unanswered[Math.floor(Math.random() * (unanswered.length))];
                    console.log(word);
                    randomized.add(word)
                }
                randomized = Array.from(randomized);
                let currentIndex = 0;

                state.contentView = 'flashcard';
                state.currentActivity = activity;
                state.answeredFlashcards = answered;
                state.unansweredFlashcards = randomized;
                state.currentFlashcardIndex = currentIndex;
                state.isPractice = false;
                state.currentUnit = unit;
                state.currentActivityComplete = false;

                context.setState(state);
            } else {
                // learning / pdf / url.
                context.setState({
                    contentView: 'learning',
                    currentActivity: activity,
                    currentUnit: unit,
                    currentActivityComplete: false
                });
            }
        }).catch(error => {

            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: "Oops, Sorry that doesn't seem to work. If you need help, contact support@learnerpal.com"
            });
            setTimeout(() => {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);

        });

        console.log(activity, 'Load activity called.');
    }

    isActivityComplete(act) {
        // TODO - change complete logic after api change. Check if this act is assessment type and complete.
        return false;
        //return act.progress === 100 && act.assessmentType.toLowerCase() !== 'practice';
    }

    isPracticeActivity(act) {
        return act.assessmentType.toLowerCase() === 'practice';
    }

    isResumeable(act) {
        //return act.assessmentType.toLowerCase() === 'practice' && (act.progress.trim() && act.progress.trim() !== '100%' && act.progress.trim() !== '0%');
        return false;
    }

    resumeActivity(act) {
        console.log(act, 'Resume activity called.');
    }

    switchActivity(evt, act, unitID) {

        this.loadActivity(act, unitID);
        let menus = [];
        if (evt.target.nodeName === 'A') {
            menus = [...document.querySelectorAll('[id=' + evt.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.id + '] .fieldBox div.fieldBoxFullWidth')];
        } else if (evt.target.nodeName === 'LABEL') {
            menus = [...document.querySelectorAll('[id=' + evt.target.parentElement.parentElement.parentElement.parentElement.parentElement.id + '] .fieldBox div.fieldBoxFullWidth')];
        } else if (evt.target.id === 'activityTypeDisplay') {
            menus = [...document.querySelectorAll('[id=' + evt.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.id + '] .fieldBox div.fieldBoxFullWidth')];
        } else {
            menus = [...document.querySelectorAll('[id=' + evt.target.parentElement.parentElement.parentElement.parentElement.id + '] .fieldBox div.fieldBoxFullWidth')];
        }
        for (var i = 0; i < menus.length; i++) {
            let menu = menus[i];
            menu.classList.remove('active');
        }
        if (evt.target.nodeName === 'A') {
            evt.target.parentElement.parentElement.classList.add('active');
        } else if (evt.target.nodeName === 'LABEL') {
            evt.target.parentElement.classList.add('active');
        } else if (evt.target.id === 'activityTypeDisplay') {
            evt.target.parentElement.parentElement.classList.add('active');
        } else {
            evt.target.classList.add('active');
        }
    }

    getSidePanelUnitHeadingActivities(subCategories, category) {
        let context = this;
        let result = [];
        if (subCategories) {
            result = subCategories.map(subCat => {
                return <div className="fieldBox" key={subCat} id={subCat} onClick={(evt) => {
                    context.setState({
                        showCountDialog: true,
                        showCountCategory: category,
                        showCountSubCategory: subCat,
                        seeTranslation: null,
                        seeEnglish: null,
                        tempAnswer: "",
                        leave: false,
                    });
                    let menus = [];
                    if (evt.target.nodeName === 'A' || evt.target.nodeName === 'I') {
                        menus = [...document.querySelectorAll('[id=' + evt.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.id + '] .fieldBox div.fieldBoxFullWidth')];

                    } else if (evt.target.nodeName === 'LABEL') {
                        menus = [...document.querySelectorAll('[id=' + evt.target.parentElement.parentElement.parentElement.parentElement.parentElement.id + '] .fieldBox div.fieldBoxFullWidth')];
                    } else if (evt.target.id === 'activityTypeDisplay') {
                        menus = [...document.querySelectorAll('[id=' + evt.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.id + '] .fieldBox div.fieldBoxFullWidth')];
                    } else {
                        menus = [...document.querySelectorAll('[id=' + evt.target.parentElement.parentElement.parentElement.parentElement.id + '] .fieldBox div.fieldBoxFullWidth')];
                    }
                    for (var i = 0; i < menus.length; i++) {
                        let menu = menus[i];
                        menu.classList.remove('active');
                    }
                    if (evt.target.nodeName === 'A') {
                        evt.target.parentElement.parentElement.classList.add('active');
                    } else if (evt.target.nodeName === 'LABEL') {
                        evt.target.parentElement.classList.add('active');
                    } else if (evt.target.id === 'activityTypeDisplay') {
                        evt.target.parentElement.parentElement.classList.add('active');
                    } else {
                        evt.target.classList.add('active');
                    }
                }} data-category={category}>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-alignlft paddnone fieldBoxFullWidth">
                        <label htmlFor="subject2" className="pay_label css-label1 activityLeftPanel"><a
                            href="javascript:void 0">{subCat}</a> </label>
                        {  /* Completed status */
                            context.isActivityComplete(subCat) && context.state.isPractice !== true &&
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-alignlft" style={{ float: 'right' }}>
                                <div className="maincolgreen"><img src={tick} /> Completed</div>
                            </div>
                        }
                        { /* Resume button only if its a practice course */
                            context.isResumeable(subCat) &&
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-alignlft paddnone">
                                <div className="maincolblus" onClick={context.resumeActivity(subCat, category)}>Resume</div>
                            </div>
                        }
                    </div>
                </div>;
            });
        }
        return result;
    }

    unitHeadingToggle(evt) {
        console.log(evt, 'Unit heading toggle called.');
        let context = this;
        evt.persist();
        let box = evt.target.tagName === 'SPAN' ? evt.target.parentElement.nextElementSibling.firstElementChild : evt.target.nextElementSibling.firstElementChild;
        let key = box.id.replace('box-filter-unit-', '');
        if (context.state.contentView === 'question') {
            let category = context.state.categories.filter(function (cat) {
                return cat.category === key
            })[0];
            if (category && category.subCategories && category.subCategories.length > 0) {
                if (box.className.indexOf('in') > -1) {
                    box.className = box.className.replace('in', '');
                    box.style.height = '0px';
                } else {
                    box.className += ' in';
                    box.style.height = 'auto';
                }
            } else {
                // this.showIndicator();
                this.getSubCategory(key).then(result => {

                    var subs = [];
                    result.aggregations.subcategory.subcategory.buckets.forEach(sub => subs.push(sub.key));
                    let sorted = subs.sort((a, b) => {
                        return a > b;
                    });
                    let cats = context.state.categories;
                    let currentCategory = null;
                    for (let i = 0; i < cats.length; i++) {
                        if (cats[i].category === key) {
                            cats[i].subCategories = sorted;
                            currentCategory = cats[i];
                        }
                    }
                    context.setState({
                        leave: false,
                        categories: cats,
                        currentCategory: currentCategory
                    });
                    if (box.className.indexOf('in') > -1) {
                        box.className = box.className.replace('in', '');
                        box.style.height = '0px';
                    } else {
                        box.className += ' in';
                        box.style.height = 'auto';
                    }
                    this.hideIndicator();
                }).catch(error => {
                    context.setState({
                        showAlert: true,
                        alertStyle: 'danger',
                        alertText: 'Unable to load sub categories, please contact administrator.'
                    });
                    setTimeout(() => {
                        context.setState({
                            showAlert: false,
                            alertStyle: '',
                            alertText: ''
                        })
                    }, 4000);
                })
            }
        } else if (context.state.contentView === 'flashcard') {
            context.setState({
                leave: false,
                showCountDialog: true,
                showCountCategory: key,
                showCountSubCategory: ""
            });
        }
    }

    getSidePanelUnitHeadings(categories) {
        let context = this;
        let results = [];
        if (categories) {
            let unitCount = 1;
            categories.forEach(category => {

                if (category && category.subCategories) {
                    results.push(
                        <button type="button" className="collapsed" key={'trigger-unit-' + category.category}
                            id={'trigger-unit-' + category.category}
                            onClick={context.unitHeadingToggle}><span className="categoryItem">{category.category}</span>
                            {context.state.contentView === 'question' && <span className="caret caret-close" />}
                        </button>
                    );
                    results.push(
                        <div className="SubjectBox" key={'box-unit-' + category.category} id={'box-unit-' + category.category}>
                            <div className="limitFilters collapse" id={'box-filter-unit-' + category.category}
                                style={{ height: "0px" }}>
                                {/* Course Unit Activities */
                                    context.getSidePanelUnitHeadingActivities(category.subCategories, category.category)
                                }
                            </div>
                        </div>
                    );
                }
                unitCount++;
            });
        }
        return <div className="overFlowScroll-sm overflww" id={'all-units'}>{results}</div>;
    }

    deckClicked(id) {
        console.log('Deck clicked ' + id);

        this.showIndicator();
        let context = this;
        let params = {
            activityID: id,
        };
        let pathTemplate = '/activity/{activityID}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        this.showIndicator();
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {

            context.state.flashcardActivityResults.clear();
            context.state.questionActivityResults.clear();
            context.setState({
                showCountDialog: false,
                showCountCategory: null,
                showCountSubCategory: null,
                currentCategoryName: result.data.activityName,
                loading: false,
                unansweredFlashcards: result && result.data.words ? result.data.words : [],
                currentActivityComplete: false,
                currentFlashcardIndex: 0,
                questionFlashcardResults: new Map(),
                correctAnsweredQuestions: [],
                wrongAnsweredQuestions: [],
                flashcardRight: 0
            });
        }).catch(function (error) {
            context.setState({
                showCountDialog: false,
                showCountCategory: null,
                showCountSubCategory: null,
                loading: false
            });
        });
    }

    deleteDeck(id) {
        console.log('Delete deck called.', id);

        this.setState({ showDeleteDialog: true, record: id, deleteError: false });
    }

    getSidePanelForDecks() {
        let context = this;
        let results = [];
        if (context.state.decks) {
            let unitCount = 1;
            context.state.decks.results.forEach(act => {
                if (act && act.id) {
                    let date = new Date(act.createdAt).toLocaleDateString();
                    results.push(
                        <div className="overFlowScroll-sm leftsi " style={{ borderTop: "0px" }}
                            key={'trigger-unit-' + act.id} id={'trigger-unit-' + act.id} >
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  pull-left SubjectBox">
                                <div className="fieldBox ">
                                    <div className="col-lg-8 col-md-8 col-sm-10 col-xs-10  paddnone">
                                        <label onClick={function () {
                                            this.deckClicked(act.id);
                                        }.bind(this)} htmlFor="subject1" className="pay_label css-label1 margnnone" style={{ paddingLeft: "10px" }}><a
                                            href="javascript:void 0">{act.name}</a><br /><a
                                                className="colorgrey fontszthr"
                                                href="javascript:void 0">{date}</a></label>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-2 col-xs-2  paddnone">
                                        <a className="crooss" href="javascript:void 0" onClick={function () {
                                            this.deleteDeck(act.id);
                                        }.bind(this)}>&nbsp;</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
                unitCount++;
            });
        }
        return <div className="overFlowScroll-sm overflww" id={'all-units'}>{results}</div>;
    }

    expandCollapseSideBar(evt) {
        console.log(evt, 'Expand Collapse called.');
        let sidePanel = $("#sidePanel");
        let contentPanel = $("#contentPanel");
        $('#sidePanel').scrollTop(0);
        sidePanel.toggleClass('leftBoxWidthMinimize');
        contentPanel.toggleClass('rightBoxWidthMinimize');
    }

    searchByKeyword(keyword) {

        let context = this;
        context.showIndicator();
        return new Promise((resolve, reject) => {
            let aggs = null;
            if (this.state.contentView === 'question') {
                aggs = {
                    "subcategory": {
                        "terms": { "field": "subcategory" }
                    }
                };
            } else {
                aggs = {
                    "category": {
                        "terms": { "field": "category" }
                    }
                };
            }
            this.estore.query(null, this.esDocType, {
                "bool": {
                    "must": {
                        "query_string": {
                            "query": "keywords : (" + keyword + ") AND subjects : (\"" + this.params.id + "\")"
                        }
                    }
                }
            }, null, null, null, aggs, "0").then((filterData) => {

                context.setState({
                    keywordSearchTotal: filterData.data.totalRecords,
                    showKeywordSearchCountDialog: true,
                    loading: false
                });
                resolve(filterData.data);
            }).catch(error => {
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to perform search, please contact administrator.',
                    loading: false
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            })
        });
    }

    deckSortChange(evt) {

        if (evt.target.value === 'date') {
            let decks = this.state.decks.sort(function (a, b) {
                return a.createdAt > b.createdAt
            });
            this.setState({
                decks: decks
            })
        } else {
            let decks = this.state.decks.sort(function (a, b) {
                return a.name > b.name
            });
            this.setState({
                decks: decks
            })
        }
    }

    getDeleteDialog() {
        let body = <div id="deleteDialogContent">
            <p>
                Are you sure you want to delete the flashcard deck?
            </p>
            {this.state.deleteError ? <Alert bsStyle="danger">
                {this.state.deleteErrorMessage}
            </Alert> : ''}
        </div>;
        return <Modal
            backdrop="static"
            show={this.state.showDeleteDialog}
            onHide={() => {
                this.setState({ showDeleteDialog: false, record: null, deleteError: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Delete Flashcard Deck</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showDeleteDialog: false, record: null, deleteError: false });
                }}>Cancel</Button>
                <Button onClick={() => {
                    let context = this;
                    this.deleteActivity(this.state.record).then(result => {
                        context.setState({ showDeleteDialog: false, record: null, deleteError: false });
                    }).catch(error => {
                        context.setState({ deleteError: true, deleteErrorMessage: error.statusMessage });
                    });
                }} bsStyle='primary'>Delete</Button>
            </Modal.Footer>
        </Modal>;
    }

    deleteActivity(id) {
        let rejectDuplicate = (reject, error) => reject({
            status: 400,
            statusMessage: error.message
        });
        let ownerName = this.props.auth.user.userName;
        return new Promise((resolve, reject) => {
            this.showIndicator();
            let pathTemplate = '/activity/' + id + '/ownername/' + ownerName;
            let method = 'DELETE';
            let that = this;
            let deletedId = id;
            apiClient.invokeApi(null, pathTemplate, method, {}, {}).then(function (result) {
                that.setState({ showAlert: true, alertText: result.data, alertStyle: "success" });
                setTimeout(() => {
                    that.setState({ ...that.state, showAlert: false });
                }, 5000);
                resolve({
                    status: result.status,
                    statusMessage: result.data
                });
                let decks = that.state.decks;
                decks = decks.filter(function (deck) {
                    return deck.id !== deletedId;
                });
                that.setState({
                    decks: decks,
                    loading: false
                })
            }).catch(function (result) {
                that.setState({ showAlert: true, alertText: result.message, alertStyle: "danger" });
                setTimeout(() => {
                    that.setState({ ...that.state, showAlert: false });
                }, 5000);
                that.hideIndicator();
                rejectDuplicate(reject, { message: 'Internal error.' });
            });
        });
    }

    getCategorySidePanel(categories) {
        let context = this;

        if (categories) {
            let subjectName = context.params.id;
            let iconClass = "typeIcons";
            switch (subjectName.toLowerCase()) {
                case 'algebra 1':
                case 'algebra':
                    iconClass = 'typeIcons';
                    break;
                case 'u.s. history and government':
                case 'us history':
                case 'u.s. history':
                    iconClass = 'typeIconsUSHistory';
                    break;
                case 'global history and geography':
                    iconClass = 'typeIconshistory';
                    break;
                case 'cc geometry':
                    iconClass = 'typeIconsgeometry';
                    break;
                case 'earth science':
                    iconClass = 'typeIconsscience';
                    break;
                case 'living environment':
                    iconClass = 'typeIconsenvironment';
                    break;
                default:
                    break;
            }
            return <div className="anchorLeftBoxss filterBlockss setHeightFullScreen" id="sidePanel">
                <div className="ArrowDivCollapse "><span className="ArrowExpandss resmain"
                    onClick={context.expandCollapseSideBar} /></div>

                {/* Course Heading and collapse arrow */}
                {/* <div className="SubjectBox" style={{ borderBottom: '1px solid #ccc' }}> */}
                <div className="SubjectBox">
                    <div className="limitFiltersss">
                        <span className="typeIcons" />
                        <span className="contentTypeSS ">
                            <h4>{subjectName}</h4>
                            <div className="" />
                        </span>
                    </div>
                </div>
                <div className="overFlowScroll-sm leftsi">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  pull-left SubjectBox">
                        <div className="input-group">
                            <input type="text" id="search_input_field" className="form-control" placeholder="Search by keyword" onKeyDown={function (evt) {

                                if (evt.keyCode == 13) {
                                    console.log('Enter pressed on keyword search.');
                                    this.searchByKeyword($("#search_input_field").val());
                                }
                            }.bind(this)} />
                            <span className="input-group-btn">
                                <span className="btn btn-primary btnrac" type="button" onClick={function () { this.searchByKeyword($("#search_input_field").val()) }.bind(this)}><span
                                    className="searchBtn" /></span>
                            </span>
                        </div>
                    </div>
                </div>
                {context.state.activePanel === 'decks' && <div className="overFlowScroll-sm leftsi">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  pull-left SubjectBox">
                        <div className="input-group">
                            <div>
                                <div>
                                    <span style={{
                                        float: 'left',
                                        padding: '10px',
                                        fontWeight: 'bold !important'
                                    }}>Sort By:</span>
                                    <select className="form-control " style={{ width: '150px', borderRadius: '5px', display: 'inline-block' }} onChange={this.deckSortChange}>
                                        <option className="" value="name">Name</option>
                                        <option className="" value="date">Date</option>
                                    </select><span className="selectArrow" /></div>
                            </div>
                        </div>
                    </div>
                </div>}
                {/* Course Unit Headings */}
                {context.state.activePanel === 'learnerpal' && context.getSidePanelUnitHeadings(categories)}
                {context.state.activePanel === 'decks' && context.getSidePanelForDecks()}

            </div>;
        }
    }

    getCourseSidePanel(course) {
        let context = this;

        if (course) {
            let courseName = course.courseName;
            let dueDate = context.state.assignment ? context.state.assignment.dueDate : '';
            let units = course.units ? course.units : [];
            let iconClass = "typeIcons";
            if (course.subject) {
                switch (course.subject.toLowerCase()) {
                    case 'algebra 1':
                    case 'algebra':
                        iconClass = 'typeIcons';
                        break;
                    case 'u.s. history and government':
                    case 'us history':
                    case 'u.s. history':
                        iconClass = 'typeIconsUSHistory';
                        break;
                    case 'global history and geography':
                        iconClass = 'typeIconshistory';
                        break;
                    case 'cc geometry':
                        iconClass = 'typeIconsgeometry';
                        break;
                    case 'earth science':
                        iconClass = 'typeIconsscience';
                        break;
                    case 'living environment':
                        iconClass = 'typeIconsenvironment';
                        break;
                    default:
                        break;
                }
            }
            return <div className="anchorLeftBox filterBlockss" id="sidePanel">
                <div className="ArrowDivCollapse "><span className="ArrowExpandss resmain"
                    onClick={context.expandCollapseSideBar} /></div>

                {/* Course Heading and collapse arrow */}
                <div className="SubjectBox">
                    <div className="limitFilters">
                        <span className={iconClass} />
                        <span className="contentType ">
                            <h4>{courseName}</h4>
                            <div className="">{dueDate}</div>
                        </span>
                    </div>
                </div>
                {/* Course Unit Headings */}
                {context.getSidePanelUnitHeadings(units)}

            </div>;
        }
    }

    getActivitySidePanel(act) {
        let context = this;
        if (act) {

            let courseName = context.state.assignment && context.state.assignment.assignedActivities && context.state.assignment.assignedActivities.activityName ? context.state.assignment.assignedActivities.activityName : '';
            let isPractice = context.state.assignment && context.state.assignment.assignedActivities && context.state.assignment.assignedActivities.assessmentType ? context.state.assignment.assignedActivities.assessmentType : '';
            let count = context.state.assignment && context.state.assignment.assignedActivities && context.state.assignment.assignedActivities.count ? context.state.assignment.assignedActivities.count : 0;
            let dueDate = context.state.assignment ? context.state.assignment.dueDate : '';
            let iconClass = "typeIcons";
            switch (context.state.assignment.assignedActivities.subjects[0].toLowerCase()) {
                case 'algebra 1':
                case 'algebra':
                    iconClass = 'typeIcons';
                    break;
                case 'u.s. history and government':
                case 'us history':
                case 'u.s. history':
                    iconClass = 'typeIconsUSHistory';
                    break;
                case 'global history and geography':
                    iconClass = 'typeIconshistory';
                    break;
                case 'cc geometry':
                    iconClass = 'typeIconsgeometry';
                    break;
                case 'earth science':
                    iconClass = 'typeIconsscience';
                    break;
                case 'living environment':
                    iconClass = 'typeIconsenvironment';
                    break;
                default:
                    break;
            }

            return <div className="anchorLeftBox filterBlockss" id="sidePanel">

                <div className="ArrowDivCollapse "><span className="ArrowExpandss resmain" onClick={context.expandCollapseSideBar} /></div>

                {/* Course Heading and collapse arrow */}

                <div className="SubjectBox">
                    {isPractice.toLowerCase() === 'practice' && <div className="maincolblusr" style={{
                        float: 'right',
                        marginRight: '80px',
                        display: 'block'
                    }}>{count}</div>}
                    <div className="limitFilters">
                        <span className={iconClass} />
                        <span className="contentType ">
                            <h4>{courseName}</h4>
                            <div className="">{dueDate}</div>
                        </span>
                    </div>
                </div>
            </div>;
        }
    }

    setTimer() {
        let context = this;
        // document.getElementById('timerSeconds').innerHTML = context.state.timerSeconds;
        // document.getElementById('timerMinutes').innerHTML = context.state.timerMinutes;
        // document.getElementById('timerHours').innerHTML = context.state.timerHours;
        setInterval(() => {
            if (context.state.timerSeconds === 59) {
                if (context.state.timerMinutes + 1 === 60) {
                    var sec = document.getElementById('timerSeconds');
                    var min = document.getElementById('timerMinutes');
                    var hr = document.getElementById('timerHours');
                    if (sec && min && hr) {
                        context.state.timerSeconds = 0;
                        context.state.timerMinutes = 0;
                        context.state.timerHours = ++context.state.timerHours;
                        // second, minute and hour.
                        sec.innerHTML = context.state.timerSeconds.toString().length === 1 ? '0' + context.state.timerSeconds : context.state.timerSeconds;
                        min.innerHTML = context.state.timerMinutes.toString().length === 1 ? '0' + context.state.timerMinutes : context.state.timerMinutes;
                        hr.innerHTML = context.state.timerHours.toString().length === 1 ? '0' + context.state.timerHours : context.state.timerHours;
                    }
                } else {
                    var sec = document.getElementById('timerSeconds');
                    var min = document.getElementById('timerMinutes');
                    if (sec && min) {
                        context.state.timerSeconds = 0;
                        context.state.timerMinutes = ++context.state.timerMinutes;
                        sec.innerHTML = context.state.timerSeconds.toString().length === 1 ? '0' + context.state.timerSeconds : context.state.timerSeconds;
                        min.innerHTML = context.state.timerMinutes.toString().length === 1 ? '0' + context.state.timerMinutes : context.state.timerMinutes;
                    }
                }
            } else {
                var sec = document.getElementById('timerSeconds');
                if (sec) {
                    context.state.timerSeconds = ++context.state.timerSeconds;
                    sec.innerHTML = context.state.timerSeconds.toString().length === 1 ? '0' + context.state.timerSeconds : context.state.timerSeconds;
                }
            }
        }, 1000);
    }

    getTimerValue() {
        var sec = document.getElementById('timerSeconds');
        var min = document.getElementById('timerMinutes');
        var hr = document.getElementById('timerHours');
        return ((parseInt(hr.innerHTML) * 60 * 60) + (parseInt(min.innerHTML) * 60) + (parseInt(sec.innerHTML)));
    }

    questionChoiceSelected(evt) {

        console.log(evt, 'Choice selected.');
    }

    generateQuestionView(question, answerObj) {
        if (question.questionType.toLowerCase() === 'essay') {
            let defaultVal = answerObj && answerObj.studentAnswer && answerObj.studentAnswer.trim() !== 'empty-answer' ? answerObj.studentAnswer : '';
            question.question = question.question.replace('[[[LP_ANSWERS]]]', '');
            return <div dir lang={this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage} className="row" key={question.questionID} id="mainQuestionView">
                <div className="studentViewQuestionContainer">
                    <div className="col-md-4 col-sm-12 col-xs-7  hidden-md hidden-sm hidden-lg hidden-xs">
                        <div className="fltrghtress  ">
                            <a href="javascript:void 0" className="printres fltrghtress hidden-xs" onClick={this.print}> Print</a>
                            <a href="javascript:void 0" className="submitres fltrghtress hidden-xs" onClick={this.submit}> Submit</a>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <p>{htmlParser(question.question)}</p>
                    </div>
                    <div className="col-md-12">
                        <textarea placeholder="Enter your answer here." className="answerTextArea" id="answerTextArea"
                            defaultValue={defaultVal} />
                    </div>
                    <div className="row" />
                </div>
            </div>;

        } else {
            let choices = [];
            let defaultVal = answerObj && answerObj.studentAnswer ? answerObj.studentAnswer : '';
            let choicesString = '<div className="col-md-12 choicesText">';

            for (let i = 0; i < question.choices.length; i++) {
                let choice = question.choices[i];
                let isChecked = defaultVal === choice;
                choices.push(<div className="fieldBox" key={choice + '-' + i}>
                    <input type="radio" className="css-checkbox css-label1 bc_info card_side" value={choice}
                        id={"choice-" + i} name="choice" defaultChecked={isChecked} {...this.state.inputCheck} />
                    <label className="css-label radGroup1" htmlFor={"choice-" + i}
                        onClick={this.questionChoiceSelected}>{choice}</label>
                </div>);
                // string experiment.
                if (isChecked) {
                    // choicesString += '<div className="fieldBox" key="choice-' + i + '"><span style="vertical-align: top">' + (i + 1) + ') &nbsp;&nbsp; </span><input type="radio" className="css-checkbox css-label1 bc_info card_side"  id="choice-' + i + '" name="choice" checked /><label className="css-label radGroup1"  style="width:75%" for="choice-' + i + '">' + `<span dir lang=${this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage}>${choice}</span>` + '</label></div>';

                    choicesString += `<div className="fieldBox spacer" dir lang=${this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage} key="choice-` + i + '"><input type="radio" className="css-checkbox css-label1 bc_info card_side"  id="choice-' + i + '" name="choice" checked ' + this.state.inputCheck + '/><label lang className="css-label radGroup1" for="choice-' + i + '">' + (i + 1) + ') &nbsp;</label>' + `<span class="text-fix" dir lang=${this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage}>${choice}</span>` + '</div>';
                } else {
                    // choicesString += '<div className="fieldBox" key="choice-' + i + '"><span style="vertical-align: top">' + (i + 1) + ') &nbsp;&nbsp; </span><input type="radio" className="css-checkbox css-label1 bc_info card_side"  id="choice-' + i + '" name="choice" /><label className="css-label radGroup1" style="width:75%" for="choice-' + i + '">' + `<span dir lang=${this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage}>${choice}</span>` + '</label></div>';

                    choicesString += `<div className="fieldBox spacer" dir lang=${this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage} key="choice-` + i + '"><input type="radio" className="css-checkbox css-label1 bc_info card_side"  id="choice-' + i + '" name="choice" ' + this.state.inputCheck + '/><label lang className="css-label radGroup1" for="choice-' + i + '">' + (i + 1) + ') &nbsp;</label>' + `<span class="text-fix" dir lang=${this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage}>${choice}</span>` + '</div>';
                }
            }

            choicesString += '</div>';

            let showChoices = question.question.indexOf('[[[LP_ANSWERS]]]') > -1;
            let questionToDisplay = question.question;
            questionToDisplay = `<span dir lang=${this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage}>${questionToDisplay}</span>`
            if (showChoices) {
                questionToDisplay = questionToDisplay.replace('[[[LP_ANSWERS]]]', choicesString);
            }

            return <div dir lang={this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage} className="row" key={question.questionID} id="mainQuestionView">
                <div className="studentViewQuestionContainer">
                    <div className="col-md-4 col-sm-12 col-xs-7  hidden-md hidden-sm hidden-lg hidden-xs">
                        <div className="fltrghtress  ">
                            <a href="javascript:void 0" className="printres fltrghtress " onClick={this.print}> Print</a>
                            <a href="javascript:void 0" className="submitres fltrghtress" onClick={this.submit}> Submit</a>
                        </div>

                    </div>
                    <div className="col-md-12">
                        <p>{htmlParser(questionToDisplay)}</p>
                    </div>
                    {!showChoices && <div className="col-md-12">
                        {htmlParser(choicesString)}
                    </div>}
                    <div className="row" />
                </div>
            </div>;
        }
    }

    // generateQuestionView(question, answerObj) {
    //     if (question.questionType.toLowerCase() === 'essay') {
    //         let defaultVal = answerObj && answerObj.studentAnswer ? answerObj.studentAnswer : '';
    //         return <div className="row" key={question.questionID} id="mainQuestionView">
    //             <div className="col-md-8 col-sm-12 col-xs-5 ">
    //                 <h3>Question</h3>
    //             </div>
    //             <div className="col-md-4 col-sm-12 col-xs-7  hidden-md hidden-sm hidden-lg ">
    //
    //                 <div className="fltrghtress  ">
    //                     <a href="javascript:void 0" className="printres fltrghtress " onClick={this.print}> Print</a>
    //                     <a href="javascript:void 0" className="submitres fltrghtress" onClick={this.submit}> Submit</a>
    //                 </div>
    //
    //             </div>
    //             <div className="col-md-12">
    //                 <p>{htmlParser(question.question)}</p>
    //             </div>
    //             <div className="col-md-12">
    //                 <h3>Please write your answer below:</h3>
    //                 <textarea placeholder="Enter your answer here." className="answerTextArea" id="answerTextArea"
    //                           defaultValue={defaultVal}/>
    //             </div>
    //         </div>;
    //
    //     } else {
    //
    //         let choices = [];
    //         let defaultVal = answerObj && answerObj.studentAnswer ? answerObj.studentAnswer : '';
    //         let choicesString = '<div className="col-md-12"><h3>Choices:</h3>';
    //
    //         for (let i = 0; i < question.choices.length; i++) {
    //             let choice = question.choices[i];
    //             let isChecked = defaultVal === choice;
    //             choices.push(<div className="fieldBox" key={choice + '-' + i}>
    //                 <input type="radio" className="css-checkbox css-label1 bc_info card_side" value={choice}
    //                        id={"choice-" + i} name="choice" defaultChecked={isChecked}/>
    //                 <label className="css-label radGroup1" htmlFor={"choice-" + i}
    //                        onClick={this.questionChoiceSelected}>{choice}</label>
    //             </div>);
    //             // string experiment.
    //             if (isChecked) {
    //                 choicesString += '<div className="fieldBox" key="choice-' + i + '"><input type="radio" className="css-checkbox css-label1 bc_info card_side" value="' + choice + '" id="choice-' + i + '" name="choice" checked="true" aria-checked="true" /><label className="css-label radGroup1" for="choice-' + i + '">' + choice + '</label></div>';
    //             } else {
    //                 choicesString += '<div className="fieldBox" key="choice-' + i + '"><input type="radio" className="css-checkbox css-label1 bc_info card_side" value="' + choice + '" id="choice-' + i + '" name="choice" /><label className="css-label radGroup1" for="choice-' + i + '">' + choice + '</label></div>';
    //             }
    //         }
    //
    //         choicesString += '</div>';
    //
    //         let showChoices = question.question.indexOf('[[[LP_ANSWERS]]]') > -1;
    //         if (showChoices) {
    //             question.question = question.question.replace('[[[LP_ANSWERS]]]', choicesString);
    //         }
    //
    //         return <div className="row" key={question.questionID} id="mainQuestionView">
    //             <div className="col-md-8 col-sm-12 col-xs-5 ">
    //                 <h3>Question</h3>
    //             </div>
    //             <div className="col-md-4 col-sm-12 col-xs-7  hidden-md hidden-sm hidden-lg ">
    //
    //                 <div className="fltrghtress  ">
    //                     <a href="javascript:void 0" className="printres fltrghtress " onClick={this.print}> Print</a>
    //                     <a href="javascript:void 0" className="submitres fltrghtress" onClick={this.submit}> Submit</a>
    //                 </div>
    //
    //             </div>
    //             <div className="col-md-12">
    //                 <p>{htmlParser(question.question)}</p>
    //             </div>
    //             {!showChoices && <div className="col-md-12">
    //                 <h3>Choices:</h3>
    //                 {choices}
    //             </div>}
    //         </div>;
    //     }
    // }

    generateFlashcardView(flashcard, answerObj) {
        let currentActivity = this.state.currentActivity ? this.state.currentActivity : null;
        let words = this.state.unansweredFlashcards;

        return <div className="">
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3" style={{ float: 'right' }}>
                <div style={{ marginTop: "-43px" }} className="pull-right"><a style={{marginLeft: '10px'}}  className="submiAnswer imgHover"  onClick={() => {
                            browserHistory.push('/student/selfstudy');
                        }}><img src={cancel} alt="Cancel" height="30" width="30"></img><br />   Exit Activity</a></div>
                <div className="row">
                    <div className="brdgr">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-alignlft " style={{ padding: '0px' }}>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <span className="timerHeading">Elapsed Time:</span><br />
                                <span id="timer" style={{ color: '#0f6fb4' }}>
                                    <span id="timerHours">00</span>:<span id="timerMinutes">00</span>:<span id="timerSeconds">00</span></span>
                                <br /><br />
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-alignlft marginall">
                                <h3 className="ccard">Current Card: </h3>
                                <span
                                    className="bluetxta text-alignlft">
                                    {this.state.currentFlashcardIndex + 1} / {words.length}</span><br />
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-alignlft marginall">
                                <h3 className="sscore">Score:</h3>
                                <span
                                    className="bluetxta text-alignlft">
                                    {this.state.flashcardRight} / {words.length}</span>
                            </div>
                        </div>
                        {this.state.viewDefinition && <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-alignlft marginall"
                            style={{ padding: '0px' }}>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-alignlft  marginall">
                                <a className="greenbtn" href="javascript:void 0" onClick={function () {
                                    if ((this.state.currentFlashcardIndex + 1) === this.state.unansweredFlashcards.length) {
                                        this.end(true, true);
                                    } else {
                                        this.next(true);
                                    }
                                    // this.next(true);
                                    $('#flip-container').removeClass('flip');
                                    this.setState({ viewDefinition: false });
                                    if ((this.state.currentFlashcardIndex + 1) === words.length) {
                                        $('#i_was_right').removeClass('active');
                                        $('#i_was_wrong').removeClass('active');
                                        $('#i_was_right').addClass('active');
                                    }
                                }.bind(this)
                                } id="i_was_right">Next (I was right)</a>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-alignlft  marginall">
                                <a className="orngbtn" href="javascript:void 0" onClick={function () {
                                    this.next(false);
                                    $('#flip-container').removeClass('flip');
                                    this.setState({ viewDefinition: false });
                                    if ((this.state.currentFlashcardIndex + 1) === words.length) {
                                        $('#i_was_wrong').removeClass('active');
                                        $('#i_was_right').removeClass('active');
                                        $('#i_was_wrong').addClass('active');
                                    }
                                }.bind(this)
                                } id="i_was_wrong">Next (I was wrong)</a>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 flip-container" id="flip-container">
                <div className="flipper">
                    <div className="brdrnam front" style={{ padding: '0px' }}>
                        <div className="eleRows">
                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 headbar-fix" style={{ display: "flex" }}>{this.getTranslationButton(null, flashcard)}</div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-center">
                                <span className="headblue">Word {this.state.currentFlashcardIndex + 1}</span>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                <button className="addActivityBtn btnnotm flipbtntxt" onClick={() => {
                                    this.setState({ viewDefinition: true });
                                    $('#flip-container').toggleClass('flip');
                                }} />
                            </div>
                        </div>
                        <p className="cntrfonbig"><div dir lang={this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage}><div className="flashcard-word">{flashcard.translatedWord ? flashcard.translatedWord : flashcard.word}</div></div></p>
                    </div>

                    <div className="brdrnam back" style={{ padding: '0px' }}>
                        <div className="eleRows">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 headbar-fix" style={{ display: "flex" }}>{this.getTranslationButton(null, flashcard)}</div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-center">
                                <div dir lang={this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage} className="centered-text"> <span className="headblue">{flashcard.translatedWord ? flashcard.translatedWord : flashcard.word}</span></div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                <button className="addActivityBtn btnnotm flipbtntxt" onClick={() => {
                                    this.setState({ viewDefinition: true });
                                    $('#flip-container').toggleClass('flip');
                                }} />
                            </div>
                        </div>
                        <p className="cntrfonbig"><div dir lang={this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage} className="centered-text"><div className="fdef">{flashcard.wordDefinition}</div></div></p>
                    </div>
                </div>
            </div>

        </div >;
    }

    generateLearningView(flashcard) {
        let currentActivity = this.state.currentActivity ? this.state.currentActivity : null;
        if (currentActivity.documentType.toLowerCase() === 'url') {
            return <div className="">
                <div className="eleRow hidden-xs" style={{ paddingTop: '0px' }}>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-alignlft">
                        <h3 style={{ marginTop: '5px' }}>Learning </h3>
                    </div>
                </div>
                <div className="container-fluid questionMainBlock">
                    <div className="quesAndIcon">
                        <div className="row">
                            <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 text-alignlft">
                                <a href={currentActivity.documentLink} target="_blank">
                                    <span className="imgurl"><img src={url} /></span>
                                    <span className="bluetxta text-alignlft">{currentActivity.activityName}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>;
        } else {
            return <div className="">
                <div className="eleRow hidden-xs" style={{ paddingTop: '0px' }}>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-alignlft">
                        <h3 style={{ marginTop: '5px' }}>Learning </h3>
                    </div>
                </div>
                <div className="container-fluid questionMainBlock">
                    <div className="quesAndIcon">
                        <div className="row">
                            <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 text-alignlft">
                                <a href={currentActivity.documentLink} target="_blank">
                                    <span className="imgurl"><img src={pdf} /></span>
                                    <span className="bluetxta text-alignlft">{currentActivity.fileName}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>;
        }
    }

    getMainContent(contentView, obj, answerObj) {

        if (this.state.view === 'activity') {
            if (contentView === 'question') {
                return (
                    this.generateQuestionView(obj, answerObj)
                )
            } else if (contentView === 'flashcard') {
                return (
                    this.generateFlashcardView(obj, answerObj)
                )
            } else {
                return <div className="row" key={'no_activity_selected'} id="mainQuestionView">
                    <div id="no_activity_selected">
                        <p>
                            Please select an Activity from side bar.
                        </p>
                    </div>
                </div>;
            }
        } else if (this.state.view === 'course') {
            if (contentView === 'question') {
                return (
                    this.generateQuestionView(obj, answerObj)
                )
            } else if (contentView === 'flashcard') {
                return (
                    this.generateFlashcardView(obj, answerObj)
                )
            } else {
                return <div className="row" key={'no_activity_selected'} id="mainQuestionView">
                    <div id="no_activity_selected">
                        <p>
                            Please select an Activity from side bar.
                        </p>
                    </div>
                </div>;
                /* // learning
                 return (
                 this.generateLearningView(obj)
                 )*/
            }
        } else {
            return <div className="row" key={'no_activity_selected'} id="mainQuestionView">
                <div id="no_activity_selected">
                    <p>
                        Please select an Activity from side bar.
                    </p>
                </div>
            </div>;
        }
    }

    toggleTranslate(ques, flashcard) {
        if (ques) {
            if (this.state.seeTranslation && this.state.seeEnglish) {
                if (this.state.isTranslated) {
                    // $(".langConfigured").prop('lang', `${this.props.auth.user.prefLanguage}`);
                    ques.explanation = this.state.seeTranslation.explanation
                    ques.answer = this.state.seeTranslation.answer
                    ques.choices = this.state.seeTranslation.choices
                    ques.question = this.state.seeTranslation.question
                    ques.hint = this.state.seeTranslation.hint
                } else {
                    // $(".langConfigured").prop('lang', 'en');
                    ques.explanation = this.state.seeEnglish.explanation
                    ques.answer = this.state.seeEnglish.answer
                    ques.choices = this.state.seeEnglish.choices
                    ques.question = this.state.seeEnglish.question
                    ques.hint = this.state.seeEnglish.hint
                }
                this.setState({ isTranslated: !this.state.isTranslated });
            } else {
                this.showIndicator();
                this.setState({ isTranslated: true, 'seeTranslation': JSON.parse(JSON.stringify(ques)) }, () => {
                    const params = {
                        questionid: ques.questionID,
                        isAssessment: !this.state.isPractice
                    };
                    const method = 'GET';
                    const pathTemplate = '/getQuestionById/{questionid}/{isAssessment}';
                    apiClient.invokeApi(params, pathTemplate, method, {}, {}, 6).then((result) => {
                        // $(`:lang(${this.props.auth.user.prefLanguage})`).addClass('langConfigured').prop('lang', 'en');
                        const { question, explanation, answer, hint, choices } = result.data;
                        ques.explanation = result.data.explanation;
                        ques.answer = answer ? answer : "Translation not avaliable"
                        ques.question = question ? question : "Translation not avaliable"//ques.question;
                        ques.choices = choices ? choices : "Translation not avaliable"//ques.choices;
                        ques.explanation = explanation ? explanation : "Translation not avaliable"//ques.explanation;
                        ques.hint = hint ? hint : "Translation not avaliable"//ques.hint;
                        this.setState({ seeEnglish: result.data, tempAnswer: result.data.answer });
                        this.hideIndicator();
                    }, (err) => {
                        console.error(err);
                        this.setState({
                            showAlert: true,
                            alertStyle: 'danger',
                            alertText: 'Cannot translate at the moment. Please try again later.'
                        });
                        setTimeout(() => {
                            this.setState({ showAlert: false });
                        }, 5000);
                        this.hideIndicator();
                    });
                });
            }
        }
        if (flashcard) {
            //
            if (this.state.seeTranslation && this.state.seeEnglish) {

                if (this.state.isTranslated) {
                    // $(".langConfigured").prop('lang', `${this.props.auth.user.prefLanguage}`);
                    flashcard.translatedWord = this.state.seeTranslation.translatedWord
                    flashcard.wordDefinition = this.state.seeTranslation.wordDefinition
                } else {
                    // $(".langConfigured").prop('lang', 'en');
                    flashcard.translatedWord = this.state.seeEnglish.word
                    flashcard.wordDefinition = this.state.seeEnglish.wordDefinition
                }
                this.setState({ isTranslated: !this.state.isTranslated });
            } else {
                this.showIndicator();
                this.setState({ isTranslated: true, 'seeTranslation': JSON.parse(JSON.stringify(flashcard)) }, () => {
                    const params = {
                        flashcardId: flashcard.word,
                    };
                    const method = 'GET';
                    const pathTemplate = '/getFlashcardById/{flashcardId}';
                    apiClient.invokeApi(params, pathTemplate, method, {}, {}, 6).then((result) => {
                        // $(`:lang(${this.props.auth.user.prefLanguage})`).addClass('langConfigured').prop('lang', 'en');
                        const { word, wordDefinition } = result.data;
                        // ques.explanation = result.data.explanation;
                        flashcard.translatedWord = word ? word : "Translation not avaliable"
                        flashcard.wordDefinition = wordDefinition ? wordDefinition : "Translation not avaliable"
                        this.setState({ seeEnglish: result.data });
                        this.hideIndicator();
                    }, (err) => {
                        console.error(err);
                        this.setState({
                            showAlert: true,
                            alertStyle: 'danger',
                            alertText: 'Cannot translate at the moment. Please try again later.'
                        });
                        setTimeout(() => {
                            this.setState({ showAlert: false });
                        }, 5000);
                        this.hideIndicator();
                    });
                });
            }
        }
    }

    handleAlertDismiss(e) {
        this.setState({ 'showAlert': false });
    }

    getMainContentTopHeading() {
        const { dispatch } = this.props;

        // set heading, timer and breadcrumbs. for type questions or flashcard or learning.
        let that = this;
        let currentQuestion = that.state.unansweredQuestions[that.state.currentQuestionIndex];
        let currentActivity = this.state.currentActivity ? this.state.currentActivity : null;
        let currentUnit = this.state.currentUnit ? this.state.currentUnit : null;
        let isPractice = currentActivity && currentActivity.assessmentType && currentActivity.assessmentType.toLowerCase() === 'practice';
        let course = this.state.assignment && this.state.assignment.assignedCourses ? this.state.assignment.assignedCourses : null;

        if (this.state.view === 'activity') {
            return this.state.currentActivityComplete === false ? <div className="eleRow hidden-xs">

                <div className="ccol-lg-3 col-md-2 col-sm-2 col-xs-6 text-left">
                    <h3><b>{this.params.id}</b> </h3>
                </div>

                {this.state.contentView === 'question' && <div className="col-lg-3 col-md-3 col-sm-2 col-xs-3 text-center">
                    <h3><b>Current Question:&nbsp;
                        <span style={{ color: '#0f6fb4' }}>{this.state.currentQuestionIndex + 1} / {this.state.unansweredQuestions.length}</span>
                    </b></h3>
                </div>}

                <div className="col-lg-4 col-md-5 col-sm-6 col-xs-5">
                    <h3 className="quesNum"><b>
                        <span className="timerHeading">Elapsed Time:</span>
                        <span id="timer" style={{ color: '#0f6fb4' }}>&nbsp;&nbsp;
                            <span id="timerHours">00</span>:<span id="timerMinutes">00</span>:<span id="timerSeconds">00</span></span>
                    </b> </h3>
                </div>
                <div className="col-lg-1 col-md-1 col-sm-1 col-xs-2">
                    {this.getTranslationButton(currentQuestion, this.state.isTranslated)}
                </div>
                <div className="col-lg-1 col-md-1 col-sm-1 col-xs-2 pull-right"> <a style={{ marginTop: '-7px' }} className="submitAnswer imgHover"  onClick={() => {
                            browserHistory.push('/student/selfstudy');
                        }}><img src={cancel} alt="Cancel" height="27" width="29"></img><br />Exit Activity</a></div>

            </div> : null;
        } else {
            return this.state.currentActivityComplete === false ? <div className="eleRow hidden-xs">

                <div className="ccol-lg-4 col-md-4 col-sm-4 col-xs-6 text-left">
                    <h3><b>{currentActivity.activityName}</b>
                    </h3>
                </div>

                {this.state.contentView === 'question' && <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 text-center">
                    <h3>Current Question:
                    <b><span style={{ color: '#0f6fb4' }}>{this.state.currentQuestionIndex + 1} / {currentActivity.questions.length}</span>
                        </b></h3>
                </div>}

                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 text-center">
                    <h3 className="quesNum"><b>Elapsed Time:
                        <span style={{ color: '#0f6fb4' }}>
                            {this.state.timerHours.toString().length === 1 && '0'}{this.state.timerHours}:{this.state.timerMinutes.toString().length === 1 && '0'}{this.state.timerMinutes}:{this.state.timerSeconds.toString().length === 1 && '0'}{this.state.timerSeconds}
                        </span></b>
                    </h3>
                </div>
                <div className="col-lg-1 col-md-1 col-sm-1 col-xs-3 pull-right">
                    {this.getTranslationButton(currentQuestion, this.state.isTranslated)}
                </div>
            </div> : null;
        }
    }

    audioHandler(e) {
        e.target.parentElement.previousElementSibling.currentTime = 0;
        e.target.parentElement.previousElementSibling.play();
    }

    getTranslationButton(currentQuestion, flashcard) {
        if (currentQuestion) {
            return (<span className={classnames("toggle-en btn btn-primary", { "hidden": !this.props.auth.user.multiLingualLicense || !this.props.auth.user.prefLanguage || (this.props.auth.user.prefLanguage && this.props.auth.user.prefLanguage === 'en') })} onClick={() => { this.toggleTranslate(currentQuestion, null) }}> <MaterialIcon icon="translate" size={16} /> {!this.state.isTranslated ? "See English" : "See Translation"}</span>)
        }
        if (flashcard) {
            return (<span className={classnames("toggle-en btn btn-primary", { "hidden": !this.props.auth.user.multiLingualLicense || !this.props.auth.user.prefLanguage || (this.props.auth.user.prefLanguage && this.props.auth.user.prefLanguage === 'en') })} onClick={() => { this.toggleTranslate(null, flashcard) }}> <MaterialIcon icon="translate" size={16} /> {!this.state.isTranslated ? "See English" : "See Translation"}</span>)
        }
    }

    nextQuestion(currentQuestion) {
        let that = this;
        this.setState({ disableCheck: true });
        this.setState({ inputCheck: "" });
        this.setState({ disableReAns: true });
        this.setState({ submitAnswerBtn: true });
        $("#contentPanel").animate({ scrollTop: 0 }, "fast");
        document.getElementById('drop_shadow_check_ans').style.display = 'none';
        document.querySelector('.container-fluid.noPad.listMainBoxWrapper').style.overflow = 'auto';
        // currentQuestion.explanation = this.state.seeTranslation ? this.state.seeTranslation.explanation : currentQuestion.explanation;
        // currentQuestion.answer = this.state.seeTranslation ? this.state.seeTranslation.answer : currentQuestion.answer;
        this.setOriginalQuestion(currentQuestion);
        that.setState({ selectedTab: "question", rating: 0, isTranslated: false, seeEnglish: null, seeTranslation: null });
        if (that.state.currentActivity && that.state.currentActivity.questions.length - that.state.currentQuestionIndex === 1) {
            setTimeout(function () {
                that.setState({ selectedTab: "question" });
            }, 200);
            that.end(true);
        } else {
            that.next();
        }
    }


    getCheckAnswerDialog() {
        const { screenWidth } = this.state;
        const isMobile = screenWidth < 768;
        let currentQuestion = this.state.unansweredQuestions && this.state.unansweredQuestions.length > 0 ? this.state.unansweredQuestions[this.state.currentQuestionIndex] : {};
        let checked = [...document.querySelectorAll('#mainQuestionView input:checked')];
        let isCorrect = false;
        
        if (currentQuestion && currentQuestion.questionType !== 'essay') {
            if (checked && checked.length > 0) {
                // let studentAnswer = checked[0].nextElementSibling.firstElementChild.innerHTML;
                let studentAnswer = checked[0].nextElementSibling.nextElementSibling.innerHTML;
                let condans1 = studentAnswer;
                let condans2 = currentQuestion.answer;
                condans2 = condans2.replace(/ /g, "").replace(/(style.*?;")/gi, "").trim();
                condans1 = condans1.replace(/ /g, "").replace(/(style.*?;")/gi, "").trim();
                if (currentQuestion.answer) {
                    if (htmlParser(condans2) === htmlParser(condans1)) {
                        isCorrect = true;
                    }
                    else if (htmlParser(this.state.tempAnswer) === htmlParser(condans1)) {
                        isCorrect = true;
                    }
                    else if (condans2.replace(/\"/g, "").replace(/ /g, "").replace(/<!--.*?-->/g, "").replace(/(style.*?;")/gi, "").trim().toLowerCase() === condans1.replace(/\"/g, "").replace(/ /g, "").replace(/<!--.*?-->/g, "").replace(/(style.*?;")/gi, "").trim().toLowerCase()) {
                        isCorrect = true;
                    }
                    else if (this.state.seeTranslation && htmlParser(this.state.seeTranslation.answer) === htmlParser(condans1)) {
                        isCorrect = true;
                    }
                    else if (this.state.seeTranslation && this.state.seeTranslation.answer.replace(/\"/g, "").replace(/ /g, "").replace(/<!--.*?-->/g, "").toLowerCase() === condans1.replace(/\"/g, "").replace(/ /g, "").replace(/<!--.*?-->/g, "").toLowerCase()) {
                        isCorrect = true;
                    }
                    else if (this.state.seeEnglish && htmlParser(this.state.seeEnglish.answer) === htmlParser(condans1)) {
                        isCorrect = true;
                    }
                    else if (this.state.seeEnglish && this.state.seeEnglish.answer.replace(/\"/g, "").replace(/ /g, "").replace(/<!--.*?-->/g, "").toLowerCase() === condans1.replace(/\"/g, "").replace(/ /g, "").replace(/<!--.*?-->/g, "").toLowerCase()) {
                        isCorrect = true;
                    }
                    else {
                        isCorrect = false;
                    }

                    // // else if (this.state.seeTranslation && htmlParser(this.state.seeTranslation.answer) === htmlParser(studentAnswer))
                    // else if (currentQuestion.answer.replace(/\"/g, "").replace(/ /g, "").replace(/<!--.*?-->/g, "").toLowerCase() === this.state.seeTranslation && htmlParser(this.state.seeTranslation.answer)) {
                    //     isCorrect = true;
                    // }
                    // else if (this.state.seeTranslation && this.state.seeTranslation.answer.replace(/\"/g, "").replace(/ /g, "").replace(/<!--.*?-->/g, "").toLowerCase() === currentQuestion.answer.replace(/\"/g, "").replace(/ /g, "").replace(/<!--.*?-->/g, "").toLowerCase()) {
                    //     isCorrect = true;
                    // }
                }
            }
        }



        let audioSource;
        let audio = "";

        // let removeBrtag = currentQuestion.explanation.replace(/<br\/><br\/>/g || /<br\/>\s<br\/>/g, "<br/>");

        // currentQuestion.explanation = removeBrtag;

        if (currentQuestion.explanation && currentQuestion.explanation.indexOf("</audio>") != -1) {
            // currentQuestion.explanation = (currentQuestion.explanation.replace("<audio controls", "<audio id = 'player'")) || (currentQuestion.explanation.replace("<audio autoplay", "<audio id = 'player'")) ;
            // currentQuestion.explanation = currentQuestion.explanation.replace("autoplay", "");
            currentQuestion.explanation = currentQuestion.explanation.replace("<audio", "<audio id = 'player'");
            currentQuestion.explanation = currentQuestion.explanation.replace("controls", "");
            currentQuestion.explanation = currentQuestion.explanation.replace("autoplay", "");
            let tempDiv = document.createElement('div');
            tempDiv.innerHTML = currentQuestion.explanation;
            console.dir(tempDiv.innerHTML);
            audioSource = tempDiv.getElementsByTagName('source') && tempDiv.getElementsByTagName('source')[0] && tempDiv.getElementsByTagName('source')[0].src;
            console.log("Source --> ", audioSource);
            audio = audioSource;
        }

        let body = <div>
            <p className="cntrfon">
                <div dir lang={this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage}>{htmlParser(currentQuestion.explanation)}</div>
                {currentQuestion.explanation && currentQuestion.explanation.indexOf('</audio') != -1 && audio ?
                    <div className="audioplayerHeight">
                        <AudioPlayer src={audio} autoPlay={true} />
                    </div> : ""
                }
            </p>
        </div>;

        let width = window.innerWidth / 2;
        let height = window.innerHeight / 2;
        let left = width * -1;

        // var liClasses = classNames({
        //     'main-class': true,
        //     'activeClass': self.state.focused === index
        //   });

        let handleClassName = "handle explanation_box";
        const isTab = screenWidth <= 1199;
        // let screenHeight = window.innerHeight
        // let check1 = screenHeight <= 850
        // let check2 = screenHeight <= 700
        // let check3 = screenHeight <= 650
        // console.log(check1)
        // transform: check1 ? "translate(0, -70px) scale(0.7)" : check2 ? "translate(0, -90px) scale(0.6)" : check3 ? "translate(0, -90px) scale(0.5)" : "scale(1)"}}
        // screenHeight <= 850 ? this.setState({value: 0.5}) : null
        // console.log(this.state.value)

        if (isTab) {
            handleClassName = "explanation_box"
        }
        if (isCorrect) {
            this.state.ansCheck = true;
        }
        else {
            this.state.ansCheck = false;
        }
        return (
            <div className="quesAndIconStudent">
                {/* <div className="container-fluid questionMainBlock"> */}
                <div className="topDiv">

                    {/* <Button onClick={() => {
                            let that = this;
                            document.getElementById('drop_shadow_check_ans').style.display = 'none';
                            document.querySelector('.container-fluid.noPad.listMainBoxWrapper').style.overflow = 'auto';
                            currentQuestion.explanation = this.state.seeTranslation ? this.state.seeTranslation.explanation : currentQuestion.explanation;
                            currentQuestion.answer = this.state.seeTranslation ? this.state.seeTranslation.answer : currentQuestion.answer;
                            if (that.state.isTranslated)
                                that.toggleTranslate(currentQuestion);
                            that.setState({ showNextDialog: false, rating: 0, isTranslated: false, seeEnglish: null, seeTranslation: null });
                            if (that.state.currentActivity && that.state.currentActivity.questions.length - that.state.currentQuestionIndex === 1) {
                                setTimeout(function () {
                                    that.setState({ showNextDialog: false });
                                }, 200);
                                that.end(true);
                            } else {
                                that.next();
                            }
                        }} bsStyle='primary' style={{ color: 'white', backgroundColor: 'transparent', borderColor: 'transparent', border: "none" }} id="cross-button">X</Button> */}
                    {/* {!isCorrect && !isMobile && <span className="eleRowspoporng wrongAnswer" style={{ paddingLeft: '20px', paddingTop: '0px', height: 'auto', color: '#e5e5e5', width: '100%', fontWeight: '500', fontSize: '18px', fontFamily: 'Verdana, Geneva, sans-serif' }}><span className="panel-heading-top"><i className="material-icons" style={{ position: 'relative', top: '-3px', right: '5px' }}>cancel</i>Incorrect Answer <br /><span className="eleRowspopcrt" style={{ color: 'white', fontWeight: '100', position: "relative", bottom: "4px" }} title={currentQuestion.answer}>Correct Answer is: <span dir lang={this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage}>{htmlParser(currentQuestion.answer)}</span></span></span></span>} */}
                    {!isCorrect && isMobile && <span className="eleRowspoporng wrongAnswer" style={{ paddingLeft: '20px', paddingTop: '0px', height: 'auto', color: '#e5e5e5', width: '100%', fontWeight: '500', fontSize: '18px', fontFamily: 'Verdana, Geneva, sans-serif' }}><span className="panel-heading-top"><i className="material-icons" style={{ position: 'relative', top: '-3px', right: '5px' }}>cancel</i>Incorrect Answer <br /><span className="eleRowspopcrt" style={{ color: 'white', fontWeight: '100', position: "relative", bottom: "4px" }} title={currentQuestion.answer}>Correct Answer is: <span dir lang={this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage}>{htmlParser(currentQuestion.answer)}</span></span></span></span>}
                    {isCorrect && isMobile && <span className="eleRowspoporng" style={{ paddingLeft: '20px', paddingTop: '0px', background: '#2D63B4', width: '100%', color: 'white', fontWeight: '500', fontSize: '18px', fontFamily: 'Verdana, Geneva, sans-serif', border: "none" /* , paddingBottom : '20px' */ }}><span className="panel-heading-top"><i className="material-icons" style={{ position: 'relative', top: '-3px', right: '5px', color: 'white' }} >check_circle</i>Correct Answer!</span></span>}


                    {/* {!isCorrect && <span className="eleRowspoporng" style={{ paddingLeft: '20px', paddingTop: '25px', color: '#e5e5e5' , background: '#F44336', width: '100%' , fontWeight : '500' ,  fontSize : '18px', fontFamily: 'Verdana, Geneva, sans-serif'}}><span className = "panel-heading-top"><i className="material-icons" style = {{ position : 'relative' , top : '-3px' , right : '5px' }}>cancel</i>Incorrect Answer <br/><span className="eleRowspopcrt" style = {{color: 'white' , fontWeight : '100', position : "relative" , bottom : "4px" }} title={currentQuestion.answer}>Correct Answer is: {htmlParser(currentQuestion.answer)}</span></span></span>
                            }
                    {isCorrect && <span className="eleRowspop" style={{ paddingLeft: '20px', paddingTop: '25px',  background: '#2D63B4', width: '100%' , color : 'white' , fontWeight : 200 + 'px !important' , fontSize : '18px !important' , border : "none" , paddingBottom : '20px' }}><span className = "panel-heading-top"><i className="material-icons" style = {{ position : 'relative' , top : '-3px' , right : '5px' , color: 'white' }} >check_circle</i>Correct Answer!</span></span>} */}


                </div>
                <div className="my">
                    {/* <div className="body" style={{ marginBottom: '50px', marginTop: '20px' }}> */}
                    <div className="body">
                        {body}
                    </div>
                </div>
                <div className="lastQuesRedirectStudent">
                    <div className="text-left footer-fix" style={{
                        padding: '0px',
                        height: '10px',
                        marginLeft: '20px',
                        position: 'relative',
                        paddingTop: '5px',
                        marginright: '14px',
                        fontSize: "16px !important",

                    }} id="checkAnsFooter">How would you rate this explanation?
                        <Rater style={{ marginLeft: "20px" }} rating={this.state.currentRating} onRate={this.onRate} />
                        {/* <Button className="pull-right" onClick={() => {
                                let that = this;
                                document.getElementById('drop_shadow_check_ans').style.display = 'none';
                                document.querySelector('.container-fluid.noPad.listMainBoxWrapper').style.overflow = 'auto';
                                currentQuestion.explanation = this.state.seeTranslation ? this.state.seeTranslation.explanation : currentQuestion.explanation;
                                currentQuestion.answer = this.state.seeTranslation ? this.state.seeTranslation.answer : currentQuestion.answer;
                                if (that.state.isTranslated)
                                    that.toggleTranslate(currentQuestion);
                                that.setState({ showNextDialog: false, rating: 0, isTranslated: false, seeEnglish: null, seeTranslation: null });
                                if (that.state.currentActivity && that.state.currentActivity.questions.length - that.state.currentQuestionIndex === 1) {
                                    setTimeout(function () {
                                        that.setState({ showNextDialog: false });
                                    }, 200);
                                    that.end(true);
                                } else {
                                    that.next();
                                }
                            }} bsStyle='primary' id="next-question-button">Next Question</Button> */}
                        <Button className="pull-right" onClick={this.nextQuestion} bsStyle='primary' id="next-question-button">Next Question</Button>
                        {this.getTranslationButton(currentQuestion, isCorrect)}
                    </div>
                </div>
            </div>
        );

        // return <Modal id="check_answer_modal"
        //               backdrop="static"
        //               show={this.state.showNextDialog}
        //               onHide={()=> {
        //                   let that = this;
        //                   setTimeout(function () {
        //                       that.setState({showNextDialog: false});
        //                   }, 200);
        //                   this.next();
        //                   this.setState({currentRating:0});
        //               }}
        //               container={this}
        //               aria-labelledby="contained-modal-title">
        //     <Modal.Header closeButton>
        //         {!isCorrect && <span className="eleRowspoporng" >Incorrect Answer - <span className="eleRowspop"  style={{display: 'inline'}}>Correct Answer is: {htmlParser(currentQuestion.answer)}</span></span>}
        //         {isCorrect && <span className="eleRowspop"  >Correct Answer!</span>}
        //     </Modal.Header>
        //     <Modal.Body>
        //         {body}
        //     </Modal.Body>
        //     <Modal.Footer>
        //         <div className="col-md-8 text-left">How would you rate this explanation?
        //             <Rater rating={this.state.currentRating} onRate={this.onRate} />
        //         </div>
        //         <div className="col-md-4 text-right">
        //             <Button onClick={()=> {
        //                 let that = this;
        //                 if (this.state.currentQuestionIndex === (this.state.unansweredQuestions.length - 1)) {
        //                     setTimeout(function () {
        //                         that.setState({showNextDialog: false, rating:0});
        //                     }, 200);
        //                     this.end(true);
        //                 } else {
        //                     that.setState({showNextDialog: false});
        //                     this.next();
        //                 }
        //                 // Next question.
        //             }} bsStyle='primary'>Next Question</Button>
        //         </div>
        //     </Modal.Footer>
        // </Modal>;
    }

    setOriginalQuestion(currentQuestion) {
        currentQuestion.explanation = this.state.seeTranslation ? this.state.seeTranslation.explanation : currentQuestion.explanation
        currentQuestion.answer = this.state.seeTranslation ? this.state.seeTranslation.answer : currentQuestion.answer
        currentQuestion.choices = this.state.seeTranslation ? this.state.seeTranslation.choices : currentQuestion.choices
        currentQuestion.question = this.state.seeTranslation ? this.state.seeTranslation.question : currentQuestion.question
        currentQuestion.hint = this.state.seeTranslation ? this.state.seeTranslation.hint : currentQuestion.hint
    }
    setOriginalFlashcard(flashcard) {
        flashcard.translatedWord = this.state.seeTranslation ? this.state.seeTranslation.translatedWord : flashcard.translatedWord
        flashcard.wordDefinition = this.state.seeTranslation ? this.state.seeTranslation.wordDefinition : flashcard.wordDefinition
    }

    setQuestionAndClearTranslateState(currentQuestion) {
        if (currentQuestion) {
            this.setOriginalQuestion(currentQuestion);
            this.setState({ isTranslated: false, seeEnglish: null, seeTranslation: null });
        }
    }

    resetFlashcard(flashcard) {
        if (flashcard) {
            this.setOriginalFlashcard(flashcard);
            this.setState({ isTranslated: false, seeEnglish: null, seeTranslation: null });
        }
    }

    onRate(rate) {
        if (rate.type === 'click') {

            let body = {
                studentID: this.props.auth.user.userName,
                questionID: this.state.currentActivity && this.state.currentActivity.questions ? this.state.currentActivity.questions[this.state.currentQuestionIndex].questionID : this.state.unansweredQuestions[this.state.currentQuestionIndex].questionID,
                rating: rate.rating.toString()
            };
            this.setState({ currentRating: rate.rating });
            apiClient.invokeApi({}, '/question/rating', 'PUT', {}, body, '3').then(function (result) {
            });
        }
    }

    getHintDialog() {

        let currentQuestion = this.state.unansweredQuestions && this.state.unansweredQuestions.length > 0 ? this.state.unansweredQuestions[this.state.currentQuestionIndex] : {};
        let body = <div>
            <div className="infoIcon">
                <img src={hint} style={{ height: '80px' }} />
            </div>
            <div style={{ display: 'inline-block', width: '80%' }}>
                <span dir lang={this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage}>{htmlParser(currentQuestion.hint)}</span>
            </div>
        </div>;

        return <Modal id="hint_modal"
            show={this.state.showHintDialog}
            onHide={() => {
                this.setState({ showHintDialog: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Question Hint</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showHintDialog: false });
                }} bsStyle='primary'>OK</Button>
            </Modal.Footer>
        </Modal>;
    }

    getCountDialog() {
        const { dispatch } = this.props;
        let label = this.state.contentView === 'question' ? 'Questions' : 'Flashcards';
        let body = <div>
            <p>
                <div>
                    <select id="no_of_questions_to_practice" defaultValue="10">
                        <option value="10">10 {label}</option>
                        <option value="15">15 {label}</option>
                        <option value="20">20 {label}</option>
                        <option value="25">25 {label}</option>
                        <option value="30">30 {label}</option>
                        <option value="35">35 {label}</option>
                        <option value="40">40 {label}</option>
                        <option value="45">45 {label}</option>
                        <option value="50">50 {label}</option>
                        <option value="55">55 {label}</option>
                        <option value="60">60 {label}</option>
                    </select>
                </div>
            </p>
            <p>
                <div className="fieldBox" style={{ paddingLeft: '29px' }}>
                    <input type="checkbox" value='wrong-questions' id="no_of_questions_to_practice_checkbox"
                        className="css-checkbox" name="no_of_questions_to_practice_checkbox" />
                    <label htmlFor="no_of_questions_to_practice_checkbox" className="pay_label css-label1">Practice&nbsp;
                        {label.toLowerCase()} I got wrong.</label>
                </div>
            </p>
        </div>;

        return <Modal id="count_modal"
            show={this.state.showCountDialog}
            onHide={() => {
                this.setState({ showCountDialog: false, showCountCategory: null, showCountSubCategory: null });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">{(this.state.contentView + 's').toUpperCase()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={function () {
                    this.showIndicator();
                    let context = this;
                    let count = $("#no_of_questions_to_practice").val() ? parseInt($("#no_of_questions_to_practice").val()) : 10;
                    let wrong = document.querySelector("#no_of_questions_to_practice_checkbox").checked;
                    let pathTemplate = null;
                    let params = null;
                    let apiPostFix = '3';
                    if (context.state.contentView === 'question') {
                        pathTemplate = '/question/student/{studentID}/category/{category}/subcategory/{subcategory}/type/{activityType}/subject/{subject}/count/{count}';
                        params = {
                            "category": this.state.showCountCategory,
                            "subcategory": this.state.showCountSubCategory,
                            "subject": this.params.id,
                            "count": count,
                            "activityType": this.state.view,
                            "studentID": this.props.auth.user.userName
                        };
                    } else {
                        apiPostFix = '3';
                        pathTemplate = '/words/student/{studentID}/category/{category}/type/{activityType}/subject/{subject}/count/{count}';
                        params = {
                            "category": this.state.showCountCategory,
                            "subject": this.params.id,
                            // "subject": "U.S. History and Government",
                            "count": count,
                            "activityType": this.state.view,
                            "studentID": this.props.auth.user.userName
                        };
                    }

                    apiClient.invokeApi(params, pathTemplate, 'GET', {}, null, apiPostFix).then(function (result) {

                        if (context.state.contentView === 'question') {
                            context.state.flashcardActivityResults.clear();
                            context.state.questionActivityResults.clear();
                            context.setState({
                                showCountDialog: false,
                                showCountCategory: null,
                                showCountSubCategory: null,
                                loading: false,
                                unansweredQuestions: result && result.data ? result.data : [],
                                currentCategoryName: context.state.showCountCategory,
                                currentSubCategoryName: context.state.showCountSubCategory,
                                currentActivityComplete: false,
                                currentQuestionIndex: 0,
                                questionActivityResults: new Map(),
                                correctAnsweredQuestions: [],
                                wrongAnsweredQuestions: [],
                                flashcardRight: 0,
                                disableCheck: true, inputCheck: "", selectedTab: "question", disableReAns: true
                            });
                        } else if (context.state.contentView === 'flashcard') {
                            context.state.flashcardActivityResults.clear();
                            context.state.questionActivityResults.clear();
                            context.setState({
                                isTranslated: false,
                                seeTranslation: "",
                                seeEnglish: "",
                                showCountDialog: false,
                                showCountCategory: null,
                                showCountSubCategory: null,
                                currentCategoryName: context.state.showCountCategory,
                                loading: false,
                                unansweredFlashcards: result && result.data ? result.data : [],
                                currentActivityComplete: false,
                                currentFlashcardIndex: 0,
                                questionFlashcardResults: new Map(),
                                flashcardActivityResults: new Map(),
                                correctAnsweredQuestions: [],
                                wrongAnsweredQuestions: [],
                                flashcardRight: 0
                            });
                        }
                        for (var i = 1; i < 99999; i++) { window.clearInterval(i); }
                        context.expandCollapseSideBar();
                        context.setState({ timerSeconds: 0, timerMinutes: 0, timerHours: 0, timerSet: true }, context.setTimer);
                        dispatch({
                            type: SET_FULLSCREEN
                        });
                    }).catch(function (error) {
                        context.setState({
                            showCountDialog: false,
                            showCountCategory: null,
                            showCountSubCategory: null,
                            loading: false,
                            disableCheck: true, inputCheck: "", selectedTab: "question"
                        });
                    });
                }.bind(this)} bsStyle='primary'>Start</Button>
            </Modal.Footer>
        </Modal>;
    }

    getKeywordSearchCountDialog() {

        let label = this.state.contentView === 'question' ? 'Questions' : 'Flashcards';
        let body = <div>
            <p>
                <div>
                    <select id="no_of_questions_to_practice" defaultValue="10">
                        <option value="10">10 {label}</option>
                        <option value="15">15 {label}</option>
                        <option value="20">20 {label}</option>
                        <option value="25">25 {label}</option>
                        <option value="30">30 {label}</option>
                        <option value="35">35 {label}</option>
                        <option value="40">40 {label}</option>
                        <option value="45">45 {label}</option>
                        <option value="50">50 {label}</option>
                        <option value="55">55 {label}</option>
                        <option value="60">60 {label}</option>
                    </select>
                </div>
            </p>
            <p>
                <div className="fieldBox" style={{ paddingLeft: '29px' }}>
                    {this.state.keywordSearchTotal} records found.
                </div>
            </p>
        </div>;

        return <Modal id="count_modal"
            show={this.state.showKeywordSearchCountDialog}
            onHide={() => {
                this.setState({ showKeywordSearchCountDialog: false, keywordSearchTotal: 0 });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">{(this.state.contentView + 's').toUpperCase()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={function () {
                    this.showIndicator();
                    let context = this;
                    let count = $("#no_of_questions_to_practice").val() ? parseInt($("#no_of_questions_to_practice").val()) : 10;
                    let keyword = $("#search_input_field").val();

                    context.showIndicator();
                    context.estore.query(null, this.esDocType, {
                        "bool": {
                            "must": {
                                "query_string": {
                                    "query": "keywords : (" + keyword + ") AND subjects : (\"" + this.params.id + "\")"
                                }
                            }
                        }
                    }, ['id'], null, null, null, count.toString()).then((filterData) => {
                        console.log(filterData);
                        let questionIds = [];
                        filterData.data.results.forEach(q => questionIds.push(q.id));

                        let pathTemplate = '/assignment/student/{studentID}/practice/questions/{questions}';
                        let params = {
                            "questions": questionIds,
                            "studentID": this.props.auth.user.userName
                        };
                        if (this.state.contentView === 'flashcard') {
                            pathTemplate = '/assignment/student/{studentID}/practice/flashcards/{words}';
                            params = {
                                "words": questionIds,
                                "studentID": this.props.auth.user.userName
                            }
                        }
                        apiClient.invokeApi(params, pathTemplate, 'GET', {}, null, '3').then(function (result) {

                            if (context.state.contentView === 'question') {
                                context.state.flashcardActivityResults.clear();
                                context.state.questionActivityResults.clear();
                                context.setState({
                                    showKeywordSearchCountDialog: false,
                                    showCountCategory: null,
                                    showCountSubCategory: null,
                                    loading: false,
                                    unansweredQuestions: result && result.data ? result.data : [],
                                    currentCategoryName: 'Search by Keyword',
                                    currentSubCategoryName: keyword,
                                    currentActivityComplete: false,
                                    currentQuestionIndex: 0,
                                    questionActivityResults: new Map(),
                                    flashcardActivityResults: new Map(),
                                    correctAnsweredQuestions: [],
                                    wrongAnsweredQuestions: []
                                });
                            } else if (context.state.contentView === 'flashcard') {
                                context.state.flashcardActivityResults.clear();
                                context.state.questionActivityResults.clear();
                                context.setState({
                                    loading: false,
                                    showKeywordSearchCountDialog: false,
                                    showCountCategory: null,
                                    showCountSubCategory: null,
                                    currentCategoryName: 'Search by Keyword',
                                    currentSubCategoryName: keyword,
                                    currentActivityComplete: false,
                                    unansweredFlashcards: result && result.data ? result.data : [],
                                    currentFlashcardIndex: 0,
                                    flashcardActivityResults: new Map(),
                                    questionActivityResults: new Map(),
                                    correctAnsweredQuestions: [],
                                    wrongAnsweredQuestions: [],
                                    flashcardRight: 0
                                });
                            }
                            for (var i = 1; i < 99999; i++) { window.clearInterval(i); }
                            context.setState({ timerSeconds: 0, timerMinutes: 0, timerHours: 0, timerSet: true }, context.setTimer);
                        }).catch(function (error) {
                            context.setState({
                                showKeywordSearchCountDialog: false,
                                showCountCategory: null,
                                showCountSubCategory: null,
                                loading: false
                            });
                        });
                    }).catch(error => {
                        console.error(error);
                        context.setState({
                            showAlert: true,
                            alertStyle: 'danger',
                            alertText: 'Unable to fetch question Ids, please contact administrator.'
                        });
                        setTimeout(() => {
                            context.setState({
                                showAlert: false,
                                alertStyle: '',
                                alertText: ''
                            })
                        }, 4000);
                    });
                }.bind(this)} bsStyle='primary'>Start</Button>
            </Modal.Footer>
        </Modal>;
    }

    getActivitySwitchWarningDialog() {
        let body = <div>
            <div className="infoIcon">
                <img src={warning} style={{ height: '80px' }} />
            </div>
            <div style={{ display: 'inline-block' }}>
                Switching to another activity, You may lose all unsaved progress.
            </div>
        </div>;

        return <Modal id="activity_warning_modal"
            backdrop="static"
            show={this.state.activitySwitchWarningDialog}
            onHide={() => {
                this.setState({ activitySwitchWarningDialog: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Warning!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ activitySwitchWarningDialog: false });
                }}>Cancel</Button>
                <Button onClick={() => {
                    this.switchActivity(this.state.activitySwitchWarningData, this.state.activitySwitchAct, this.state.activitySwitchUnitId);
                    this.setState({ activitySwitchWarningDialog: false, activitySwitchWarningData: null });
                    this.setState({ disableCheck: true, inputCheck: "", selectedTab: "question", disableReAns: true })
                }} bsStyle='primary'>Continue</Button>
            </Modal.Footer>
        </Modal>;
    }

    getTestReportDialog() {

        let studentAnswers = this.state.questionActivityResults;
        let unansweredQuestions = this.state.unansweredQuestions;
        console.log(studentAnswers);
        // let currentAct = this.state.currentActivity ? this.state.currentActivity : {};

        // let wrongWithStudentAns = [];
        // let reportEntries = [];
        // let score = 0;
        // let scorePercent = 0;
        // let length = 0;
        // calculate score.
        // if (this.state.reportData) {
        //     wrongWithStudentAns = this.state.reportData.wrongWithStudentAns;
        //     reportEntries = this.state.reportData.reportEntries;
        //     score = this.state.reportData.score;
        //     scorePercent = this.state.reportData.scorePercent;
        //     length = this.state.reportData.length;
        // }
        let score = 0;
        let categoryHeadings = new Set();

        studentAnswers.forEach(ans => {

            for (var i = 0; i < unansweredQuestions.length; i++) {

                if (unansweredQuestions[i] && unansweredQuestions[i].questionID === ans.questionID && htmlParser(unansweredQuestions[i].answer) === htmlParser(ans.studentAnswer)) {
                    score++;
                }
                else if (ans.studentAnswer && unansweredQuestions[i] && unansweredQuestions[i].questionID && unansweredQuestions[i].questionID === ans.questionID && unansweredQuestions[i].answer.replace(/\"/g, "").replace(/ /g, "").replace(/<!--.*?-->/g, "").toLowerCase() === ans.studentAnswer.replace(/\"/g, "").replace(/ /g, "").replace(/<!--.*?-->/g, "").toLowerCase()) {
                    score++;
                }
                categoryHeadings.add({
                    category: this.state.currentCategoryName,
                    subcategory: this.state.currentSubCategoryName
                });
            }
        });
        let scorePercent = null;
        let length = 0;
        if (unansweredQuestions) {
            scorePercent = (score / unansweredQuestions.length) * 100;
            length = unansweredQuestions.length;
        }
        let headings = Array.from(categoryHeadings);
        let reportEntries = [];
        let wrongQuestions = [];
        headings.forEach(heading => {
            let headingData = { questions: [], score: 0 };
            for (let j = 0; j < unansweredQuestions.length; j++) {
                let catQ = unansweredQuestions[j];
                if (catQ.category === heading.category && catQ.subcategory === heading.subcategory) {
                    headingData.questions.push(catQ);
                    let isCorrect = false;
                    studentAnswers.forEach(ans => {
                        if (htmlParser(ans.studentAnswer) === htmlParser(catQ.answer)) {
                            isCorrect = true;
                        }
                    });
                    if (isCorrect) {
                        headingData.score += 1;
                    } else {
                        wrongQuestions.push(catQ);
                    }
                }
            }
            headingData.title = heading.category + ' - ' + heading.subcategory;
            headingData.totalQuestionPercentage = (headingData.questions.length / unansweredQuestions.length) * 100;
            headingData.correctQuestionPercentage = (headingData.score / headingData.questions.length) * 100;
            reportEntries.push(headingData);
        });

        let reportLines = reportEntries.map(line => {
            let correctQuestionPercentage = line.correctQuestionPercentage ? line.correctQuestionPercentage.toFixed(0) : 0;
            return <tr key={line.title}>
                <td>{line.title}:</td>
                <td>
                    {line.correctQuestionScore + ' out of ' + line.correctQuestionLength + ' questions correct. (' + correctQuestionPercentage + '%)'}
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12   paddnone barchrtlghtbl"
                        style={{ marginRight: '10px', marginTop: '5px', background: 'white' }}>
                        <div className="filled" style={{ width: (line.correctQuestionPercentage ? line.correctQuestionPercentage : 0) + '%', background: (line.correctQuestionPercentage && line.correctQuestionPercentage > 64 ? 'green' : 'red') }}></div>
                    </div>
                </td>
            </tr>;
        });

        let wrongWithStudentAns = [];
        wrongQuestions.forEach(wrongQ => {

            if (studentAnswers.has(wrongQ.questionID)) {
                wrongQ.studentAnswer = studentAnswers.get(wrongQ.questionID).studentAnswer;
            }
            wrongWithStudentAns.push(wrongQ);
        });

        let rowCount = 0;
        let wrongLines = wrongWithStudentAns.map(question => {
            let explanation = question.explanation;
            // replace auto play.
            if (explanation) {
                explanation = explanation.replace('autoplay', '');
            }

            rowCount++;
            return <tr className="" key={'wrong' + '-' + question.questionID}>
                <td style={{ border: '1px solid #555555' }}>
                    <p className="QuestionNoBorder">
                        <strong style={{ float: 'left' }}>{rowCount + '. '} </strong>{htmlParser(`<span dir lang=${this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage}>${question.question.replace('[[[LP_ANSWERS]]]', '')}</span>`)}
                    </p>
                    <br />
                    <div className="std-q-heading">Correct Answer</div>
                    <p>
                        {htmlParser(`<span dir lang=${this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage}>${question.answer}</span>`)}
                    </p>
                    <div className="std-q-heading">Your Response</div>
                    <p>
                        {htmlParser(`<span dir lang=${this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage}>${question.studentAnswer}</span>`)}
                    </p>
                    <div className="std-q-heading">Explanation</div>
                    <p>
                        {htmlParser(`<span dir lang=${this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage}>${explanation}</span>`)}
                    </p>
                </td>
            </tr>;
        });

        let studentStats = <table className="table" id="student-stat">
            <thead>
                <tr>
                    <th style={{ background: '#f7f8f9', border: '1px solid #555555' }}>
                        Questions you got wrong.
                    </th>
                </tr>
            </thead>
            <tbody>
                {wrongLines}
            </tbody>
        </table>;


        let activityStats = <table className="table" id="act-stats-user">
            <tbody>
                <tr>
                    <td><MaterialIcon icon="accessibility" size={24} /> Student Name:</td>
                    <td>{this.props.auth.user.firstName + " " + this.props.auth.user.lastName}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="assessment" size={24} /> Score:</td>
                    <td>{scorePercent ? scorePercent.toFixed(0) : 0}%</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="check" size={24} /> Number Correct:</td>
                    <td>{score} of {length}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="timer" size={24} /> Time In:</td>
                    <td>
                        {parseHours(this.getTimerValue())}{' hours '}
                        {parseMinutes(this.getTimerValue())}{' mins '}
                        {parseSeconds(this.getTimerValue())}{' seconds'}
                    </td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="date_range" size={24} /> Completion Date:</td>
                    <td>{new Date().toLocaleString()}</td>
                </tr>
            </tbody>
        </table>;

        // let catStats = <table className="table" id="act-cat-stats-user">
        //     <tbody>
        //     {reportLines}
        //     </tbody>
        // </table>;


        let table = <div id="user-activity-analysis" className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <p style={{ overflow: 'auto' }}>
                    {activityStats}
                </p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading"  >Student Question Analysis </h1>
                <p style={{ overflow: 'auto' }}>
                    {studentStats}
                </p>
            </div>
        </div>;

        return <Modal id="test_report_modal"
            backdrop="static"
            show={this.state.showTestReportDialog}
            onHide={() => {
                this.setState({
                    showTestReportDialog: false,
                    answeredQuestions: [],
                    unansweredQuestions: [],
                    correctAnsweredQuestions: [],
                    wrongAnsweredQuestions: [],
                    currentQuestionIndex: 0,
                    currentActivityComplete: true,
                    showNoAnswerAssessment: false
                });
                for (var i = 1; i < 99999; i++) { window.clearInterval(i); }
                this.setState({ timerSet: null });
                //clearInterval(this.state.timerID);
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">{
                    <span className="eleRowspopbl paddnone margnnone ">Results for <span
                        className="eleRowspop paddnone margnnone">{this.state.currentCategoryName} : {this.state.currentSubCategoryName}</span></span>
                }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {table}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({
                        showTestReportDialog: false,
                        answeredQuestions: [],
                        unansweredQuestions: [],
                        correctAnsweredQuestions: [],
                        wrongAnsweredQuestions: [],
                        currentQuestionIndex: 0,
                        currentActivityComplete: true,
                        showNoAnswerAssessment: false
                    });
                    for (var i = 1; i < 99999; i++) { window.clearInterval(i); }
                    this.setState({ timerSet: null });
                    //  clearInterval(this.state.timerID);
                }} bsStyle='primary'>OK</Button>
            </Modal.Footer>
        </Modal>;
    }

    next(flashcardResult) {
        console.log('Next called.');
        this.setState({ currentRating: 0 });
        if (this.state.contentView === 'question') {
            // preserve current question details.
            let checked = [...document.querySelectorAll('#mainQuestionView input:checked')];
            let questionActivityResults = this.state.questionActivityResults;
            let currentQuestion = this.state.unansweredQuestions[this.state.currentQuestionIndex];
            let questionKey = this.props.auth.user.userName + '-' + this.params.id + "-" + currentQuestion.questionID;
            let isCourse = this.state.view === 'course';
            let entry = {
                questionID: currentQuestion.questionID,
                category: this.state.currentCategoryName,
                subCategory: this.state.currentSubCategoryName,
                timer: this.getTimerValue()
            };

            if (currentQuestion.questionType === 'essay') {
                let value = $('#answerTextArea').val();
                if (value) {
                    entry.studentAnswer = value;
                }
                entry.hasResult = !!value;
                if (isCourse) {
                    entry.unitID = this.state.currentUnit.unitID;
                    entry.courseID = this.state.course.courseID;
                }
                questionActivityResults.set(questionKey, entry);
                if (value) {
                    this.state.correctAnsweredQuestions.push(entry);
                } else {
                    this.state.wrongAnsweredQuestions.push(entry);
                }

            } else {

                if (checked && checked.length > 0) {
                    entry.studentAnswer = checked[0].nextElementSibling.nextElementSibling.innerHTML;;
                    entry.hasResult = !!checked[0].nextElementSibling.nextElementSibling.innerHTML;;
                    if (isCourse) {
                        entry.unitID = this.state.currentUnit.unitID;
                        entry.courseID = this.state.course.courseID;
                    }
                    questionActivityResults.set(questionKey, entry);
                } else {
                    entry.hasResult = false;
                    if (isCourse) {
                        entry.unitID = this.state.currentUnit.unitID;
                        entry.courseID = this.state.course.courseID;
                    }
                    questionActivityResults.set(questionKey, entry);
                }

                if (currentQuestion.answer === entry.studentAnswer) {
                    this.state.correctAnsweredQuestions.push(entry);
                } else {
                    this.state.wrongAnsweredQuestions.push(entry);
                }
            }

            if (this.state.currentQuestionIndex < (this.state.unansweredQuestions.length - 1)) {
                this.setState({
                    currentQuestionIndex: this.state.currentQuestionIndex + 1,
                    currentActivityProgress: this.state.currentActivityProgress + 1

                });
            }
            else if (this.state.currentQuestionIndex === (this.state.unansweredQuestions.length - 1)) {
                this.submit(false, true, true);
            }
            // now set details for next question in unanswered queue.
        } else if (this.state.contentView === 'flashcard') {
            let flashcardActivityResults = this.state.flashcardActivityResults;
            let currentFlashcard = this.state.unansweredFlashcards[this.state.currentFlashcardIndex];
            let flashcardKey = this.props.auth.user.userName + '-' + this.params.id + "-" + currentFlashcard.word;
            let isCourse = this.state.view === 'course';
            let entry = {
                word: currentFlashcard.word,
                category: this.state.currentCategoryName,
                timer: this.getTimerValue()
            };
            if (isCourse) {
                entry.unitID = this.state.currentUnit.unitID;
                entry.courseID = this.state.course.courseID;
            }
            entry.studentAnswer = flashcardResult;
            entry.result = flashcardResult;
            entry.hasResult = true;
            flashcardActivityResults.set(flashcardKey, entry);
            if (flashcardResult) {
                this.state.correctAnsweredQuestions.push(entry);
            } else {
                this.state.wrongAnsweredQuestions.push(entry);
            }
            let rightAns = 0;
            flashcardActivityResults.forEach(ans => ans.studentAnswer === true ? rightAns++ : 0);
            this.resetFlashcard(currentFlashcard);

            if (this.state.currentFlashcardIndex < (this.state.unansweredFlashcards.length - 1)) {
                this.setState({
                    currentFlashcardIndex: this.state.currentFlashcardIndex + 1,
                    flashcardRight: rightAns,
                    currentActivityProgress: this.state.currentActivityProgress + 1
                });
            } else {
                // last card.
                if ((this.state.currentFlashcardIndex + 1) === this.state.unansweredFlashcards.length && !this.state.lastObject) {
                    this.setState({
                        flashcardRight: rightAns,
                        currentActivityProgress: this.state.currentActivityProgress + 1, lastObject: true
                    });
                }
            }
        }
    }

    prev() {
        console.log('Previous called.');

        if (this.state.contentView === 'question') {
            // preserve current question details.
            let checked = [...document.querySelectorAll('#mainQuestionView input:checked')];
            let assignment = this.state.assignment;
            let currentActivity = this.state.currentActivity;
            let questionActivityResults = this.state.questionActivityResults;
            let currentQuestion = this.state.unansweredQuestions[this.state.currentQuestionIndex];
            let questionKey = currentActivity.activityID + "-" + currentQuestion.questionID;
            let isCourse = this.state.view === 'course';
            let entry = {
                questionID: currentQuestion.questionID,
                activityID: currentActivity.activityID,
                assignmentID: assignment.assignmentID,
                timer: this.getTimerValue()
            };
            if (currentQuestion.questionType === 'essay') {
                let value = $('#answerTextArea').val();
                entry.studentAnswer = value;
                entry.hasResult = !!value;
                questionActivityResults.set(questionKey, entry);
            } else {
                if (checked && checked.length > 0) {
                    entry.studentAnswer = checked[0].nextElementSibling.nextElementSibling.innerHTML;;
                    entry.hasResult = !!checked[0].nextElementSibling.nextElementSibling.innerHTML;;
                    if (isCourse) {
                        entry.unitID = this.state.currentUnit.unitID;
                        entry.courseID = this.state.course.courseID;
                    }
                    questionActivityResults.set(questionKey, entry);
                } else {
                    // Unchecked - remove entry.
                    questionActivityResults.delete(questionKey);
                }
            }

            if (this.state.currentQuestionIndex > 0) {
                this.setState({
                    currentQuestionIndex: this.state.currentQuestionIndex - 1,
                    currentActivityProgress: this.state.currentActivityProgress - 1
                });
            }
            // now set details for next question in unanswered queue.
        } else if (this.state.contentView === 'flashcard') {
            $('#i_was_right').removeClass('active');
            $('#i_was_wrong').removeClass('active');

            let currentActivity = this.state.currentActivity;
            let flashcardActivityResults = this.state.flashcardActivityResults;
            let currentFlashcard = this.state.unansweredFlashcards[this.state.currentFlashcardIndex];
            let flashcardKey = currentActivity.activityID + "-" + currentFlashcard.word;
            flashcardActivityResults.delete(flashcardKey);
            let rightAns = 0;
            flashcardActivityResults.forEach(ans => ans.studentAnswer === true ? rightAns++ : 0);
            this.resetFlashcard(currentFlashcard);
            if (this.state.currentFlashcardIndex > 0) {
                this.setState({
                    flashcardActivityResults: flashcardActivityResults,
                    currentFlashcardIndex: this.state.currentFlashcardIndex - 1,
                    currentActivityProgress: this.state.currentActivityProgress - 1,
                    flashcardRight: rightAns
                });
            }
        }
    }

    pause() {
        console.log('Pause called.');

        this.submit(true);
    }

    hint() {
        console.log('Hint called.');

        this.setState({
            ...this.state,
            showHintDialog: true
        })
    }

    print() {
        let directionStyle = this.props.auth.user.prefLanguage === 'ar' ? "direction:rtl;text-align:left;display:inline-block" : "";

        if (this.state.contentView === 'flashcard') {
            let record = this.state.unansweredFlashcards;
            let count = 0;
            let printNodes = [];
            if (record.length > 0) {
                record.forEach(flashcard => {
                    let flashcardText = flashcard.word;
                    let definition = flashcard.wordDefinition;
                    printNodes.push("<div> <h3> Flashcard " + (count + 1) + "</h3> <p style='" + directionStyle + "'>" + flashcardText + "</p> <p style='" + directionStyle + "'>" + definition + "</p></div><hr />");
                    count++;
                });
                let myWindow = window.open('', 'PRINT', 'height=600,width=800');
                myWindow.document.write('<html><head><title>' + 'All Flashcards' + '</title>');
                myWindow.document.write('<h1>' + 'All Flashcards' + '</h1>');
                myWindow.document.write(printNodes.join(" "));
                myWindow.document.write('</body></html>');
                myWindow.focus(); // necessary for IE >= 10*/
                setTimeout(function () {
                    myWindow.print();
                    myWindow.document.close(); // necessary for IE >= 10
                    myWindow.close();
                }, 1000);
                return false;
            }
        }
        else {
            let record = this.state.unansweredQuestions;
            let count = 0;
            let printNodes = [];
            if (record.length > 0) {
                record.forEach(question => {
                    let questionText = question.question.replace('[[[LP_ANSWERS]]]', '');
                    let questionText2 = "";
                    if (this.props.auth.user.prefLanguage === 'ar' && questionText.indexOf('<div') === 0) {
                        questionText2 = questionText.substring(0, questionText.indexOf('>'));
                        questionText2 += " style='" + directionStyle + "' ";
                        questionText2 += questionText.substring(questionText.indexOf('>'));
                    } else {
                        questionText2 = "<p style='" + directionStyle + "'> " + questionText + "</p>";
                    }

                    let choicesHtml = [];
                    let choiceCount = 0;
                    if (question.choices && question.choices.length > 0) {
                        question.choices.forEach(choice => {
                            choicesHtml.push("<div className='fieldBox'  style='direction:ltr' id=" + question.activityID + choiceCount + ">" + "<span>" + (choiceCount + 1) + ". </span>" + "<input type='checkbox' className='css-checkbox css-label1 bc_info card_side'  id=" + "choice" + choiceCount + " name='choice' />" +
                                "<label className='css-label radGroup1' for='choice'" + choiceCount + "'></label><div style='" + directionStyle + "'>" + choice + "</div></div>");
                            choiceCount++;
                        });
                    }
                    printNodes.push("<div> <h3> Question " + (count + 1) + "</h3> <p>" + questionText2 + "</p> <p>" + choicesHtml.join(" ") + "</p></div><hr />");
                    count++;
                });
                let myWindow = window.open('', 'PRINT', 'height=600,width=800');
                myWindow.document.write('<html><head><title>' + 'All Questions' + '</title>');
                myWindow.document.write('<h1>' + 'All Questions' + '</h1>');
                myWindow.document.write(printNodes.join(" "));
                myWindow.document.write('</body></html>');
                myWindow.focus(); // necessary for IE >= 10*/
                setTimeout(function () {
                    myWindow.print();
                    myWindow.document.close(); // necessary for IE >= 10
                    myWindow.close();
                }, 1000);
                return false;
            }
        }
    }

    refreshIdentityToken(response) {

        return new Promise((resolve, reject) => {
            const token = response['id_token'];
            const access_token = response['access_token'];
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: config.identityPoolId,
                Logins: {
                    'accounts.google.com': token
                }
            });
            AWS.config.region = config.region;
            AWS.config.credentials.clearCachedId();
            AWS.config.credentials.get(function (err, data) {
                if (err) {
                    reject("Error: " + err);
                }
                localStorage.setItem('accessToken', access_token);
                localStorage.setItem('identityToken', token);
                localStorage.setItem('accessKeyId', AWS.config.credentials.accessKeyId);
                localStorage.setItem('secretAccessKey', AWS.config.credentials.secretAccessKey);
                localStorage.setItem('sessionToken', AWS.config.credentials.sessionToken);
            });

            resolve(AWS.config.credentials.getPromise());
        })
    }

    getAwsCredentials() {
        AWS.config.update({ region: 'us-east-1' });

        let userPool = new CognitoUserPool({
            UserPoolId: config.userPoolId,
            ClientId: config.clientId
        });
        let cognitoUser = userPool.getCurrentUser();
        cognitoUser.getSession(function (err, session) {
            if (session) {
                console.log('session validity: ' + session.isValid());
                localStorage.setItem('token', session.getIdToken().getJwtToken());
            }
        });

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: config.identityPoolId,
            Logins: {
                [config.cognitoURL]: localStorage.getItem('token')
            }
        });

        // AWS.config.credentials.clearCachedId();
        // We can set the get method of the Credentials object to retrieve
        // the unique identifier for the end user (identityId) once the provider
        // has refreshed itself
        AWS.config.credentials.clearCachedId();
        AWS.config.credentials.get(function (err) {
            if (err) {
                console.log("Error: " + err);
            }
            // localStorage.setItem('token', result.getIdToken().getJwtToken());
            localStorage.setItem('accessKeyId', AWS.config.credentials.accessKeyId);
            localStorage.setItem('secretAccessKey', AWS.config.credentials.secretAccessKey);
            localStorage.setItem('sessionToken', AWS.config.credentials.sessionToken);
        });
        AWS.config.credentials.clearCachedId();

        return AWS.config.credentials.getPromise();
    }

    sendActivityAssignmentResultForGoogle(payload, submitToTeacher, pause, showReport, markActivityComplete, saveCourse) {
        let token = this.state.gToken;
        this.refreshIdentityToken(token).then(res => {
            for (var i = 1; i < 99999; i++) { window.clearInterval(i); }
            this.setState({ timerSet: null, showNextDialog: false, showNoAnswerAssessment: false });
            let context = this;
            context.showIndicator();
            let pathTemplate = '/assignment/student/practice';
            let params = {};
            if (submitToTeacher) {
                payload.submit = true;
            }
            if (pause) {
                payload.pause = 'true';
            } else {
                payload.pause = 'false';
            }
            payload.activityTime = this.getTimerValue();

            return new Promise((resolve, reject) => {
                apiClient.invokeApi(params, pathTemplate, 'PUT', {}, payload, '2').then(function (result) {
                    if (payload.activityType === 'question') {
                        ClevertapReact.event("Submitted Practice Questions from " + payload.subject, { 'userName': context.props.auth.user.userName });
                    }
                    else if (payload.activityType === 'flashcard') {
                        ClevertapReact.event("Submitted Practice Flashcards from " + payload.subject, { 'userName': context.props.auth.user.userName });
                    }

                    context.hideIndicator();

                    resolve(result.data);
                    if (showReport) {
                        context.setState({
                            showTestReportDialog: true,
                            showNextDialog: false
                        })
                    } else {
                        context.setState({
                            currentActivityComplete: true,
                            showNextDialog: false
                        })
                    }
                }).catch(function (error) {
                    context.hideIndicator();
                    reject(error);
                });
            });
        }).catch(e => {
        })
    }

    sendActivityAssignmentResult(payload, submitToTeacher, pause, showReport, markActivityComplete, saveCourse) {
        this.getAwsCredentials().then(res => {
            for (var i = 1; i < 99999; i++) { window.clearInterval(i); }
            this.setState({ timerSet: null, showNextDialog: false, showNoAnswerAssessment: false });
            let context = this;
            context.showIndicator();
            let pathTemplate = '/assignment/student/practice';
            let params = {};
            if (submitToTeacher) {
                payload.submit = true;
            }
            if (pause) {
                payload.pause = 'true';
            } else {
                payload.pause = 'false';
            }
            payload.activityTime = this.getTimerValue();

            return new Promise((resolve, reject) => {
                apiClient.invokeApi(params, pathTemplate, 'PUT', {}, payload, '2').then(function (result) {
                    if (payload.activityType === 'question') {
                        ClevertapReact.event("Submitted Practice Questions from " + payload.subject, { 'userName': context.props.auth.user.userName });
                    }
                    else if (payload.activityType === 'flashcard') {
                        ClevertapReact.event("Submitted Practice Flashcards from " + payload.subject, { 'userName': context.props.auth.user.userName });
                    }

                    context.hideIndicator();

                    resolve(result.data);
                    if (showReport) {
                        context.setState({
                            showTestReportDialog: true,
                            showNextDialog: false
                        })
                    } else {
                        context.setState({
                            currentActivityComplete: true,
                            showNextDialog: false
                        })
                    }
                }).catch(function (error) {
                    context.hideIndicator();
                    reject(error);
                });
            });
        }).catch(e => {
        })
    }

    submit(pause, showReport, markActivityComplete) {

        console.log('Submit called.');
        this.setState({ currentRating: 0 });
        let assignment = this.state.assignment;
        let currentActivity = this.state.currentActivity;

        if (this.state.contentView === 'question') {
            // preserve current question details.
            let questionActivityResults = this.state.questionActivityResults;
            let isCourse = this.state.view === 'course';

            // same stuff stays here..
            let checked = [...document.querySelectorAll('#mainQuestionView input:checked')];
            let currentQuestion = this.state.unansweredQuestions[this.state.currentQuestionIndex];
            let questionKey = this.props.auth.user.userName + '-' + this.params.id + "-" + currentQuestion.questionID;
            let entry = {
                studentID: this.props.auth.user.userName,
                questionID: currentQuestion.questionID,
                category: this.state.currentCategoryName,
                subCategory: this.state.currentSubCategoryName,
                timer: this.getTimerValue()
            };

            if (currentQuestion.questionType === 'essay') {
                let value = $('#answerTextArea').val();
                if (value) {
                    entry.studentAnswer = value;
                }
                entry.hasResult = !!value;
                if (isCourse) {
                    entry.unitID = this.state.currentUnit.unitID;
                    entry.courseID = this.state.course.courseID;
                }
                questionActivityResults.set(questionKey, entry);
            } else {
                if (checked && checked.length > 0) {
                    entry.studentAnswer = checked[0].nextElementSibling.nextElementSibling.innerHTML;;
                    entry.hasResult = !!checked[0].nextElementSibling.nextElementSibling.innerHTML;;
                    if (isCourse) {
                        entry.unitID = this.state.currentUnit.unitID;
                        entry.courseID = this.state.course.courseID;
                    }
                    questionActivityResults.set(questionKey, entry);
                } else {
                    // Unchecked - remove entry.
                    questionActivityResults.delete(questionKey);
                }
            }

            let answeredQuestion = [];
            let timerForAssignmentActivity = 0;
            questionActivityResults.forEach(q => {
                answeredQuestion.push(q);
                timerForAssignmentActivity += q.timer
            });

            // general payload.
            let payload = {
                "subject": this.params.id,
                "category": this.state.currentCategoryName,
                "subCategory": this.state.currentSubCategoryName,
                "activityType": this.state.contentView,
                "practiceType": this.state.view,
                "studentID": this.props.auth.user.userName,
                "submit": true,
                "pause": pause,
                "timer": timerForAssignmentActivity,
                "answeredQuestion": answeredQuestion,
                "correctAnsweredQuestions": this.state.correctAnsweredQuestions,
                "wrongAnsweredQuestions": this.state.wrongAnsweredQuestions,
            };

            if (isCourse) {
                // save for course.
                payload.assignedActivities.courseID = this.state.course.courseID;
                payload.assignedActivities.unitID = this.state.currentUnit.unitID;
                payload.courseProgress = assignment.courseProgress;

                //calculate progress for course.
                let course = this.state.course;
                var acts = [];
                course.units.forEach(unit => {
                    acts = acts.concat(unit.activities);
                });
                let singleActivityProgress = 100 / acts.length;
                if (payload.courseProgress) {
                    payload.courseProgress = parseFloat(payload.courseProgress) + singleActivityProgress;
                } else {
                    payload.courseProgress = singleActivityProgress;
                }
                if (this.props.auth.user.federatedIdentity) {
                    this.sendActivityAssignmentResultForGoogle(payload, true, pause, showReport, markActivityComplete, true);
                } else {
                    this.sendActivityAssignmentResult(payload, true, pause, showReport, markActivityComplete, true);
                }
            } else {
                // activity.
                if (this.props.auth.user.federatedIdentity) {
                    this.sendActivityAssignmentResultForGoogle(payload, true, pause, showReport, false);
                } else {

                    this.sendActivityAssignmentResult(payload, true, pause, showReport, false);
                }
            }

            // now set details for next question in unanswered queue.
        } else if (this.state.contentView === 'flashcard') {

            // preserve current question details.
            let flashcardActivityResults = this.state.flashcardActivityResults;
            let isCourse = this.state.view === 'course';

            let answeredFlashcard = [];
            let timerForAssignmentActivity = 0;
            flashcardActivityResults.forEach(q => {
                answeredFlashcard.push(q);
                timerForAssignmentActivity += q.timer
            });


            let wrong = [];
            let right = [];
            flashcardActivityResults.forEach(result => {
                if (result.studentAnswer) {
                    right.push(result);
                } else {
                    wrong.push(result);
                }
            });

            let payload = {
                "subject": this.params.id,
                "category": this.state.currentCategoryName,
                "activityType": this.state.contentView,
                "practiceType": this.state.view,
                "studentID": this.props.auth.user.userName,
                "submit": true,
                "pause": pause,
                "timer": timerForAssignmentActivity,
                "answeredQuestion": answeredFlashcard,
                "correctAnsweredQuestions": right,
                "wrongAnsweredQuestions": wrong,
            };

            if (isCourse) {
                // save for course.
                payload.assignedActivities.courseID = this.state.course.courseID;
                payload.assignedActivities.unitID = this.state.currentUnit.unitID;
                payload.courseProgress = assignment.courseProgress;

                //calculate progress for course.
                let course = this.state.course;
                var acts = [];
                course.units.forEach(unit => {
                    acts = acts.concat(unit.activities);
                });
                let singleActivityProgress = 100 / acts.length;
                if (payload.courseProgress) {
                    payload.courseProgress = parseFloat(payload.courseProgress) + singleActivityProgress;
                } else {
                    payload.courseProgress = singleActivityProgress;
                }
                if (this.props.auth.user.federatedIdentity) {
                    this.sendActivityAssignmentResultForGoogle(payload, true, pause, false, markActivityComplete, true);
                } else {
                    this.sendActivityAssignmentResult(payload, true, pause, false, markActivityComplete, true);
                }
            } else {
                if (this.props.auth.user.federatedIdentity) {
                    this.sendActivityAssignmentResultForGoogle(payload, true, pause, false, markActivityComplete);
                } else {
                    this.sendActivityAssignmentResult(payload, true, pause, false, markActivityComplete);
                }
                // activity.

            }
        }
    }

    end(markActivityComplete) {

        console.log('End called.');
        document.getElementById('drop_shadow_check_ans').style.display = 'none';
        this.setState({ currentRating: 0 });
        this.setState({ leave: true }, this.submit(false, true, markActivityComplete));
    }

    addNewDeckClicked(evt) {
        console.log('Add new deck clicked.');

        browserHistory.push('/student/flashcard/new/' + this.props.params.id);
    }

    learnerPalFlashcards() {
        console.log('Leaner pal flashcards clicked.');

        this.setState({
            activePanel: 'learnerpal'
        })
    }

    myFlashcards() {
        console.log('My flashcards clicked.');

        this.setState({
            activePanel: 'decks'
        })
    }
    render() {

        const { screenWidth } = this.state;
        const isMobile = screenWidth < 768;
        let context = this;
        let categories = context.state.categories;
        let activity = context.state.assignment && context.state.assignment.assignedActivities ? context.state.assignment.assignedActivities : null;

        let content = null;
        let currentQuestion = context.state.unansweredQuestions[context.state.currentQuestionIndex];

        if (context.state.currentActivity) {
            if (context.state.contentView === 'question') {
                let currentQuestion = context.state.unansweredQuestions[context.state.currentQuestionIndex];
                if (currentQuestion) {
                    let questionActivityResults = this.state.questionActivityResults;
                    content = this.getMainContent(context.state.contentView, currentQuestion, questionActivityResults.get(this.state.currentActivity.activityID + '-' + currentQuestion.questionID));
                }
            } else if (context.state.contentView === 'flashcard') {
                let currentFlashcard = context.state.unansweredFlashcards[context.state.currentFlashcardIndex];
                if (currentFlashcard) {
                    content = this.getMainContent(context.state.contentView, currentFlashcard);
                }
            } else if (context.state.contentView === 'learning') {
                content = this.getMainContent(context.state.contentView);
            }
        } else {
            if (context.state.contentView === 'question') {
                let currentQuestion = context.state.unansweredQuestions[context.state.currentQuestionIndex];
                if (currentQuestion) {
                    let questionActivityResults = this.state.questionActivityResults;
                    let questionKey = this.props.auth.user.userName + '-' + this.params.id + "-" + currentQuestion.questionID;
                    content = this.getMainContent(context.state.contentView, currentQuestion, questionActivityResults.get(questionKey));
                }
            } else if (context.state.contentView === 'flashcard') {
                let currentFlashcard = context.state.unansweredFlashcards[context.state.currentFlashcardIndex];
                if (currentFlashcard) {
                    content = this.getMainContent(context.state.contentView, currentFlashcard);
                }
            } else if (context.state.contentView === 'learning') {
                content = this.getMainContent(context.state.contentView);
            }
        }

        let text = this.state.contentView === 'question' ? 'Please select a category and sub-category OR search by keyword.' : 'Please select a category OR search by keyword.';

        let bcumb = 'Practice ' + Utility.ucFirst(context.state.contentView);
        const { isFullScreen } = this.props.appSettings;
        return (
            <div className="container-fluid noPad listMainBoxWrapper " id="prac-assignment" ref="practiceAssignment" >
                {!isFullScreen && <Heading
                    breadcrumbsData={
                        [
                            {
                                name: 'Self Study', action: () => {
                                    browserHistory.push('/student/selfstudy/');
                                }
                            },
                            {
                                name: bcumb, action: () => {
                                }
                            }
                        ]
                    }
                    pickList=
                    {
                        <div>
                            <a href={'/student/selfstudy/view/' + encodeURIComponent(this.state.subjectID) + '[[question]]?subject=' + this.state.subjectID}><span style={{ color: "white" }}>Questions  |</span></a>
                            <a href={'/student/selfstudy/flashcard/' + encodeURIComponent(this.state.subjectID) + '[[flashcard]]?subject=' + this.state.subjectID}><span style={{ color: "white" }}>Flashcards</span></a>
                            {/* | */}
                            {/* <a href={'/student/selfstudy/course/' + encodeURIComponent(this.state.subjectID)}>Courses</a> */}
                        </div>
                    }
                    showFilterButtons="false"
                />}
                {
                    this.state.showAlert &&
                    <div className="row">
                        <div className="col-md-3 col-xs-3" />
                        <div className="col-md-6 col-xs-3">
                            <Alert bsStyle={this.state.alertStyle}
                                onDismiss={this.handleAlertDismiss}>
                                {this.state.alertText}
                            </Alert>
                        </div>
                        <div className="col-md-3 col-xs-3" />
                    </div>
                }
                <div id="submission" className="tab-pane fade active in">

                    {/* Side panel for Course view */}
                    {categories && this.getCategorySidePanel(categories)}
                    {/* Side panel for Activity view */}
                    {activity && !categories && this.getActivitySidePanel(activity)}
                    {/* Main Content */}
                    <div className="mainActivBoxRight" id="contentPanel">
                        {this.state.contentView === 'flashcard' && <div id="flashcard-btns">
                            <div id="flashcard-nav">
                                <a href="javascript:void 0" onClick={this.learnerPalFlashcards}>LearnerPal Flashcards</a>
                                <span> | </span>
                                <a href="javascript:void 0" onClick={this.myFlashcards}>My Flashcards</a>
                            </div>
                            <div className="col-md-2 pull-right noPad crtBtnCol hidden-xs "><button type="button" className="btn btn-primary crtBtn" onClick={this.addNewDeckClicked}><span> </span>Create New</button></div>
                        </div>}
                        {/* Main content heading and breadcrumbs */}

                        {context.state.contentView === 'question' && this.getMainContentTopHeading()}

                        {/* content for current activity */}
                        {this.state.currentActivityComplete === false && <div className="">

                            {(context.state.contentView === 'flashcard' || context.state.contentView === 'learning') && content}
                            {this.state.contentView === 'question' && this.state.isPractice ? <Tabs eventKey="classCheck" id="tab" defaultActiveKey={this.state.selectedTab} activeKey={this.state.selectedTab} onSelect={selectedTab => this.setState({ selectedTab })}>
                                <Tab eventKey="question" title={this.state.inputCheck === "" ? null : "Question"}>

                                    <div className="container-fluid questionMainBlock" style={this.state.inputCheck === "" ? { marginTop: '-2px' } : { marginTop: '5px' }}>

                                        <div className="quesAndIconStudent">
                                            {this.state.disableReAns && this.state.selectedTab === "question" && this.state.inputCheck === "disabled" && <span style={{ color: "red", fontSize: "16px" }}>You have already answered this question and cannot change your answer at this point</span>}
                                            {context.state.contentView === 'question' && content}
                                            {/* Content area footer  */}
                                            {context.state.contentView !== 'learning' && <div className="row">
                                                <div className=" lastQuesRedirectStudent" style={{ zIndex: 2 }}>
                                                    <div className="col-md-2 col-sm-3 col-xs-2 text-alignlft" style={{ zIndex: 10 }}>
                                                        <a href="javascript:void 0" className="print" onClick={this.print}>
                                                            <span className="submitAnswer"><b>Print</b></span></a>
                                                    </div>
                                                    <div className="col-md-7 col-sm-6 col-xs-7 text-center">
                                                        {this.state.view !== 'activity' &&
                                                            <a href="javascript:void 0" className="pause" onClick={this.pause}>
                                                                <b>Pause</b></a>}
                                                        {this.state.isPractice && context.state.contentView === 'question' && <a href="javascript:void 0" className="hint"
                                                            onClick={this.hint}><span className="submitAnswer"><b>Hint</b></span> </a>}
                                                        {!this.state.isPractice &&
                                                            <a href="javascript:void 0" className="prevQuesStudent"
                                                                onClick={this.prev}>
                                                                <span className="submitAnswer"><b>Back</b></span></a>}
                                                        {this.state.inputCheck === "" && this.state.contentView === 'question' && <a style={{ height: "45px" }} href="javascript:void 0"
                                                            className={this.state.isPractice ? "nextQuesStudent forPracticeOnly" : "nextQuesStudent"}
                                                            onClick={function () {

                                                                let currentQuestion = context.state.unansweredQuestions[context.state.currentQuestionIndex];
                                                                //  if (context.state.currentActivity.questions.length - context.state.currentQuestionIndex === 1) {
                                                                //     this.end(true);
                                                                //  } else
                                                                this.setQuestionAndClearTranslateState(currentQuestion)
                                                                this.checkEmptyAnswer();
                                                            }.bind(this)
                                                            }> <span className="submitAnswer hidden-xs hidden-sm"><b>{this.state.isPractice ? 'Submit Answer' : 'Next'}</b> </span><span className="submitAnswer hidden-md hidden-lg"><b>Submit</b></span></a>}
                                                        {this.state.selectedTab === "question" && this.state.inputCheck === "disabled" &&
                                                            <a style={{ height: "45px" }} href="javascript:void 0"
                                                                className={this.state.isPractice ? "nextQuesStudent forPracticeOnly" : "nextQuesStudent"}
                                                                onClick={this.nextQuestion
                                                                }><span className="submitAnswer"><b>Next Question</b></span></a>
                                                        }

                                                    </div>
                                                    <div className="col-md-3 col-sm-3 col-xs-2 text-alignrght">
                                                        {/* {!this.state.isPractice &&
                                                 <a href="javascript:void 0" className="submit" onClick={this.submit}>
                                                 Submit</a>} */}
                                                        {this.state.isPractice &&
                                                            <a href="javascript:void 0" className="end"
                                                                onClick={function () {
                                                                    const currentFlashcard = context.state.unansweredFlashcards && context.state.unansweredFlashcards[context.state.currentFlashcardIndex];
                                                                    context.resetFlashcard(currentFlashcard);
                                                                    this.end(true);
                                                                }.bind(this)}> <span className="submitAnswer"><b>Submit Activity</b></span></a>}

                                                        {!this.state.isPractice &&
                                                            <a href="javascript:void 0" className="end  hidden-xs"
                                                                onClick={function () {
                                                                    const currentFlashcard = context.state.unansweredFlashcards && context.state.unansweredFlashcards[context.state.currentFlashcardIndex];
                                                                    context.resetFlashcard(currentFlashcard);
                                                                    this.setState({ seeEnglish: null, seeTranslation: null })
                                                                    this.end(true);
                                                                }.bind(this)}><span className="submitAnswer"><b>Submit & End</b></span></a>}
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    </div></Tab>
                                <Tab eventKey="explanation" title={this.state.disableCheck ? "" : "Explanation"} disabled={this.state.disableCheck}>
                                    {this.state.selectedTab === "explanation" && this.getCheckAnswerDialog()}

                                </Tab>
                                <Tab eventKey={this.state.inputCheck === "disabled" && this.state.selectedTab === "explanation" && !isMobile && (!this.state.ansCheck ? "answerWrong" : "answerCorrect")} title={this.state.inputCheck === "disabled" && !isMobile && this.state.selectedTab === "explanation" && (!this.state.ansCheck && <span style={{ display: 'flex', marginBottom: '5px' }}><span style={{ display: 'flex', marginTop: '15px', color: 'white' }}><i className="material-icons" style={{ position: 'relative', color: 'white', marginRight: "3px" }} >cancel</i><span style={{ color: "white", display: 'flex' }} >Sorry, that answer was not correct.</span> <span style={{ maxHeight: '60px', display: 'flex', color: 'white' }}>Correct Answer is: </span></span><span style={{ display: 'flex', maxHeight: '60px', color: 'white', marginTop: '14px', marginLeft: '12px' }} dir lang={this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage}>{htmlParser(currentQuestion.answer)}</span></span> || this.state.ansCheck && <span><i className="material-icons" style={{ position: 'relative', color: 'white', marginRight: "3px" }} >check_circle</i><span style={{ color: "white" }} >Your Answer is Correct</span></span>)} disabled={true}></Tab>

                            </Tabs> :
                                <div className="container-fluid questionMainBlock" style={{ marginTop: '60px' }}>

                                    <div className="quesAndIconStudent">

                                        {context.state.contentView === 'question' && content}
                                        {/* Content area footer  */}
                                        {context.state.contentView !== 'learning' && <div className="row">
                                            <div className=" lastQuesRedirectStudent" style={{ zIndex: 2 }}>
                                                <div className="col-md-2 col-sm-3 col-xs-2 text-alignlft" style={{ zIndex: 10 }}>
                                                    {!this.state.isPractice &&
                                                        <a href="javascript:void 0" className="prevQuesStudent"
                                                            onClick={this.prev}>
                                                            <span className="submitAnswer"><b>Back</b></span></a>}
                                                    {this.state.contentView === 'question' &&
                                                        <a href="javascript:void 0"
                                                            className={this.state.isPractice ? "nextQuesStudent forPracticeOnly" : "nextQuesStudent"}
                                                            onClick={function () {

                                                                let currentQuestion = context.state.unansweredQuestions[context.state.currentQuestionIndex];
                                                                //  if (context.state.currentActivity.questions.length - context.state.currentQuestionIndex === 1) {
                                                                //     this.end(true);
                                                                //  } else
                                                                this.setQuestionAndClearTranslateState(currentQuestion)
                                                                this.checkEmptyAnswer();
                                                            }.bind(this)
                                                            }> <span className="submitAnswer hidden-xs hidden-sm"><b>{this.state.isPractice ? 'Submit Answer' : 'Next'}</b></span><span className="submitAnswer hidden-md hidden-lg">Submit</span></a>}
                                                </div>
                                                <div className="col-md-7 col-sm-6 col-xs-7 text-center">
                                                    {this.state.view !== 'activity' &&
                                                        <a href="javascript:void 0" className="pause" onClick={this.pause}>
                                                            <b> Pause</b></a>}
                                                    {this.state.isPractice && context.state.contentView === 'question' && <a href="javascript:void 0" className="hint"
                                                        onClick={this.hint}><span className="submitAnswer"><b>Hint</b></span></a>}
                                                    <a href="javascript:void 0" className="print" onClick={this.print}>
                                                        <span className="submitAnswer"><b>Print</b></span></a>
                                                </div>
                                                <div className="col-md-3 col-sm-3 col-xs-2 text-alignrght">
                                                    {/* {!this.state.isPractice &&
                                     <a href="javascript:void 0" className="submit" onClick={this.submit}>
                                     Submit</a>} */}
                                                    {this.state.isPractice &&
                                                        <a href="javascript:void 0" className="end"
                                                            onClick={function () {
                                                                const currentFlashcard = context.state.unansweredFlashcards && context.state.unansweredFlashcards[context.state.currentFlashcardIndex];
                                                                context.resetFlashcard(currentFlashcard);
                                                                this.end(true);
                                                            }.bind(this)}> <span className="submitAnswer"><b>Submit Activity</b></span></a>}

                                                    {!this.state.isPractice &&
                                                        <a href="javascript:void 0" className="end  hidden-xs"
                                                            onClick={function () {
                                                                const currentFlashcard = context.state.unansweredFlashcards && context.state.unansweredFlashcards[context.state.currentFlashcardIndex];
                                                                context.resetFlashcard(currentFlashcard);
                                                                this.setState({ seeEnglish: null, seeTranslation: null })
                                                                this.end(true);
                                                            }.bind(this)}><span className="submitAnswer"><b>Submit & End</b></span></a>}
                                                </div>
                                            </div>
                                        </div>}

                                    </div>
                                </div>}
                        </div>}

                        {this.state.currentActivityComplete &&
                            <div className="row" key={'no_activity_selected'} id="mainQuestionView">
                                <div id="no_activity_selected">
                                    <p>
                                        {text}
                                        <br /><br />
                                        <a href="javascript:void 0" onClick={() => {
                                            browserHistory.push('/student/selfstudy');
                                        }}> Go back to Self Study </a>
                                    </p>
                                </div>
                            </div>}

                    </div>

                </div>
                <div id="drop_shadow_check_ans" style={{
                    zIndex: 0,
                    display: 'none',
                    background: 'black',
                    opacity: 0.5
                }}></div>
                <ProgressIndicator show={this.state.loading} />
                {/* {this.state.selectedTab === "explanation" && this.getCheckAnswerDialog()} */}
                {this.state.showHintDialog && this.getHintDialog()}
                {this.state.showCountDialog && this.getCountDialog()}
                {this.state.showTestReportDialog && this.getTestReportDialog()}
                {this.state.activitySwitchWarningDialog && this.getActivitySwitchWarningDialog()}
                {this.state.showKeywordSearchCountDialog && this.getKeywordSearchCountDialog()}
                {this.state.showDeleteDialog && this.getDeleteDialog()}
                {this.state.showNoAnswerSelectionWarningDialog && this.getNoAnswerSelectionWarningDialog()}
                {/* {this.state.disableReAns && this.state.selectedTab === "question" && this.state.inputCheck === "disabled" && this.showReAnsWarning()} */}

            </div >
        );
    }
    showReAnsWarning() {
        return <Modal id="hint_modal"
            backdrop="static"
            show={this.state.inputCheck === "disabled"}
            container={this}
            onHide={() => { this.setState({ disableReAns: false }) }}
            aria-labelledby="contained-modal-title">
            <Modal.Header>
                <Modal.Title id="delete-activity-modal"></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {"You have already answered this question and cannot change your answer at this point"}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ disableReAns: false })
                }} bsStyle='primary'>OK</Button>
            </Modal.Footer>
        </Modal>;

    }
    getNoAnswerSelectionWarningDialog() {

        let body = <div>
            <p>You have not submitted an answer for this question. If you choose to continue without submitting an answer, the question will be marked as incorrect. If you would like to answer this question, click the ""Cancel"" button below and submit an answer</p>
        </div>;
        let currentQuestion = this.state.unansweredQuestions[this.state.currentQuestionIndex];

        return <Modal id="hint_modal"
            backdrop="static"
            show={this.state.showNoAnswerSelectionWarningDialog}
            onHide={() => {
                document.querySelector('.container-fluid.noPad.listMainBoxWrapper').style.overflow = 'hidden';
                this.setState({ showNoAnswerSelectionWarningDialog: false });
                if (currentQuestion.questionType.toLowerCase() !== 'essay' && this.state.isPractice) {
                    this.setState({ selectedTab: "explanation" });
                } else {
                    this.next();
                }
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header>
                <Modal.Title id="delete-activity-modal">You have not submitted an answer for this question</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showNoAnswerSelectionWarningDialog: false });
                }} bsStyle='default'>Cancel</Button>
                <Button onClick={() => {

                    document.querySelector('.container-fluid.noPad.listMainBoxWrapper').style.overflow = 'hidden';
                    this.setState({ showNoAnswerSelectionWarningDialog: false });
                    if (currentQuestion.questionType.toLowerCase() !== 'essay' && this.state.isPractice) {
                        // document.getElementById('drop_shadow_check_ans').style.display = 'block';
                        // this.setState({ showNextDialog: true });
                        this.setState({ disableCheck: false });
                        this.setState({ inputCheck: "disabled" });
                        this.setState({ selectedTab: "explanation" });
                        this.setState({ submitAnswerBtn: false });
                    } else {
                        this.next();

                    }
                }} bsStyle='primary'>Ok</Button>
            </Modal.Footer>
        </Modal>;
    }

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    getSubjectFilterData(id) {

        id = this.params.id;
        let context = this;
        // context.showIndicator();
        return new Promise((resolve, reject) => {

            if (context.state.contentView === 'question') {
                this.estore.query(null, this.esDocType, {
                    // "match": {
                    //     "subjects": id
                    // }
                    "bool": {
                        "must": [
                            {
                                "match": {
                                    "subjects": id
                                }
                            },
                            {
                                "match": {
                                    "questionState2": "Published"
                                }
                            }
                        ]
                    }
                }, null, null, null, {
                    "category": {
                        "filter": { "term": { "questionType": "Multiple Choice" } },
                        "aggs": {
                            "category": {
                                "terms": {
                                    "field": "category",
                                    "size": 10000
                                }
                            }
                        }
                    }
                }, "0").then((filterData) => {
                    console.log(filterData);
                    resolve(filterData.data);
                }).catch(error => {
                    console.error(error);
                    context.setState({
                        showAlert: true,
                        alertStyle: 'danger',
                        alertText: 'Unable to load filter data, please contact administrator.'
                    });
                    setTimeout(() => {
                        context.setState({
                            showAlert: false,
                            alertStyle: '',
                            alertText: ''
                        })
                    }, 4000);
                    reject(error);
                })
            } else if (context.state.contentView === 'flashcard') {
                id = this.params.id;
                this.estore.query(null, this.esDocType, {
                    "bool": {
                        "must": {
                            "query_string": {
                                "query": "subjects : (\"" + id + "\") AND ownerName : (LearnerPal)"
                            }
                        }
                    }
                }, null, null, null, {
                    "category": {
                        "terms": { "field": "category", "size": 10000 }
                    }
                }, "0").then((filterData) => {
                    console.log(filterData);
                    resolve(filterData.data);
                }).catch(error => {
                    console.error(error);
                    context.setState({
                        showAlert: true,
                        alertStyle: 'danger',
                        alertText: 'Unable to load filter data, please contact administrator.'
                    });
                    setTimeout(() => {
                        context.setState({
                            showAlert: false,
                            alertStyle: '',
                            alertText: ''
                        })
                    }, 4000);
                    reject(error);
                })
            }
        });
    }

    getSubCategory(category) {
        let context = this;
        // context.showIndicator();
        return new Promise((resolve, reject) => {
            this.estore.query(null, this.esDocType, {
                // "match": {
                //     "category": category
                // }
                "bool": {
                    "must": [
                        {
                            "match": {
                                "subjects": context.params.id
                            }
                        },
                        {
                            "match": {
                                "category": category
                            }
                        },
                        {
                            "match": {
                                "questionState2": "Published"
                            }
                        }
                    ]
                }
            }, null, null, null, {
                // "subcategory": {
                //     "terms": { "field": "subcategory", "size": 10000 }
                // }
                "subcategory": {
                    "filter": { "term": { "questionType": "Multiple Choice" } },
                    "aggs": {
                        "subcategory": {
                            "terms": {
                                "field": "subcategory",
                                "size": 10000
                            }
                        }
                    }
                }
            }, "0").then((filterData) => {

                console.log(filterData);
                resolve(filterData.data);
            }).catch(error => {
                console.error(error);
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load sub categories, please contact administrator.'
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            })
        });
    }

    getUserDecks() {
        let context = this;
        context.showIndicator();
        let owner = this.props.auth.user.userName;
        return new Promise((resolve, reject) => {
            this.estore.query(null, 'activity' + config.stage, {
                "match": {
                    "originalOwner": owner
                }
            }, ["id", "name", "createdAt"], null, null, null, null).then((filterData) => {
                resolve(filterData.data);
            }).catch(error => {
                console.error(error);
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load user decks, please contact administrator.'
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            })
        });
    }
    routerWillLeave(nextLocation) {
        return 'Your work is not saved! Are you sure you want to leave?'
    }
}

function mapStateToProps(state) {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(withRouter(PracticeSubject));
